import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import ScheduleCallBack from "./loInsight/scheduleCallBack";
import DangerAlert from "../generalUseComponents/dangerAlert";

export const StatusLeadModal = (props) => {
  const [status, setStatus] = useState("");
  const [deadReason, setDeadReason] = useState("");
  const [error, setError] = useState("");
  let deadReasons = [
    "Did not Apply",
    "Listed",
    "Dealing W/ Agent",
    "Not Selling",
    "Sold",
    "DNC",
    "Bad Phone",
    "No Interest"
  ];
  const statusLead = () => {
    if (status == "") {
      setError("A status must be selected");
    } else if (status == "Dead" && deadReason == "") {
      setError("A dead reason must be selected");
    } else {
      if (status == "Dead") {

        if (props.lead.notes != undefined && props.lead.notes.trim() != "") {
          props.onSave();
          axios
            .post("/backend/activeLeads/markYlopoLeadDead", props.lead)
            .then((resp) => { })
            .catch((err) => { });
        } else {
          setError("If you would like to status a lead as Dead please add Notes")
        }


      } else {
        props.onSave();
      }
    }
  };

  let deadReasonsItems = deadReasons.map((item) => {
    if (item == deadReason) {
      return (
        <a className="btn btn-primary m-1" onClick={() => {
          setDeadReason(item)
          props.setters.setDeadReason(item)
        }}>
          {item}
        </a>
      );
    } else {
      return (
        <a className="btn btn-light  m-1" onClick={() => {
          setDeadReason(item)
          props.setters.setDeadReason(item)
        }}>
          {item}
        </a>
      );
    }
  });
  return (
    <div>
      <Modal show={props.show}>
        <Modal.Header>
          <h4>Status Lead</h4>
          <button
            className="btn btn-danger"
            onClick={() => {
              props.setShow(false);
            }}
          >
            <AiOutlineClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          <DangerAlert data={error}/>
          {status == "" ? (
            <div>
              {" "}
              <a
                className="btn btn-light"
                onClick={() => {
                  setStatus("Call Back Later");
                  props.setters.setLeadStatus("Call Back Later");
                  setDeadReason("");
                }}
              >
                Call Back Later
              </a>
              <a
                className="btn btn-light ml-2"
                onClick={() => {
                  setStatus("Dead");
                  props.setters.setLeadStatus("Dead");
                }}
              >
                Dead
              </a>{" "}
            </div>
          ) : (
            <div></div>
          )}

          {status == "Call Back Later" ? (
            <div>
              {" "}
              <a
                className="btn btn-primary"
                onClick={() => {
                  props.setters.setLeadStatus("Call Back Later");
                  setStatus("Call Back Later");
                }}
              >
                Call Back Later
              </a>
              <a
                className="btn btn-light ml-2"
                onClick={() => {
                  setStatus("Dead");
                  props.setters.setLeadStatus("Dead");
                }}
              >
                Dead
              </a>{" "}
            </div>
          ) : (
            <div></div>
          )}

          {status == "Dead" ? (
            <div>
              {" "}
              <a
                className="btn btn-light"
                onClick={() => {
                  setStatus("Call Back Later");
                  props.setters.setLeadStatus("Call Back Later");
                  setDeadReason("");
                }}
              >
                Call Back Later
              </a>
              <a
                className="btn btn-primary ml-2"
                onClick={() => {
                  setStatus("Dead");
                  props.setters.setLeadStatus("Dead");
                }}
              >
                Dead
              </a>{" "}
              <div className="mt-5">
                <label>Reason:</label>
                {deadReasonsItems}
              </div>
            </div>
          ) : (
            <div></div>
          )}

          {status == "Call Back Later" ? (
            <div className="mt-5">
              <ScheduleCallBack
                leadStatus={props.lead.leadStatus}
                callBackLaterDate={props.lead.callBackLaterDate}
                onChangeCallBackLaterDate={props.setters.setCallBackLaterDate}
              />
            </div>
          ) : (
            <div></div>
          )}
          <button className="btn btn-dark mt-5" onClick={() => statusLead()}>
            Status
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};
