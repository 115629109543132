import React, { Component } from "react";

export default class CoBorrower extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (this.props.lead.employment == "Unemployed") {
      return (
        <div className="input-group-sm">
          <select
            className="form-control  col"
            value={this.props.lead.hasCoBorrower}
            onChange={this.props.onChangeHasCoBorrower}
          >
            <option value=""></option>
            <option value="Yes">Yes </option>
            <option value="No">No</option>
          </select>
        </div>
      );
    } else {
      return (
        <div className="input-group-sm">
          <select
            disabled
            className="form-control  col"
            value={this.props.lead.hasCoBorrower}
            onChange={this.props.onChangeHasCoBorrower}
          >
            <option value=""></option>
            <option value="Yes">Yes </option>
            <option value="No">No</option>
          </select>
        </div>
      );
    }
  }
}
