import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Modal } from "react-bootstrap";

export const DeactiveUserModal = (props) => {
  const [userEmailConformation, setUserEmailConformation] = useState("");

 
  if (props.show) {
    return (
      <div className="mt-2 text-center">
        <h6>Enter User's email to Deactivate Account</h6>
        <div className="row justify-content-center">
          <input
            placeholder="User's Email"
            value={userEmailConformation}
            onChange={(e) => setUserEmailConformation(e.target.value)}
          />
        </div>

        <div className="row justify-content-center">
          {props.user.email == userEmailConformation ? (
            <button className="btn btn-danger mt-2"onClick={props.onClickDeactivateUser}>
              Confirm Deactivation User
            </button>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};
