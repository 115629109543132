import employmentTest from "./employementTest";

export default function calcPurchaseEligibility (lead) {
  if (!lead) {
    return {
        classStr: '',
        message: ''
    }
  }
  
  const danger = 'font-weight-bold bg-danger text-white';

  if (employmentTest(lead.employment, lead.hasCoBorrower) === 'Fail') {
    return {
      classStr: danger,
      message: 'Employment Issue',
    };
  }
  if (lead.primaryGoal !== 'Purchase') {
    return {
      classStr: danger,
      message: 'Not looking to purchase',
    };
  }
  if (lead.creditScore < 580 && lead.isVeteran === 'No') {
      return {
        classStr: danger,
        message: 'FICO too low: 580',
      };
  }

  return {
    classStr: 'bg-success font-weight-bold text-white',
    message: 'Eligible',
  }
}