function checkRequiredFields (lead, ltv, creditScore, jumboThreshold) {
    if (!lead) return true;

    const requiredFields = [
        lead.primaryGoal, ltv, creditScore, 
        lead.chaseEligible, lead.loanBalance, lead.occupancy,
        lead.employment, jumboThreshold
    ];
    let fieldMissing = false;
    
    requiredFields.forEach((field) => {
        if (field === '' || field === undefined) {
            fieldMissing = true;
        }
    })
    return fieldMissing;
}

export default function calcContourCommunityLendingEligibility (lead, ltv, jumboThreshold) {
    const creditScore = lead?.creditScore ? parseInt(lead.creditScore) : undefined;
    const validEmployment = ['Employed', 'Retired', 'Disabled'];

    const fieldMissing = checkRequiredFields(lead, ltv, creditScore, jumboThreshold);
    if (fieldMissing) return {
        classStr: '',
        message: '',
    };

    const firstCase = () => {
        if (
            lead.primaryGoal === 'Cash Out' && 
            ltv < 75 && 
            creditScore > 620 &&
            lead.chaseEligible === 'Eligible' &&
            lead.loanBalance <= jumboThreshold &&
            lead.occupancy === 'Primary' &&
            validEmployment.includes(lead.employment)
        ) {
            return true;
        }
        return false;
    };
    const secondCase = () => {
        if (
            lead.primaryGoal === 'Purchase' &&
            ltv < 85 &&
            creditScore >= 620 &&
            creditScore <= 679 &&
            lead.chaseEligible === 'Eligible' &&
            lead.importDataLoanAmount <= jumboThreshold &&
            lead.occupancy === 'Primary' &&
            validEmployment.includes(lead.employment)
        ) {
            return true;
        }
        return false;
    };
    const thirdCase = () => {
        if (
            lead.primaryGoal === 'Purchase' &&
            ltv < 97 &&
            creditScore > 679 &&
            lead.chaseEligible === 'Eligible' &&
            lead.importDataLoanAmount <= jumboThreshold &&
            lead.occupancy === 'Primary' &&
            validEmployment.includes(lead.employment)
        ) {
            return true;
        }
        return false;
    };

    if (firstCase() || secondCase() || thirdCase()) {
        return {
            classStr: 'font-weight-bold bg-success text-white',
            message: 'Eligible'
        };
    }

    return {
        classStr: 'font-weight-bold bg-danger text-white',
        message: 'Ineligible'
    };
}