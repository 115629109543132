import employmentTest from "./employementTest";
import calcPaymentsMade from "../../generalUseFunctions/calcPaymentsMade"
import calcMinEquity from "./calcMinEquity";
import calcLTV from "./calcLTV";
import calcEquity from "../../generalUseFunctions/calcEquity"
import calcMaxVaCoLtv from "./calcMaxVaCoLtv";

export default function calcVaCashoutEligibility (lead, irrrlFICO) {
    if (!lead) {
        return {
            classStr: '',
            message: ''
        }
    }

    const danger = 'font-weight-bold bg-danger text-white';

    const equity = calcEquity(lead.estimatedValue, lead.loanBalance);
    const ltv = calcLTV(lead.loanBalance, lead.estimatedValue);
    const maxLtv = calcMaxVaCoLtv(lead.creditScore);

    if (employmentTest(lead.employment, lead.hasCoBorrower) === 'Fail') {
        return {
            classStr: danger,
            message: 'Employment Issue',
        }
    }
    if (lead.isVeteran === 'No') {
        return {
            classStr: danger,
            message: 'Not a Veteran',
        }
    }
    if (calcPaymentsMade(lead.mortgageDate) < 5) {
        return {
            classStr: danger,
            message: 'Seasoning Needed: 5 months',
        }
    }
    if (lead.state === 'TX') {
        return {
            classStr: danger,
            message: 'VA Cashout not valid in Texas',
        }
    }
    if (lead.creditScore < irrrlFICO) {
        return {
            classStr: danger,
            message: `FICO too low: ${irrrlFICO}`
        }
    }
    if (equity < calcMinEquity(ltv)) {
        return {
            classStr: danger,
            message: `Not enough equity: ${calcMinEquity(ltv)}`,
        }
    }
    if (ltv > maxLtv) {
        return {
            classStr: danger,
            message: `LTV too high: ${maxLtv}`,
        }
    }
    if (lead.occupancy !== 'Primary') {
        return {
            classStr: danger,
            message: 'Can only do on primary residence',
        }
    }
    if ((lead.loanBalance + equity) < 100000) {
        return {
            classStr: danger,
            message: "Doesn't meet minimum loan amount criteria.",
        }
    }

    return {
        classStr: 'bg-success font-weight-bold text-white',
        message: 'Eligible',
    }
}