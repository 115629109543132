import React, { useEffect, useState } from "react";
import axios from "axios";
import { ClaimLeadModal } from "./claimLeadModal";
var CurrencyFormat = require("react-currency-format");
export const ClaimLeadQueue = (props) => {
  const [openClaimLeadModal, setOpenClaimLeadModal] = useState(false);
  const [leadToClaim, setLeadToClaim] = useState();

  const getValue = (itemName, key) => {
    if (itemName == "FirstName") {
      if (
        props.claimLeadsQueue[key].ylopoId != undefined ||
        props.claimLeadsQueue[key].campaignName == "TRIG"
      ) {
        return props.claimLeadsQueue[key].b1FirstName;
      } else {
        let firstName = props.claimLeadsQueue[key].ylopoDataFields.find(
          (item) => item.label == "First Name"
        );

        return firstName != undefined ? firstName.value : "";
      }
    } else if (itemName == "LastName") {
      if (
        props.claimLeadsQueue[key].ylopoId != undefined ||
        props.claimLeadsQueue[key].campaignName == "TRIG"
      ) {
        return props.claimLeadsQueue[key].b1LastName;
      } else {
        let LastName = props.claimLeadsQueue[key].ylopoDataFields.find(
          (item) => item.label == "Last Name"
        );

        return LastName != undefined ? LastName.value : "";
      }
    } else if (itemName == "TimeFrame") {
      let TimeFrame = props.claimLeadsQueue[key].ylopoDataFields.find(
        (item) => item.label == "Time Frame"
      );

      return TimeFrame != undefined ? TimeFrame.value : "";
    } else if (itemName == "PhoneNumber") {
      if (props.claimLeadsQueue[key].campaignName == "TRIG") {
        return props.claimLeadsQueue[key].phoneNumber;
      } else if (props.claimLeadsQueue[key].ylopoId != undefined && props.claimLeadsQueue[key].ylopoPhoneNumbers[0]) {
        return props.claimLeadsQueue[key].ylopoPhoneNumbers[0].value;
      } else {
        let PhoneNumber = props.claimLeadsQueue[key].ylopoDataFields.find(
          (item) => item.label == "Phone"
        );

        return PhoneNumber != undefined ? PhoneNumber.value : "";
      }
    }
  };

  const onClaimLead = (lead) => {
    setLeadToClaim(lead);
    setOpenClaimLeadModal(true);
    // axios.post("/backend/activeLeads/ClaimQueue", {});
  };

  const leadItems = props.claimLeadsQueue.map((lead, i) => {
    return (
      <div className="row border-bottom border-right border-left search-row">
        <div className="col border-right p-0 p-2 text-center">
          {lead.vendorLeadCode}
        </div>
        <div className="col border-right p-0 p-2 text-center">
          {getValue("FirstName", i)}
        </div>
        <div className="col border-right p-0 p-2 text-center">
          {getValue("LastName", i)}
        </div>
        <div className="col border-right p-0 p-2 text-center">
          {getValue("TimeFrame", i)}
        </div>
        <div className="col border-right p-0 p-2 text-center">
          {" "}
          <CurrencyFormat
            className="border-0"
            format="(###) ###-####"
            mask="_"
            placeholder="(###) ###-####"
            value={getValue("PhoneNumber", i)}
          />
        </div>
        <div className="col-1  text-center">
          <button
            className="btn btn-primary p-2 text-center"
            onClick={() => onClaimLead(lead)}
          >
            View
          </button>
        </div>
      </div>
    );
  });

  return (
    <div>
      <ClaimLeadModal
        show={openClaimLeadModal}
        setShow={setOpenClaimLeadModal}
        lead={leadToClaim}
        user={props.user}
        setShowLead={props.setShowLead}
        onClickLeadDashboard={props.onClickLeadDashboard}
        onClickTmLeadsDashboard={props.onClickTmLeadsDashboard}
        getQueueLeads={props.getQueueLeads}
      />
      <div className="container">
        <h3 className="text-center mt-2">Lead Queue</h3>
        {props.claimLeadsQueue != undefined &&
        props.claimLeadsQueue.length == 0 ? (
          <div className="center-text">
            <h3>No Leads in the Queue</h3>
          </div>
        ) : (
          <div className="row mt-1 contour-blue text-white">
            <div className="col text-center">Vendor Lead Code</div>
            <div className="col text-center">First name</div>
            <div className="col text-center">Last Name</div>
            <div className="col text-center">Time Frame</div>
            <div className="col text-center">Phone</div>
            <div className="col-1"></div>
          </div>
        )}

        {leadItems}
      </div>
    </div>
  );
};
