import React from "react";
import ScheduleCallBack from "./loInsight/scheduleCallBack";
var CurrencyFormat = require("react-currency-format");

export const BorrowerLoanInformation = (props) => {
  const formatDate =(string)=> {
    const time = new Date(string);
    return (
      time.toDateString() +
      " at " +
      time.toLocaleTimeString([], { timeStyle: "short" })
    );
  }
  return (
    <div className="col-4 ml-2">
      <div className="mt-2 mr-2">
        <table className="table table-striped table-sm table-bordered shadow">
          <thead>
            <tr className="table-active w-100">
              <td colSpan="2" className="text-center font-weight-bold">
                Loan Information
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Purpose</td>
              <td colSpan="2">
                <select
                  className="form-control"
                  value={props.lead.primaryGoal ? props.lead.primaryGoal : ""}
                  onChange={(e) => {
                    props.setNeedsToBeSaved(true);
                    props.setters.setPrimaryGoal(e.target.value)
                  }}
                >
                  <option value=""></option>
                  <option value="Purchase">Purchase</option>
                  <option value="Renovation">Renovation</option>
                  <option value="Lower Payment">Lower Payment</option>
                  <option value="Cash Out">Cash Out</option>
                  <option value="Other">Other</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>Cell Phone</td>
              <td colSpan="2">
                <CurrencyFormat
                  className="form-control"
                  format="(###) ###-####"
                  mask="_"
                  placeholder="(###) ###-####"
                  value={props.lead.phoneNumber}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    // formattedValue = $2,223
                    // value ie, 2223
                    props.setNeedsToBeSaved(true);
                    props.setters.setPhoneNumber(value)
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Email</td>
              <td colSpan="2">
                <input
                  className="form-control"
                  value={props.lead.importDataEmail}
                  onChange={(e)=>{
                    props.setNeedsToBeSaved(true);
                    props.setters.setImportDataEmail(e.target.value)
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Loan Outcome</td>
              <td colSpan="2">
                <input
                  className="w-100 form-control"
                  type="text"
                  disabled
                  value={props.lead.importDataAdverseReason}
                />
              </td>
            </tr>
            <tr>
              <td>Last Milestone</td>
              <td colSpan="2">
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={props.lead.importDataLastFinishedMilestone}
                />
              </td>
            </tr>
            <tr>
              <td>Last Loan Officer</td>
              <td colSpan="2">
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={props.lead.importDataLastLoanOfficer}
                />
              </td>
            </tr>
            <tr>
              <td>Closing Date</td>
              <td colSpan="2">
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={new Date(
                    props.lead.importDataClosingDate
                  ).toDateString()}
                />
              </td>
            </tr>
            <tr>
              <td>Loan Amount</td>
              <td>
                <CurrencyFormat
                  className="form-control"
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                  disabled
                  value={props.lead.loanBalance}
                />
              </td>
            </tr>
            <tr>
              <td>Loan Program</td>
              <td colSpan="2">
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={props.lead.importDataLoanProgram}
                />
              </td>
            </tr>
            <tr>
              <td>Note Rate</td>
              <td colSpan="2">
                <input
                  className="form-control"
                  type="text"
                  disabled
                  placeholder="city"
                  value={props.lead.interestRate}
                />
              </td>
            </tr>
            <tr>
              <td>FICO</td>
              <td colSpan="2">
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={props.lead.creditScore}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <table className="table table-striped table-sm table-bordered shadow">
            <tbody>
              <tr>
                <td>Lead Status</td>
                <td>
                  <select
                    className="form-control input-group col"
                    value={props.lead.leadStatus}
                    onChange={(e) => {
                      props.setNeedsToBeSaved(true);
                      props.setters.setLeadStatus(e.target.value)
                    }}
                  >
                    <option value=""></option>
                    <option value="Call Back Later">Call Back Later</option>
                    <option value="Dead">Dead</option>
                  </select>
                </td>
              </tr>
              <ScheduleCallBack
                leadStatus={props.lead.leadStatus}
                callBackLaterDate={props.lead.callBackLaterDate}
                onChangeCallBackLaterDate={
                  props.setters.setCallBackLaterDate
                }
              />
              <tr>
                <td>Lead Created Date</td>
                <td>{formatDate(props.lead.leadCreated)}</td>
              </tr>
            </tbody>
          </table>
      </div>
    </div>
  );
};
