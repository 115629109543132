export default function determineHuntGroupRingCentral(lead) {
    if (!lead) return;

    let huntgroup = '6';

    if (lead.primaryGoal === 'Purchase') {
        huntgroup += '1';
    } else {
        huntgroup += '2';
    }

    const stateCodeMap = {
        'AL':'01',
        'AK':'02',
        'AZ':'03',
        'AR':'04',
        'CA':'05',
        'CO':'06',
        'CT':'07',
        'DE':'08',
        'FL':'09',
        'GA':'10',
        'HI':'11',
        'ID':'12',
        'IL':'13',
        'IN':'14',
        'IA':'15',
        'KS':'16',
        'KY':'17',
        'LA':'18',
        'ME':'19',
        'MD':'20',
        'MA':'21',
        'MI':'22',
        'MN':'23',
        'MS':'24',
        'MO':'25',
        'MT':'26',
        'NE':'27',
        'NV':'28',
        'NH':'29',
        'NJ':'30',
        'NM':'31',
        'NY':'32',
        'NC':'33',
        'ND':'34',
        'OH':'35',
        'OK':'36',
        'OR':'37',
        'PA':'38',
        'RI':'39',
        'SC':'40',
        'SD':'41',
        'TN':'42',
        'TX':'43',
        'UT':'44',
        'VT':'45',
        'VA':'46',
        'WA':'47',
        'WV':'48',
        'WI':'49',
        'WY':'50',
        'DC':'51',
    }

    huntgroup += stateCodeMap[lead.state] ?? '';

    return huntgroup;
}
