import React, { Component } from "react";

export default class PrevCashoutInterviewQuestion extends Component {
  render() {
    if (
      (this.props.lead.primaryGoal == "Lower Payment" &&
        this.props.lead.state == "TX") ||
      (this.props.lead.primaryGoal == "Cash Out" &&
        this.props.lead.state == "TX")
    ) {
      return (
        <div className="input-group-sm">
          <select
          className="form-control input-group col"
          value={this.props.lead.hasPrevCashOut}
          onChange={this.props.onChangeHasPreviousCashOut}
        >
          <option value=""></option>
          <option value="Yes">Yes </option>
          <option value="No">No</option>
        </select>
        </div>
      );
    } else {
      return (
        <div className="input-group-sm">
          <select
            disabled
            className="form-control input-group col"
            value={this.props.lead.hasPrevCashOut}
            onChange={this.props.onChangeHasPreviousCashOut}
          >
            <option value=""></option>
            <option value="Yes">Yes </option>
            <option value="No">No</option>
          </select>
        </div>
      );
    }
  }
}
