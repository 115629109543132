import calcPaymentsMade from "../../generalUseFunctions/calcPaymentsMade";
import calcFhaRtFloor from "./calcFhaRtFloor";
import calcLTV from "./calcLTV";
import employmentTest from "./employementTest";

export default function calcFhaRateTermEligibility (lead, fhaFICO, jumboThreshold, fhaBase, maxFHAConvRTLTV) {
    if (!lead || !lead.vendorLeadCode) {
        return {
            classStr: '',
            message: '',
        }
    }

    const danger = 'font-weight-bold bg-danger text-white';
    const success = 'bg-success font-weight-bold text-white';

    const fhaFloor = calcFhaRtFloor(fhaBase, lead.vendorLeadCode, lead.hasMortgageInsurance, lead.creditScore, lead.loanBalance, jumboThreshold);
    const ltv = calcLTV(lead.loanBalance, lead.estimatedValue);

    if (employmentTest(lead.employment, lead.hasCoBorrower) === 'Fail') {
        return {
            classStr: danger,
            message: 'Employment Issue',
        }
    }

    if (lead.vendorLeadCode.substring(0, 2) === 'VA') {
        return {
            classStr: danger,
            message: 'Use VA options',
        }
    }

    if (lead.creditScore < fhaFICO) {
        return {
            classStr: danger,
            message: `FICO too low: ${fhaFICO}`,
        }
    }

    if (lead.loanBalance > (jumboThreshold - 5000) && lead.creditScore < 640) {
        return {
            classStr: danger,
            message: 'FICO too low: 640',
        }
    }

    if (lead.interestRate < fhaFloor) {
        return {
            classStr: danger,
            message: `Interest rate too low: ${fhaFloor}`,
        }
    }

    if (ltv > maxFHAConvRTLTV) {
        return {
            classStr: danger,
            message: `LTV too high: ${maxFHAConvRTLTV}`,
        }
    }

    if (calcPaymentsMade(lead.mortgageDate) < 5) {
        return {
            classStr: danger,
            message: 'Seasoning Needed: 5 months',
        }
    }

    if (lead.loanBalance < 75000) {
        return {
            classStr: danger,
            message: 'Loan Balance too low: $75,000',
        }
    }

    if (lead.state !== 'TX') {
        return {
            classStr: success,
            message: 'Eligible',
        }
    }

    if (lead.hasPrevCashout === 'No') {
        return {
            classStr: success,
            message: 'Eligible',
        }
    }

    if (calcPaymentsMade(lead.mortgageDate) < 10) {
        return {
            classStr: danger,
            message: 'TX: 10 months seasoning',
        }
    }

    if (ltv > 75) {
        return {
            classStr: danger,
            message: 'TX: Max LTV is 75',
        }
    }

    return {
        classStr: success,
        message: 'Eligible',
    }
}