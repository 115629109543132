import calcLTV from "../../generalUseFunctions/calcLTV";
import calcPaymentsMade from "../tmInsight/calcPaymentsMade";

export default function calcRefiNowEligibility (lead, jumboThreshold) {
    if (!lead || !lead.vendorLeadCode) {
        return {
            classStr: '',
            message: '',
        }
    }

    const danger = 'font-weight-bold bg-danger text-white';
    const success = 'bg-success font-weight-bold text-white';

    const paymentsMade = calcPaymentsMade(lead.mortgageDate);
    const ltv = calcLTV(lead.loanBalance, lead.estimatedValue);

    if (lead.vendorLeadCode.substring(0, 2) !== 'CO') {
        return {
            classStr: danger,
            message: 'Not Eligible',
        }
    }

    if (lead.freddieFannieIndicator === false || lead.freddieFannieIndicator === undefined) {
        return {
            classStr: danger,
            message: 'Not Eligible: Must be Freddie or Fannie Loan',
        }
    }

    if (lead.creditScore < 620) {
        return {
            classStr: danger,
            message: 'FICO too low: 620',
        }
    }

    if (paymentsMade < 9 || paymentsMade > 120) {
        return {
            classStr: danger,
            message: 'Needs Seasoning or has made more than 120 payments',
        }
    }

    if (lead.loanBalance > (jumboThreshold - 5000)) {
        return {
            classStr: danger,
            message: 'Not Eligible: Conforming',
        }
    }

    if (lead.loanBalance < 75000) {
        return {
            classStr: danger,
            message: 'Loan Balance too low: $75,000',
        }
    }

    if (lead.occupancy === 'Primary' && ltv > 97-5) {
        return {
            classStr: success,
            message: 'Eligible',
        }
    }

    if (lead.occupancy === 'Secondary' && ltv > 90-5) {
        return {
            classStr: success,
            message: 'Eligible',
        }
    }

    if (lead.occupancy === 'Investment' && ltv > 75-5) {
        return {
            classStr: success,
            message: 'Eligible',
        }
    }

    return {
        classStr: danger,
        message: 'Not Eligible: LTV Too Low',
    }
}