import React, { Component } from "react";
import "react-notifications-component/dist/theme.css";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import SignInPage from "./components/sign-in-page";
import { mainContext } from "./context/mainContext";
import "./css/home.css";
axios.defaults.headers.common["Authorization"] = process.env.REACT_APP_API_KEY;
const baseURL = process.env.REACT_APP_BASE_URL;

export default class App extends Component {
  constructor() {
    super();
  }
  render() {
    const contextValues = { baseURL };
    return (
      <Router>
        <Switch>
          <mainContext.Provider value={contextValues}>
            <Route path="/" exact component={SignInPage} />
          </mainContext.Provider>
        </Switch>
      </Router>
    );
  }
}
