import employmentTest from "./employementTest";

export default function calcOtherEligibility (lead) {
    if (!lead) {
        return {
            classStr: '',
            message: '',
        }
    }

    const danger = 'font-weight-bold bg-danger text-white';
    const success = 'bg-success font-weight-bold text-white';

    if (employmentTest(lead.employment, lead.hasCoBorrower) === 'Fail') {
        return {
            classStr: danger,
            message: 'Employment Issue',
        }
    }

    if (lead.primaryGoal !== 'Other') {
        return {
            classStr: danger,
            message: 'Not a unique situation',
        }
    }

    return {
        classStr: success,
        message: 'Eligible',
    }
}