export default function calcFhaRtFloor(fhaBase, vendorLeadCode, mortgageInsurance, creditScore,loanBalance, jumboThreshold){
    let rate = fhaBase;
        

    if(vendorLeadCode.substring(0, 2)=="FH"){
        rate = rate +.75;
    }
    else{
        if(mortgageInsurance=="Yes"){
            rate = rate +1.3;
        }
        else {
            rate = rate+1.6;
        }
    }
    if( creditScore< 640){
        rate=rate+.25;
    }

    if(loanBalance > jumboThreshold-5000){
        rate = rate+.25;
    }
   

    return rate
}