import React, { Component } from "react";

import ChangePassword from "./change-password";
import UpdateEncompass from "./update-encompass";
import axios from "axios";
import EditLicensedStates from "./edit-licensed-state";
import DangerAlert from "../generalUseComponents/dangerAlert";
import SuccessAlert from "./successAlert";
import LeadDashboardContent from "../dashboard-content";

export default class EditProfile extends Component {
  constructor(props) {
    super(props);

    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeUserType = this.onChangeUserType.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onClickChangePassword = this.onClickChangePassword.bind(this);
    this.onClickUpdateEncompass = this.onClickUpdateEncompass.bind(this);
    this.onClickUpdateLicensedStates = this.onClickUpdateLicensedStates.bind(
      this
    );

    this.state = {
      firstName: this.props.data.user.firstName,
      lastName: this.props.data.user.lastName,
      userType: this.props.data.user.userType,
      email: this.props.data.user.email,
      encompassUsername: this.props.data.user.encompassUserName,
      encompassPassword: this.props.data.user.encompassPassword,
      userType: this.props.data.user.userType,

      changePassword: "",
      updateEncompass: "",
      showLicensedStates: "",
      successResponse: "",
      err: "",
    };
  }
  componentDidMount() {
    this.setState({
      changePassword: false,
      updateEncompass: false,
      showLicensedStates: false,
    });
  }
  onChangeFirstName(e) {
    this.setState({ firstName: e.target.value });
  }

  onChangeLastName(e) {
    this.setState({ lastName: e.target.value });
  }

  onChangeUserType(e) {
    this.setState({ userType: e.target.value });
  }

  onChangeEmail(e) {
    this.setState({ email: e.target.value });
  }
  onChangeEncompassPassword(e) {
    this.setState({ encompassPassword: e.target.value });
  }

  onChangeEncompassUserName(e) {
    this.setState({ encompassUsername: e.target.value });
  }

  onClickChangePassword(e) {
    e.preventDefault();
    this.setState({ changePassword: !this.state.changePassword });
  }
  onClickUpdateEncompass(e) {
    e.preventDefault();
    this.setState({ updateEncompass: !this.state.updateEncompass });
  }
  onClickUpdateLicensedStates(e) {
    this.setState({ showLicensedStates: !this.state.showLicensedStates });
  }
  onClickSuccessResponse() {
    this.setState({ successResponse: "" });
  }
 

  render() {
    if(this.props.user.userType=="Admin" ||this.props.user.userType=="IT"){
      return (
        <div className="col-8 container">
          <div className="row">
            <div className=" col card mt-4 shadow mr-3">
              <div className=" container form-group mt-2">
                <h4 className="h4 text-center">Edit My Profile</h4>
  
                <div className=" row mt-3 ">
                  <div className="col">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="form-control shadow"
                      placeholder="First Name"
                      value={this.state.firstName}
                      onChange={this.onChangeFirstName}
                    />
                  </div>
  
                  <div className="col">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="form-control shadow"
                      placeholder="Last Name"
                      onChange={this.onChangeLastName}
                      value={this.state.lastName}
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <label>Email</label>
                  <input
                    type="text"
                    className="form-control col  shadow"
                    placeholder="Email"
                    onChange={this.onChangeEmail}
                    value={this.state.email}
                  />
                </div>
  
             
  
                <div className="text-center"></div>
              </div>
            </div>
            
          </div>
        </div>
      );

    } else {
      return (
        <div className="col-8 container">
     
        </div>
      );
    }
   
  }
}
