import React, { useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import Draggable from 'react-draggable'; // The default
import { BsArrowsMove } from 'react-icons/bs';

export const YlopoScript = (props) => {
    const [firstName, setFirstName] = useState('');
    const [adPlatform, setAdPlatform] = useState('');
    const [leadType, setLeadType] = useState('');
    const [timeFrame, setTimeFrame] = useState('');

    useEffect(() => {
        if (props.lead.ylopoId != undefined) {
            setFirstName(props.lead.b1FirstName);

            if (props.lead.ylopoSource == 'Ylopo Seller') {
                setLeadType('Sell');
            } else {
                setLeadType('Buy');
            }

            props.lead.ylopoTags.find((item) => {
                if (item.includes('YEAR') || item.includes('MONTH') || item.includes('DAYS') || item.includes('WEEKS')) {
                    setTimeFrame(item.replace('_', ' ').replace('NEXT', ''));
                }

                if (item.includes('YLOPO')) {
                    setAdPlatform(item.replace('YLOPO', '').replace('_', ' '));
                }
            });
        } else {
            props.lead.ylopoDataFields.find((item) => {
                if (item.label == 'First Name') {
                    setFirstName(item.value);
                }
                if (item.label == 'Ad Platform') {
                    setAdPlatform(item.value.replace('YLOPO', ''));
                }
                if (item.label == 'Lead Type') {
                    if (item.value == 'SELLER') {
                        setLeadType('Sell');
                    } else if (item.value == 'BUYER') {
                        setLeadType('Buy');
                    } else {
                        setLeadType(item.value);
                    }
                }
                if (item.label == 'Seller Time Frame' || item.label == 'Time Frame') {
                    setTimeFrame(item.value.replace('within', ''));
                }
            });
        }

        return () => {};
    }, [props.lead]);

    if (props.lead != undefined && props.show == true && props.lead.vendorLeadCode.substring(0, 7) == 'TRIGCLS') {
        return (
            <Draggable handle="strong">
                <div className="row  z-index-5 border border-dark bg-light shadow rounded script-width">
                    <div className="col">
                        <div className="row  mt-1">
                            <div className="col">
                                <strong className="cursor left-justify">
                                    <div>
                                        <BsArrowsMove />
                                    </div>
                                </strong>
                            </div>
                            <div className="col-6">
                                <h4>Script</h4>
                            </div>

                            <div className="col ">
                                <button
                                    className="border rounded bg-danger text-white right-justify "
                                    onClick={() => {
                                        props.setShow(false);
                                    }}
                                >
                                    <AiOutlineClose />
                                </button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <p>
                                    Hello, May I speak with{' '}
                                    <u>
                                        <strong>{props.lead.b1FirstName+ ' ' +props.lead.b1LastName}</strong>
                                    </u>{' '}
                                    please?
                                </p>
                                <p>
                                    My name is{' '}
                                    <u>
                                        <strong>{props.user.firstName + ' ' + props.user.lastName}</strong>
                                    </u>{' '}
                                    with Contour Mortgage's Retention Dept.
                                </p>
                                <p>How are you today? </p>
                                <p>
                                    Our Dept. has the task of monitoring Credit profiles of customers from loans closing on{' '}
                                    <u>
                                        <strong>{props.lead.importDataClosingDate ? props.lead.importDataClosingDate : '___________'}</strong>
                                    </u>{' '}
                                    . The reason we do this is to ensure our current customer based continues to be serviced by Contour Mortgage.
                                    Unlike the current lender you are dealing with, we don’t need to collect any information or documentation and go
                                    over the long application process again, and it seems as if you had a good experience with us last time. Can we
                                    look at this for you again?
                                </p>
                            </div>

                            <p></p>
                        </div>
                    </div>
                </div>
            </Draggable>
        );
    } else if (props.lead != undefined && props.show == true && props.lead.vendorLeadCode.substring(0, 7) == 'TRIGADV') {
        return (
            <Draggable handle="strong">
                <div className="row  z-index-5 border border-dark bg-light shadow rounded script-width">
                    <div className="col">
                        <div className="row  mt-1">
                            <div className="col">
                                <strong className="cursor left-justify">
                                    <div>
                                        <BsArrowsMove />
                                    </div>
                                </strong>
                            </div>
                            <div className="col-6">
                                <h4>Script</h4>
                            </div>

                            <div className="col ">
                                <button
                                    className="border rounded bg-danger text-white right-justify "
                                    onClick={() => {
                                        props.setShow(false);
                                    }}
                                >
                                    <AiOutlineClose />
                                </button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <p>
                                    Hello, May I speak with{' '}
                                    <u>
                                    <strong>{props.lead.b1FirstName+ ' ' +props.lead.b1LastName}</strong>

                                    </u>{' '}
                                    please?
                                </p>
                                <p>
                                    My name is{' '}
                                    <u>
                                        <strong>{props.user.firstName + ' ' + props.user.lastName}</strong>
                                    </u>{' '}
                                    with Contour Mortgage's Retention Dept.
                                </p>
                                <p>How are you today? </p>
                                <p>
                                    Our Dept. has the task of monitoring Credit profiles of applicants that started the mortgage process with us on{' '}
                                    <u>
                                        <strong>{props.lead.importDataClosingDate ? props.lead.importDataClosingDate : '___________'}</strong>
                                    </u>{' '}
                                    but never completed the process. Was there any reason you did not complete the process with us? Well, it looks as
                                    if you applied for mortgage in the last 24 hours. Since we have most of your information and documents from the
                                    last transaction, can we look at this for you again?
                                </p>
                            </div>

                            <p></p>
                        </div>
                    </div>
                </div>
            </Draggable>
        );
    } else if (props.lead != undefined && props.show == true) {
        return (
            <Draggable handle="strong">
                <div className="row  z-index-5 border border-dark bg-light shadow rounded script-width">
                    <div className="col">
                        <div className="row  mt-1">
                            <div className="col">
                                <strong className="cursor left-justify">
                                    <div>
                                        <BsArrowsMove />
                                    </div>
                                </strong>
                            </div>
                            <div className="col-6">
                                <h4>Script</h4>
                            </div>

                            <div className="col ">
                                <button
                                    className="border rounded bg-danger text-white right-justify "
                                    onClick={() => {
                                        props.setShow(false);
                                    }}
                                >
                                    <AiOutlineClose />
                                </button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <p>
                                    Hello, May I speak with{' '}
                                    <u>
                                        <strong>{firstName}</strong>
                                    </u>
                                    ?
                                </p>
                                <p>
                                    My name is{' '}
                                    <u>
                                        <strong>{props.user.firstName + ' ' + props.user.lastName}</strong>
                                    </u>
                                    , I am calling on behalf of Bay States Group, Cummings & Co..
                                </p>
                                <p>How are you today? </p>
                                <p>
                                    We were calling you in reference to an inquiry you had recently posted on{' '}
                                    <u>
                                        <strong>{adPlatform}</strong>
                                    </u>{' '}
                                    . I see that you were looking to{' '}
                                    <u>
                                        <strong>{leadType}</strong>
                                    </u>{' '}
                                    within the next{' '}
                                    <u>
                                        <strong>{timeFrame}</strong>
                                    </u>
                                    .
                                </p>
                                <p>Are you by chance familiar with the buying process?</p>
                                <p> Was there a time when we could set up a call with one of our premier agents? </p>
                                Talking Points:
                                <ul>
                                    <li>Why are you looking to buy?</li>
                                    <li>Do you have an area in mind?</li>
                                    <li>Have you ever been prequalified?</li>
                                    <li>Home Buyer Seminar interest?</li>
                                </ul>
                            </div>

                            <p></p>
                        </div>
                    </div>
                </div>
            </Draggable>
        );
    } else {
        return <div></div>;
    }
};
