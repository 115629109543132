import React from "react";

export const ExpiredListingsOutput = (props) => {
  return (
    <div className="col">
      <div className="mt-2 mr-2">
        <table className="table table-striped table-sm table-bordered shadow">
          <thead>
            <tr className="table-active">
              <td colSpan="2" className="text-center font-weight-bold">
                Expired Listing
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>MLS Number</td>
              <td>{props.lead.mlsNumber}</td>
            </tr>
            <tr>
              <td>Condition Code</td>
              <td>{props.lead.conditionCode} </td>
            </tr>

            <tr>
              <td>Closing Date</td>
              <td>
                {props.lead.deedRecordDate}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
