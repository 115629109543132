import React, { Component } from "react";
import axios from "axios";
import DangerAlert from "../generalUseComponents/dangerAlert"
export default class TmManualEntryForm extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeLeadType = this.onChangeLeadType.bind(this);
    this.state = {
      leadType: "",
      leadCreated: false,
      lead: "",
      error:""
    };
  }

  onChangeLeadType(e) {
    this.setState({ leadType: e.target.value, error: "" });
  }
  onSubmit(e) {
    e.preventDefault();
    if(this.state.leadType==""){
      this.setState({error: "Must Select a Loan Type"})

    }else{
      let randDigits=(Math.floor(Math.random()*90000) + 10000)
      let newLead = {
        TriggerDate: "",
        dbIdLookup: "MANU" +
        ("0" + (new Date().getMonth() + 1)).slice(-2) +
        ("0" + new Date().getDate()).slice(-2)+randDigits ,
        vendorLeadCode:
          this.state.leadType +
          "MANU" +
          ("0" + (new Date().getMonth() + 1)).slice(-2) +
          ("0" + new Date().getDate()).slice(-2)+ randDigits,
        campaignName: "",
        campaignCode: "",
        b1LastName: "",
        b1FirstName: "",
        b1MiddleInitial: "",
        b1Suffix: "",
        streetAddress: "",
        city: "",
        state: "",
        postalCode: "",
        creditScore: "",
        numberOfMonthsSinceLastINQ: "",
        firstMortgageAmount: "",
        mortgageDate: "",
        loanOneType: "",
        DebtToCredit: "",
        DebtAmount: "",
        closedVaLoans: "",
        phoneNumber: "",
        segment: "",
        newLeadCreated: "",
        leadStatus: "",
        leadCreated: "",
        tm: this.props.user.firstName + " " + this.props.user.lastName,
        tmId: this.props.user._id,
        primaryGoal: "",
        creditInquiryDate: "",
  
        loanBalance: null,
        estimatedValue: null,
        interestRate: "",
        isFixed: "",
        hasMortgageInsurance: "",
        isVeteran: "",
        occupancy: "",
        employment: "",
        hasCoBorrower: "",
        hasPrevCashOut: "",
        FinanceIQ: "",
        callerAtt: "",
        competingOffer: "",
        notes: "",
        isTransferred: "",
        loanOfficer: "",
        transferredDate: "",
      };
      axios.post("backend/activeleads/add/tm/manual", newLead).then((res) => {
        this.props.handleSetManualLead(res.data);
      });

    }

   
   
  }
  render() {
   
      return (
        <div>
          <DangerAlert data={this.state.error}/>
          <form className="form-group" onSubmit={this.onSubmit}>
            <select
              className="form-control"
              value={this.state.leadType}
              onChange={this.onChangeLeadType}
            >
              <option value="">--Select Loan Type--</option>
              <option value="VA">VA</option>
              <option value="FH">FH</option>
              <option value="CO">CO</option>
            </select>
            <div className="mt-2">
              <button type="submit" className="btn btn-primary form-control">
                {" "}
                Create Lead
              </button>
            </div>
          </form>
        </div>
      );
    }
  
}
