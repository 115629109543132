import React, { Component } from "react";

export default class NavbarLink extends Component {
  render() {
    if (this.props.text == "Lead Queue") {
      if (this.props.navBarActive) {
        return (
          <li className="nav-item mr-2  ">
            <div className="badge badge-pill badge-danger lead-queue-count ">
              {this.props.queueCount}
            </div>
            <a
              onClick={this.props.onClickNavBarItem}
              className="nav-link border-bottom bg-light"
              href="#"
              style={{whiteSpace: 'nowrap'}}
            >
              {this.props.text }
            </a>
          </li>
        );
      } else {
        return (
          <li className="nav-item mr-2 ">
            <div className="badge badge-pill badge-danger lead-queue-count ">
              {this.props.queueCount}
            </div>
            <a
              onClick={this.props.onClickNavBarItem}
              className="nav-link bg-light"
              href="#"
              style={{whiteSpace: 'nowrap'}}
            >
              {this.props.text}
            </a>
          </li>
        );
      }
    } else {
      if (this.props.navBarActive) {
        return (
          <li className="nav-item  ">
            <a
              onClick={this.props.onClickNavBarItem}
              className="nav-link border-bottom bg-light"
              href="#"
            >
              {this.props.text}
            </a>
          </li>
        );
      } else {
        return (
          <li className="nav-item  ">
            <a
              onClick={this.props.onClickNavBarItem}
              className="nav-link bg-light"
              href="#"
            >
              {this.props.text}
            </a>
          </li>
        );
      }
    }
  }
}
