import React from "react";
import ScheduleCallBack from "./loInsight/scheduleCallBack";

export const ExpiredListingsStatusAndNotes = (props) => {
  return (
    <div className="col">
      <div className="mt-2 mr-2">
        <table className="table table-striped table-sm table-bordered shadow">
          <thead>
            <tr className="table-active">
              <td colSpan="2" className="text-center font-weight-bold">
                Status and Notes
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Lead Status</td>
              <td>
                <select
                  className="form-control input-group col"
                  value={props.lead.leadStatus}
                  onChange={(e) => props.setters.setLeadStatus(e.target.value)}
                >
                  <option value=""></option>
                  <option value="Call Back Later">Call Back Later</option>
                  <option value="Dead">Dead</option>
                </select>
              </td>
            </tr>
            <ScheduleCallBack
              leadStatus={props.lead.leadStatus}
              callBackLaterDate={props.lead.callBackLaterDate}
              onChangeCallBackLaterDate={props.onChangeCallBackLaterDate}
            />
            <tr>
              <td>Notes</td>
              <td>
                <textarea
                  className="form-control textarea-control"
                  value={props.lead.notes}
                  onChange={(e) => props.setters.setNotes(e.target.value)}
                ></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
