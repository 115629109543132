import React, { Component } from "react";
import edit from "../../../img/edit-icon.png";
import save from "../../../img/save.png";
export default class ComparisonFieldButton extends Component {
  render() {
    if (this.props.edit == true) {
      return (
        <div>
          <button
            type="button"
            className="btn btn-default btn-sm "
            onClick={this.props.onSaveValue}
          >
            <img width="15px" src={save} alt="save icon" /> Save
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <button
            type="button"
            className="btn btn-default btn-sm "
            onClick={this.props.onClickValue}
          >
            <img width="15px" src={edit} alt="edit icon" /> Edit
          </button>
        </div>
      );
    }
  }
}
