import React from "react";
import DangerAlert from "./dangerAlert";

export const UpdatePassword = (props) => {
  return (
    <div className="mt-3 mb-1">
      <h5 className="text-center">User Password</h5>
      <DangerAlert data={props.error}/>
      <div className="row">
        <div className="col-6 text-center">
          <label>password</label>
        </div>
        <div className="col-6">
          <input
            className="form-control"
            value={props.password}
            onChange={(e) => props.setPassword(e.target.value)}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-6 text-center">
          <label>confirm password</label>
        </div>
        <div className="col-6">
          <input
            className="form-control"
            value={props.confirmPassword}
            onChange={(e) => props.setConfirmPassword(e.target.value)}
          />
        </div>
      </div>
      <div className="row mt-2 justify-content-center">
        <button
          className="btn btn-dark"
          onClick={props.onClickUpdateUserPassword}
        >
          Reset Password{" "}
        </button>
      </div>
    </div>
  );
};
