import React from "react";
import TmInsight from "./leadMidSectionComponents/tm-insights";
import TmInsightOld from "./leadMidSectionComponents/tm-insights-old";
import InterviewQuestions from "./leadMidSectionComponents/interview-questions";
import Opportunity from "./leadMidSectionComponents/opportunities";
import LoInsights from "./leadMidSectionComponents/lo-insights";
import { BorrowerLoanInformation } from "./leadMidSectionComponents/borrowerLoanInformation";
import { ExpiredListingsInput } from "./leadMidSectionComponents/expiredListingsInput";
import { ExpiredListingsOutput } from "./leadMidSectionComponents/expiredListingsOutput";
import { ExpiredListingContact } from "./leadMidSectionComponents/expiredListingContact";
import { ExpiredListingsStatusAndNotes } from "./leadMidSectionComponents/expiredListingsStatusAndNotes";
import { YlopoDataFields } from "./leadMidSectionComponents/ylopoDataFields";
import { YlopoDataInput } from "./leadMidSectionComponents/ylopoDataInput";
import { YlopoCrmTransferInput } from "./leadMidSectionComponents/ylopoCrmTransferInput";

export const LeadMidSectionManager = (props) => {
  if (props.campaignName != undefined) {
    if (props.campaignName == "BSEL") {
      return (
        <div className="row">
          <ExpiredListingContact lead={props.lead} setters={props.setters} />
          <ExpiredListingsOutput lead={props.lead} setters={props.setters} />
          <ExpiredListingsInput
            lead={props.lead}
            onChangeCallBackLaterDate={props.onChangeCallBackLaterDate}
            onChangeState={props.onChangeState}
            setNeedsToBeSaved={props.setNeedsToBeSaved}
            setters={props.setters}
          />
          <ExpiredListingsStatusAndNotes
            lead={props.lead}
            setters={props.setters}
            onChangeCallBackLaterDate={props.onChangeCallBackLaterDate}
          />
        </div>
      );
    } else if (props.campaignName == "TRIG") {
      return (
        <div className="row">
          <div className="col"></div>
          <BorrowerLoanInformation lead={props.lead} setters={props.setters} setNeedsToBeSaved={props.setNeedsToBeSaved}/>
          <LoInsights
            user={props.user}
            leadType={props.leadType}
            lead={props.lead}
            onChangeFinanceIQ={props.onChangeFinanceIQ}
            onChangeCallerAttitude={props.onChangeCallerAttitude}
            onChangeCompetingOffer={props.onChangeCompetingOffer}
            onChangeNotes={props.onChangeNotes}
            onChangeLoCallBackLaterDate={props.onChangeLoCallBackLaterDate}
            onChangeLoLeadStatus={props.onChangeLoLeadStatus}
            setNeedsToBeSaved={props.setNeedsToBeSaved}
          />
          <div className="col"></div>
        </div>
      );
    } else if (props.campaignName == "BSYL") {
      return (
        <div className="row ">
          <div className="col-3 m-1">
            <YlopoDataFields lead={props.lead}/>
          </div>
          <div className="col-3 m-1">
            <YlopoDataInput lead={props.lead} setters={props.setters}/>
          </div>
          <div className="col-3">
            <YlopoCrmTransferInput lead={props.lead} setters={props.setters}/>
          </div>
          <div className="col-3"></div>
        </div>
      );
    } else {
      return (
        <div className="row">
          { new Date(props.lead.TriggerDate) < new Date("5/24/2022") ?
            <TmInsightOld
              leadType={props.leadType}
              lead={props.lead}
              handleChangeDate={props.handleChangeDate}
              setNeedsToBeSaved={props.setNeedsToBeSaved}
            /> :
            <TmInsight
              leadType={props.leadType}
              lead={props.lead}
              handleChangeDate={props.handleChangeDate}
              setNeedsToBeSaved={props.setNeedsToBeSaved}
            />
          }
          <InterviewQuestions
            leadType={props.leadType}
            lead={props.lead}
            setters={props.setters}
            onChangePrimaryGoal={props.onChangePrimaryGoal}
            onChangeInterestRate={props.onChangeInterestRate}
            onChangeIsFixed={props.onChangeIsFixed}
            onChangeHasMortgageInsurance={props.onChangeHasMortgageInsurance}
            onChangeIsVeteran={props.onChangeIsVeteran}
            onChangeOccupancy={props.onChangeOccupancy}
            onChangeEmployment={props.onChangeEmployment}
            onChangeHasCoBorrower={props.onChangeHasCoBorrower}
            onChangeHasPreviousCashOut={props.onChangeHasPreviousCashOut.bind(
              this
            )}
            onChangeCreditScore={props.onChangeCreditScore}
            onChangeEstimatedValue={props.onChangeEstimatedValue}
            onChangeLoanBalance={props.onChangeLoanBalance}
            onChangeCreditInquiryDate={props.onChangeCreditInquiryDate}
            onChangeInsuranceInterest={props.onChangeInsuranceInterest}
            onChangeIsSpanishSpeaker={props.onChangeIsSpanishSpeaker}
            setNeedsToBeSaved={props.setNeedsToBeSaved}
          />

          <Opportunity
            user={props.user}
            leadType={props.leadType}
            lead={props.lead}
            setters={props.setters}
            onChangeLeadStatus={props.onChangeLeadStatus}
            onChangeCallBackLaterDate={props.onChangeCallBackLaterDate}
            setNeedsToBeSaved={props.setNeedsToBeSaved}
          />

          <LoInsights
            user={props.user}
            leadType={props.leadType}
            lead={props.lead}
            setters={props.setters}
            onChangeFinanceIQ={props.onChangeFinanceIQ}
            onChangeCallerAttitude={props.onChangeCallerAttitude}
            onChangeCompetingOffer={props.onChangeCompetingOffer}
            onChangeNotes={props.onChangeNotes}
            setNeedsToBeSaved={props.setNeedsToBeSaved}
          />
        </div>
      );
    }
  } else {
    return <div></div>;
  }
};
