import React, { Component } from 'react'

export default class PageNavItems extends Component {
    render() {
        if(this.props.active){
            return (
                <li  className="page-item active ">
                    <a className="page-link ">{this.props.text}</a>
                </li>
            )
        }
        else if(this.props.text==="Next"){
            return (
                <li  className="page-item">
                    <a className="page-link" href="#d" onClick={this.props.onClickNext}>{this.props.text}</a>
                </li>
            )
        } else{
            return (
                <li  className="page-item">
                    <a className="page-link" href="#d" onClick={this.props.onClickPrevious}>{this.props.text}</a>
                </li>
            )

        }
       
    }
}
