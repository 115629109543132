import React, { useState, useEffect } from 'react';
import employmentTest from "./employementTest";
import calcPurchaseEligibility from './calcPurchaseEligibility';

export const Purchase = ({lead}) => {
  const [purchaseStatus, setPurchaseStatus] = useState({ classStr: '', message: '' });

  useEffect(() => {
    const status = calcPurchaseEligibility(lead);
    if (status.classStr && status.message) {
      setPurchaseStatus(status)
    }
  }, [lead])

  const requiredFields = lead.primaryGoal !== '' && lead.creditScore !== '' && lead.isVeteran !== '' && employmentTest(lead.employment, lead.hasCoBorrower) !== "";

  return (
    <tr>
      <td>Purchase</td>
      <td className="results-td">
        {
          requiredFields ? (
            <div id="purchaseResponse" className={purchaseStatus.classStr}>
              {purchaseStatus.message}
            </div>
          ) : <div id="purchaseResponse"></div>}
      </td>
    </tr>
  )
}
