import React, { useState, useEffect } from "react";
import axios from "axios";
import employmentTest from "./employementTest"
import calcFhaStreamlineEligibility from "./calcFhaStreamlineEligibility";

export const FhaStreamLine = ({lead}) => {
    const [fhaFICO, setFhaFICO] = useState('');
    const [jumboThreshold, setJumboThreshold] = useState('');
    const [fhaBase, setFhaBase] = useState('');
    const [fhaStreamlineStatus, setFhaStreamlineStatus] = useState({ classStr: '', message: '' })

    useEffect(() => {
        axios.get("backend/CompField").then((res) => {
            if (res.data && res.data[0]) {
                setFhaFICO(res.data[0].fhaFICO);
                setJumboThreshold(res.data[0].jumboThreshold);
                setFhaBase(res.data[0].vaBaseRate+.125);
            }
        });
    }, [])

    useEffect(() => {
        const status = calcFhaStreamlineEligibility(lead, fhaFICO, jumboThreshold, fhaBase)
        if (status.classStr && status.message) {
            setFhaStreamlineStatus(status)
        }
    }, [lead, fhaFICO, jumboThreshold, fhaBase])

    const requiredFields = lead.mortgageDate !== "" && lead.creditScore !== "" && (lead.loanBalance !== "" && lead.loanBalance!== null) &&
    lead.interestRate !== "" && employmentTest(lead.employment, lead.hasCoBorrower) !== "";

    return (
        <tr>
            <td>FHA Streamline</td>
            <td>
                {
                    requiredFields ? (
                        <div id="fhaStreamlineResponse" className={fhaStreamlineStatus.classStr}>
                            {fhaStreamlineStatus.message}
                        </div>
                    ) : <div id="fhaStreamlineResponse"></div>
                }
            </td>
        </tr>
    )
}
