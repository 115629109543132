import React, { Component } from "react";
import LeadDashboard from "./admin-view-content-components/lead-dashboard";
import EditProfile from "./admin-view-content-components/edit-profile";
import ComparisonFields from "./admin-view-content-components/comparison-fields";
import NewTmLead from "./admin-view-content-components/new-tm-lead";
import DmListUpload from "./admin-view-content-components/dm-list-upload";
import { EditUsers } from "./admin-view-content-components/editUsers/editUsers";
import { ClaimLeadQueue } from "../admin-veiw-components/claimLeadQueue";

export default class LeadDashboardContent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.navbarState.newTmLead) {
      return (
        <NewTmLead
          user={this.props.user}
          onClickLeadDashboard={this.props.onClickLeadDashboard}
          showLead={this.props.showLead}
          handleShowLead={this.props.handleShowLead}
        />
      );
    } else if (this.props.navbarState.leadDashboard) {
      return (
        <LeadDashboard
          user={this.props.user}
          onClickLeadDashboard={this.props.onClickLeadDashboard}
          onClickNewTmLead={this.props.onClickNewTmLead}
          showLead={this.props.showLead}
          handleShowLead={this.props.handleShowLead}
          setNeedsToBeSaved={this.props.setNeedsToBeSaved}
          needsToBeSaved={this.props.needsToBeSaved}
          setOpenConfirmation={this.props.setOpenConfirmation}
        />
      );
    }else if (this.props.navbarState.editProfile) {
      return (
        <EditProfile
          data={this.props.data}
          user={this.props.user}
          updateUser={this.props.updateUser}
        />
      );
    }else if (this.props.navbarState.comparisonField) {
      return <ComparisonFields user={this.props.user} />;
    } else if (this.props.navbarState.importMailer) {
      return <DmListUpload user={this.props.user} />;
    } else if (this.props.navbarState.createUser) {
      return <EditUsers user={this.props.user} />;
    } else if (this.props.navbarState.claimQueue) {
      return (
        <ClaimLeadQueue
          user={this.props.user}
          socket={this.props.socket}
          setShowLead={this.props.setShowLead}
          showLead={this.props.showLead}
          onClickLeadDashboard={this.props.onClickLeadDashboard}
          onClickTmLeadsDashboard={this.props.onClickTmLeadsDashboard}
          claimLeadsQueue={this.props.claimLeadsQueue}
          getQueueLeads={this.props.getQueueLeads}
        />
      );
    }
      else {
      return <div></div>;
    }
  }
}
