import React, { Component } from "react";
var CurrencyFormat = require("react-currency-format");

export default class TmInsightsEquity extends Component {
  render() {
    if (
      this.props.equity == "Need Loan Balance" ||
      this.props.equity == "Need Estimated Value" ||
      this.props.equity == "Need est value and loan balance"
    ) {
      return <div>{this.props.equity}</div>;
    } else {
      return (
        <CurrencyFormat
          value={this.props.equity}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
      );
    }
  }
}
