import React, { useState } from 'react';
import ConversionQueueLogo from '../img/ConversionQueueLogoPNG.png';
import axios from 'axios';

export const ForgotPassword = (props) => {
    const [email, setEmail] = useState('');
    const [showMessage, setShowMessage] = useState(false);

    const onClickResetPassword = (e) => {
        e.preventDefault();
        axios
            .post(`/backend/user/sendPasswordResetLink`, { email })
            .then(() => {
                setShowMessage(true);
            })
            .catch((err) => console.log(err));
    }

    const onClickIcon = () => {
        props.onChangeShowForgotPassword();
    }

    return (
        <div>
            <div>
                <div className="container border border-light w-50 rounded mx-md-auto shadow mt-5">
                    <div className="text-center">
                        <a onClick={onClickIcon}>
                            <img src={ConversionQueueLogo} width="200px" />
                        </a>
                    </div>

                    {showMessage ? (
                        <div className="alert alert-info text-center">
                            Please wait for an email for your reset Password Link. Click on Conversion Queue logo to return to
                            the sign in page.
                        </div>
                    ) : (
                        <div></div>
                    )}

                    <div className="text-center">
                        <form className="form-group mt-3">
                            <div className="input-group">
                                <input
                                    placeholder="Enter your account's email address"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                            <button className="btn btn-primary w-100 mt-2 " onClick={onClickResetPassword}>
                                Send Password Reset Email
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};
