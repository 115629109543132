import React, { useState, useEffect } from "react";
import axios from 'axios'
import employmentTest from "./employementTest"
import calcConvCashoutEligibility from "./calcConvCashoutEligibility";

export const ConvCashout = ({lead}) => {
    const [jumboThreshold, setJumboThreshold] = useState('');
    const [convCashoutStatus, setConvCashoutStatus] = useState({ classStr: '', message: '' });

    useEffect(() => {
        axios.get("backend/CompField").then((res) => {
            if (res.data && res.data[0]) {
                setJumboThreshold(res.data[0].jumboThreshold);
            }
        });
    }, [])

    useEffect(() => {
        const status = calcConvCashoutEligibility(lead, jumboThreshold)
        if (status.classStr && status.message) {
            setConvCashoutStatus(status)
        }
    }, [lead, jumboThreshold])

    const requiredFields = lead.isVeteran !== "" && lead.state !== "" && lead.creditScore !== "" && (lead.loanBalance !== "" && lead.loanBalance !== null) &&
    (lead.estimatedValue !== "" && lead.loanBalance !== null) && lead.occupancy !== "" && employmentTest(lead.employment, lead.hasCoBorrower) !== "";

    return (
        <tr>
          <td>Conv Cashout</td>
          <td>
            {
                requiredFields ? (
                    <div id="convCashoutResponse" className={convCashoutStatus.classStr}>
                        {convCashoutStatus.message}
                    </div>
                ) : <div id="convCashoutResponse"></div>
            }
          </td>
        </tr>
    )
}
