import React, { useState, useEffect } from "react";
import employmentTest from "./employementTest";
import calcVaConversionEligibility from "./calcVaConversionEligibility";

export const VaConversion = ({lead}) => {
  const [vaConversionStatus, setVaConversionStatus] = useState({ classStr: '', message: '' });

  useEffect(() => {
    const status = calcVaConversionEligibility(lead)
    if (status.classStr && status.message) {
      setVaConversionStatus(status)
    }
  }, [lead])

  const txRequiredFields = lead.mortgageDate !== "" && lead.isVeteran !== "" && lead.loanBalance !== "" && lead.loanBalance !== undefined &&
  lead.estimatedValue !== "" && lead.estimatedValue !== undefined && lead.hasPrevCashOut !== "" && lead.hasPrevCashOut !== undefined &&
  employmentTest(lead.employment, lead.hasCoBorrower) !== "";

  const requiredFields = lead.mortgageDate !== "" && lead.isVeteran !== "" && lead.state !== "" && lead.loanBalance !== "" &&
  lead.loanBalance !== undefined && lead.estimatedValue !== "" && lead.estimatedValue !== undefined && employmentTest(lead.employment, lead.hasCoBorrower) !== "";

  if (lead.state === 'TX') {
    return (
      <tr>
        <td>VA Conversion</td>
        <td>
          {
            txRequiredFields ? (
              <div id="vaConversionResponse" className={vaConversionStatus.classStr}>
                {vaConversionStatus.message}
              </div>
            ) : <div id="vaConversionResponse"></div>
          }
        </td>
      </tr>
    )
  }

  return (
    <tr>
      <td>VA Conversion</td>
      <td>
        {
          requiredFields ? (
            <div id="vaConversionResponse" className={vaConversionStatus.classStr}>
              {vaConversionStatus.message}
            </div>
          ) : <div id="vaConversionResponse"></div>
        }
      </td>
    </tr>
  );
}
