import React, { useState, useEffect } from "react";
import employmentTest from "./employementTest";
import axios from "axios";
import calcVAIRRRLEligibility from "./calcVAIRRRLEligibility";

export const VAIRRRL = ({lead}) => {
  const [VABaseRate, setVABaseRate] = useState('');
  const [JumboThreshold, setJumboThreshold] = useState('');
  const [IRRRLFico, setIRRRLFico] = useState('');
  const [VAIRRRLStatus, setVAIRRRLStatus] = useState({ classStr: '', message: '' });

  useEffect(() => {
    axios.get("/backend/CompField").then((resp) => {
      if (resp.data && resp.data[0]) {
        setVABaseRate(resp.data[0].vaBaseRate);
        setJumboThreshold(resp.data[0].jumboThreshold);
        setIRRRLFico(resp.data[0].irrrlFICO);
      }
    });
  }, [])

  useEffect(() => {
    const status = calcVAIRRRLEligibility(lead, VABaseRate, JumboThreshold, IRRRLFico)
    if (status.classStr && status.message) {
      setVAIRRRLStatus(status)
    }
  }, [lead, VABaseRate, JumboThreshold, IRRRLFico])

  const requiredFields = lead.mortgageDate !== '' && lead.creditScore !== '' && lead.loanBalance !== '' && lead.loanBalance !== null &&
  lead.interestRate !== '' && lead.isVeteran !== '' && employmentTest(lead.employment, lead.hasCoBorrower) !== '';

  return (
    <tr>
      <td>VA IRRRL</td>
      <td className="results-td">
        {
          requiredFields ? (
            <div id="vaIRRRLResponse" className={VAIRRRLStatus.classStr}>
              {VAIRRRLStatus.message}
            </div>
          ) : <div id="vaIRRRLResponse"></div>}
      </td>
    </tr>
  )
}
