import employmentTest from "./employementTest";
import calcPaymentsMade from "../../generalUseFunctions/calcPaymentsMade";
import calcIRRRLRate from "./calcIRRRLRate";

export default function calcVAIRRRLEligibility (lead, vaBase, JumboThreshold, IRRRLFico) {
    if (!lead || !lead.vendorLeadCode) {
        return {
            classStr: '',
            message: ''
        }
    }

    const danger = 'font-weight-bold bg-danger text-white';

    const rate = calcIRRRLRate(vaBase, JumboThreshold, lead.creditScore, lead.loanBalance);
    if (employmentTest(lead.employment, lead.hasCoBorrower) === 'Fail') {
        return {
            classStr: danger,
            message: 'Employment Issue',
        }
    }
    if (lead.isVeteran === 'No') {
        return {
            classStr: danger,
            message: 'Not a Veteran',
        }
    }
    if (lead.vendorLeadCode.substring(0, 2) !== 'VA') {
        return {
            classStr: danger,
            message: 'Not a VA Loan',
        }
    }
    if (calcPaymentsMade(lead.mortgageDate) < 5) {
        return {
            classStr: danger,
            message: 'Seasoning Needed: 5 months',
        }
    }
    if (lead.interestRate < rate) {
        return {
            classStr: danger,
            message: `Rate too low: ${rate}`,
        }
    }
    if (lead.creditScore < IRRRLFico && lead.loanBalance > JumboThreshold - 5000) {
        return {
            classStr: danger,
            message: `FICO too low: ${IRRRLFico}`,
        }
    }
    if (lead.loanBalance < 75000) {
        return {
            classStr: danger,
            message: 'Loan Balance too low: $75,000',
        }
    }
    
    return {
        classStr: 'bg-success font-weight-bold text-white',
        message: 'Eligible',
    }
}