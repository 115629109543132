import React, { useState, useEffect } from "react";
import employmentTest from "./employementTest";
import axios from "axios"
import calcVaCashoutEligibility from "./calcVaCashoutEligibility";

export const VaCashout = ({lead}) => {
  const [irrrlFICO, setIrrrlFICO] = useState('');
  const [vaCashoutStatus, setVaCashoutStatus] = useState({ classStr: '', message: '' })

  useEffect(() => {
    axios.get("backend/CompField").then((res) => {
      if (res.data && res.data[0]) {
        setIrrrlFICO(res.data[0].irrrlFICO);            
      }
    });
  }, [])

  useEffect(() => {
    const status = calcVaCashoutEligibility(lead, irrrlFICO);
    if (status.classStr && status.message) {
      setVaCashoutStatus(status)
    }
  }, [lead, irrrlFICO])

  const requiredFields = lead.mortgageDate !== "" && lead.creditScore !== "" && lead.state !== "" && lead.loanBalance !== "" && lead.loanBalance !== null &&
  lead.estimatedValue !== "" && (lead.loanBalance !== null && lead.loanBalance !== "") && lead.occupancy !== "" && employmentTest(lead.employment, lead.hasCoBorrower) !== "";

  return (
    <tr>
      <td>VA Cashout</td>
      <td>
        {
          requiredFields ? (
            <div id="vaCashoutResponse" className={vaCashoutStatus.classStr}>
              {vaCashoutStatus.message}
            </div>
          ) : <div id="vaCashoutResponse"></div>}
      </td>
    </tr>
  );
}
