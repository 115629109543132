import employmentTest from "./employementTest";
import calcLTV from "./calcLTV";

export default function calcConvRateTermEligibility (lead, jumboThreshold, convRtFloor, maxFHAConvRTLTV) {
    if (!lead) {
        return {
            classStr: '',
            message: '',
        }
    }

    const danger = 'font-weight-bold bg-danger text-white';
    const success = 'bg-success font-weight-bold text-white';

    if (employmentTest(lead.employment, lead.hasCoBorrower) === 'Fail') {
        return {
            classStr: danger,
            message: 'Employment Issue',
        }
    }

    if (lead.interestRate < convRtFloor) {
        return {
            classStr: danger,
            message: `Interest rate too low: ${convRtFloor}`,
        }
    }

    if (lead.creditScore < 620) {
        return {
            classStr: danger,
            message: 'FICO too low: 620',
        }
    }

    if (lead.creditScore < 680 && lead.loanBalance > (jumboThreshold - 5000)) {
        return {
            classStr: danger,
            message: 'FICO too low: 680',
        }
    }

    if (calcLTV(lead.loanBalance, lead.estimatedValue) > maxFHAConvRTLTV) {
        return {
            classStr: danger,
            message: `LTV too high: ${maxFHAConvRTLTV}`,
        }
    }

    if (lead.loanBalance < 75000) {
        return {
            classStr: danger,
            message: 'Loan Balance too low: $75,000',
        }
    }

    return {
        classStr: success,
        message: 'Eligible',
    }
}