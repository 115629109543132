import React, { Component } from "react";

export default class InterestRateDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      interestRate: [],
    };
  }
  /* need to fix trailing zeros*/
  componentDidMount() {
    const interestArray = [
      "2.250",
      "2.375",
      "2.500",
      "2.625",
      "2.750",
      "2.875",
      "3.000",
      "3.125",
      "3.250",
      "3.375",
      "3.500",
      "3.625",
      "3.750",
      "3.875",
      "4.000",
      "4.125",
      "4.250",
      "4.375",
      "4.500",
      "4.625",
      "4.750",
      "4.875",
      "5.000",
      "5.125",
      "5.250",
      "5.375",
      "5.500",
      "5.625",
      "5.750",
      "5.875",
      "6.000",
      "6.125",
      "6.250",
      "6.375",
      "6.500",
      "6.625",
      "6.750",
      "6.875",
      "7.000",
      "7.125",
      "7.250",
      "7.375",
      "7.500",
      "7.625",
      "7.750",
      "7.875",
      "8.000",
      "8.125",
      "8.250",
      "8.375",
      "8.500",
      "8.625",
      "8.750",
      "8.875",
      "9.000",
      "9.125",
      "9.250",
      "9.375",
      "9.500",
      "9.625",
      "9.750",
      "9.875",
      "10.000",
    ];

    this.setState({ interestRate: interestArray });
  }

  render() {
    const dropdown = this.state.interestRate.map((rate) => (
      <option key={rate} value={rate}>
        {rate}
      </option>
    ));

      return (
        <div className="input-group-sm">
          <select
            disabled={this.props.disabled}
            className="form-control col"
            value={this.props.interest}
            onChange={(e) => {
              this.props.setNeedsToBeSaved(true);
              this.props.onChangeInterestRate(e.target.value);
            }}
          >
            <option value=""></option>
            {dropdown}
          </select>
        </div>
      );
  }
}
