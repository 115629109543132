import React, { useState, useEffect } from "react";
import axios from 'axios'
import employmentTest from "./employementTest"
import calcConvRateTermEligibility from "./calcConvRateTermEligibility";

export const ConvRateTerm = ({lead}) => {
    const [jumboThreshold, setJumboThreshold] = useState('');
    const [convRtFloor, setConvRtFloor] = useState('');
    const [maxFHAConvRTLTV, setMaxFHAConvRTLTV] = useState('');
    const [convRateTermStatus, setConvRateTermStatus] = useState({ classStr: '', message: '' });

    useEffect(() => {
        axios.get("backend/CompField").then((res) => {
            if (res.data && res.data[0]) {
                setJumboThreshold(res.data[0].jumboThreshold);
                setConvRtFloor(res.data[0].vaBaseRate + 0.375+.75);
                setMaxFHAConvRTLTV(res.data[0].maxFHAConvRTLTV);
            }
        });
    }, [])

    useEffect(() => {
        const status = calcConvRateTermEligibility(lead, jumboThreshold, convRtFloor, maxFHAConvRTLTV)
        if (status.classStr && status.message) {
            setConvRateTermStatus(status)
        }
    }, [lead, jumboThreshold, convRtFloor, maxFHAConvRTLTV])

    const requiredFields = lead.interestRate !== "" && lead.creditScore !== "" && (lead.loanBalance !== "" && lead.loanBalance !== null) &&
    (lead.estimatedValue !== "" && lead.loanBalance !== null) && employmentTest(lead.employment, lead.hasCoBorrower) !== "";

    return (
        <tr>
            <td>Conv Rate/Term</td>
            <td>
                {
                    requiredFields ? (
                        <div id="convRateTermResponse" className={convRateTermStatus.classStr}>
                            {convRateTermStatus.message}
                        </div>
                    ) : <div id="convRateTermResponse"></div>
                }
            </td>
        </tr>
    );
}
