import React, { Component } from "react";
import StateDropdown from "../generalUseComponents/state-dropdown"
var CurrencyFormat = require("react-currency-format");

export default class Contact extends Component {
  constructor(props) {
    super(props);
  
  }

  render() {
    return (
      <div>
        <table className="table table-striped table-sm table-bordered shadow">
          <tbody>
            <tr>
              <td>B1</td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  placeholder="First Name"
                  value={this.props.lead.b1FirstName||''}
                  onChange={(e)=> {
                    this.props.setNeedsToBeSaved(true)
                    this.props.onChangeB1FirstName(e.target.value)
                  }}
                />
              </td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Last Name"
                  value={this.props.lead.b1LastName||''}
                  onChange={(e)=> {
                    this.props.setNeedsToBeSaved(true)
                    this.props.onChangeB1LastName(e.target.value)
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>B2</td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  placeholder="First Name"
                  value={this.props.lead.b2FirstName||''}
                  onChange={(e)=> {
                    this.props.setNeedsToBeSaved(true)
                    this.props.onChangeB2FirstName(e.target.value)
                  }}
                />
              </td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Last Name"
                  value={this.props.lead.b2LastName||''}
                  onChange={(e)=> {
                    this.props.setNeedsToBeSaved(true)
                    this.props.onChangeB2LastName(e.target.value)
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Phone</td>
              <td colSpan="2">
                <CurrencyFormat
                  className="form-control"
                  format="(###) ###-####"
                  mask="_"
                  placeholder="(###) ###-####"
                  value={this.props.lead.phoneNumber != undefined ? this.props.lead.phoneNumber : this.props.lead.importDataCellPhone != undefined ? this.props.lead.importDataCellPhone : ""}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    // formattedValue = $2,223
                    // value ie, 2223
                    this.props.setNeedsToBeSaved(true)
                    this.props.onChangePhoneNumber(value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Address</td>
              <td colSpan="2">
                <input
                  className="w-100 form-control"
                  type="text"
                  placeholder="Street Address"
                  value={this.props.lead.streetAddress}
                  onChange={(e)=> {
                    this.props.setNeedsToBeSaved(true)
                    this.props.onChangeStreetAddress(e.target.value)
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>City</td>
              <td colSpan="2">
                <input
                  className="form-control"
                  type="text"
                  placeholder="city"
                  value={this.props.lead.city}
                  onChange={(e)=> {
                    this.props.setNeedsToBeSaved(true)
                    this.props.onChangeCity(e.target.value)
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>State</td>
              <td colSpan="2">
                <StateDropdown
                  state={this.props.lead.state}
                  onChangeState={this.props.onChangeState}
                  setNeedsToBeSaved={this.props.setNeedsToBeSaved}
                />
              </td>
            </tr>
            <tr>
              <td>Zip</td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  placeholder="zipcode"
                  value={this.props.lead.postalCode}
                  onChange={(e)=>{
                    this.props.setNeedsToBeSaved(true)
                    this.props.onChangeZipCode(e.target.value)
                  }}
                />
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
