import React from "react";
import DatePicker from "react-datepicker";
import StateDropdown from "../generalUseComponents/state-dropdown";

export const YlopoTmInsight = (props) => {
  return (
    <div>
      <div className="row contour-blue text-white text-center">
        <div className="col"> Interview question</div>
      </div>
      <div className="row border">
        <div className="col">Best contact Type</div>
        <div className="col">
          <select
            className="form-control"
            value={props.lead.contactType}
            onChange={(e) => props.setters.setContactType(e.target.value)}
          >
            <option value=""></option>
            <option value="Text">Text</option>
            <option value="Phone">Phone</option>
            <option value="Email">Email</option>
          </select>
        </div>
      </div>
      <div className="row border-bottom border-right border-left">
        <div className="col">Best Contact Time</div>
        <div className="col">
          <DatePicker
            className="form-control"
            selected={props.lead.realtorContactTime}
            onChange={(date) => props.setters.setRealtorContactTime(date)}
            showTimeInput
            dateFormat="MMMM d, yyyy h:mm aa"
          />
        </div>
      </div>
      {/* <div className="row border-bottom border-right border-left">
        <div className="col">Best Contact Date</div>
        <div className="col"></div>
      </div> */}
      <div className="row border-bottom border-right border-left">
        <div className="col">Transaction Type</div>
        <div className="col">
          <select
            className="form-control"
            value={props.lead.transactionType}
            onChange={(e) => props.setters.setTransactionType(e.target.value)}
          >
            <option value=""></option>
            <option value="Purchase">Purchase</option>
            <option value="Listing">Listing</option>
            <option value="Rental">Rental</option>
          </select>
        </div>
      </div>
      <div className="row border-bottom border-right border-left">
        <div className="col">State</div>
        <div className="col">
          <StateDropdown state="MD" />
        </div>
      </div>
      <div className="row border-bottom border-right border-left">
        <div className="col">Price Point</div>
        <div className="col">
          <select
            className="form-control"
            value={props.lead.pricePoint}
            onChange={(e) => props.setters.setPricePoint(e.target.value)}
          >
            <option value=""></option>
            <option value="Not sure">Not Sure</option>
            <option value="<100,000">{"<100,000"}</option>
            <option value="100,000-150,000">100,000-150,000</option>
            <option value="150,001 - 200,000">150,001 - 200,000</option>
            <option value="200,001 - 250,000">200,001 - 250,000</option>
            <option value="250,001 - 300,000">250,001 - 300,000</option>
            <option value=">300,000">{">300,000"}</option>
          </select>
        </div>
      </div>
      <div className="row border-bottom border-right border-left">
        <div className="col">Buyer Status</div>
        <div className="col">
          <select
            className="form-control"
            value={props.lead.buyerStatus}
            onChange={(e) => props.setters.setBuyerStatus(e.target.value)}
          >
            <option value=""></option>
            <option value="First Time">First Time Buyer</option>
            <option value="Subsequent">Subsequent Buyer</option>
            <option value="Dual Transaction">Dual Transaction</option>
            <option value="Renter">Renter</option>
          </select>
        </div>
      </div>
      <div className="row border-bottom border-right border-left">
        <div className="col">Down Payment Assistance</div>
        <div className="col">
          {" "}
          <select
            className="form-control"
            value={props.lead.downPaymentAssistance}
            onChange={(e) =>
              props.setters.setDownPaymentAssistance(e.target.value)
            }
          >
            <option value=""></option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      </div>
      <div className="row border-bottom border-right border-left">
        <div className="col">Buyer Seminar</div>
        <div className="col">
          <select
            className="form-control"
            value={props.lead.buyerSeminar}
            onChange={(e) => props.setters.setBuyerSeminar(e.target.value)}
          >
            <option value=""></option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="Maybe">Maybe</option>
          </select>
        </div>
      </div>
      <div className="row border-bottom border-right border-left">
        <div className="col">Working w/ Agent</div>
        <div className="col">
          <select
            className="form-control"
            value={props.lead.workingWithAgent}
            onChange={(e) => props.setters.setWorkingWithAgent(e.target.value)}
          >
            <option value=""></option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      </div>
    </div>
  );
};
