import React, { Component } from 'react';
import axios from 'axios';
import { LeadMidSectionManager } from './leadMidSectionManager';
import { LeadTopSectionManager } from './leadTopSectionComponents/leadTopSectionManager';
import { useState, useEffect } from 'react';
import { LeadBottomSectionManager } from './leadBottomSectionComponents/leadBottomSectionManager';
import SavingModal from './savingModal';
import { FaScroll } from 'react-icons/fa';
import { YlopoScript } from './leadTopSectionComponents/ylopoScript';
import { StatusLeadModal } from './leadMidSectionComponents/statusLeadModal';
import { ReassignTelemarketer } from './leadBottomSectionComponents/reassignTelemarketer';

export const ActiveLead = (props) => {
    const [LO, setLO] = useState([]);
    const [TriggerDate, setTriggerDate] = useState('');
    const [mailDate, setMailDate] = useState('');
    const [dbIdLookup, setDbIdLookup] = useState('');
    const [vendorLeadCode, setVendorLeadCode] = useState('');
    const [campaignName, setCampaignName] = useState('');
    const [campaignCode, setCampaignCode] = useState('');
    const [b1LastName, setB1LastName] = useState('');
    const [b1FirstName, setB1FirstName] = useState('');
    const [b1MiddleInitial, setB1MiddleInitial] = useState('');
    const [b1Suffix, setB1Suffix] = useState('');
    const [b2LastName, setB2LastName] = useState('');
    const [b2FirstName, setB2FirstName] = useState('');
    const [b2MiddleInitial, setB2MiddleInitial] = useState('');
    const [b2Suffix, setB2Suffix] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [creditScore, setCreditScore] = useState('');
    const [numberOfMonthsSinceLastINQ, setNumberOfMonthsSinceLastINQ] =
        useState('');
    const [firstMortgageAmount, setFirstMortgageAmount] = useState('');
    const [mortgageDate, setMortgageDate] = useState('');
    const [loanOneType, setLoanOneType] = useState('');
    const [DebtToCredit, setDebtToCredit] = useState('');
    const [DebtAmount, setDebtAmount] = useState('');
    const [closedVaLoans, setClosedVaLoans] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [segment, setSegment] = useState('');
    const [newLeadCreated, setNewLeadCreated] = useState('');
    const [leadStatus, setLeadStatus] = useState('');
    const [leadCreated, setLeadCreated] = useState('');
    const [tm, setTm] = useState('');
    const [tmId, setTmId] = useState('');
    const [lo, setLo] = useState('');
    const [loId, setLoId] = useState('');
    const [primaryGoal, setPrimaryGoal] = useState('');
    const [creditInquiryDate, setCreditInquiryDate] = useState('');
    const [loanBalance, setLoanBalance] = useState('');
    const [estimatedValue, setEstimatedValue] = useState('');
    const [interestRate, setInterestRate] = useState('');
    const [isFixed, setIsFixed] = useState('');
    const [hasMortgageInsurance, setHasMortgageInsurance] = useState('');
    const [isVeteran, setIsVeteran] = useState('');
    const [occupancy, setOccupancy] = useState('');
    const [employment, setEmployment] = useState('');
    const [hasCoBorrower, setHasCoBorrower] = useState('');
    const [hasPrevCashOut, setHasPrevCashOut] = useState('');
    const [FinanceIQ, setFinanceIQ] = useState('');
    const [callerAtt, setCallerAtt] = useState('');
    const [competingOffer, setCompetingOffer] = useState('');
    const [notes, setNotes] = useState('');
    const [isTransferred, setIsTransferred] = useState('');
    const [isTransferredCummingsAndCo, setIsTransferredCummingsAndCo] =
        useState('');
    const [transferredDate, setTransferredDate] = useState('');
    const [loanOfficer, setLoanOfficer] = useState('');
    const [loanOfficerName, setLoanOfficerName] = useState('');
    const [callBackLaterDate, setCallBackLaterDate] = useState('');
    const [encompassIdNumber, setEncompassIdNumber] = useState('');
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [transferError, setTransferError] = useState('');
    const [insuranceInterest, setInsuranceInterest] = useState('');
    const [transferLock, setTransferLock] = useState(false);
    const [loadingCRM, setLoadingCRM] = useState(false);
    const [loadingCummings, setLoadingCummings] = useState(false);
    const [ylopoPhoneNumbers, setYlopoPhoneNumbers] = useState([]);
    const [ylopoEmails, setYlopoEmails] = useState([]);
    const [loanType, setLoanType] = useState('');
    const [expiredReason, setExpiredReason] = useState('');
    const [contactType, setContactType] = useState('');
    const [importDataEmail, setImportDataEmail] = useState('');
    const [realtorContactTime, setRealtorContactTime] = useState();
    const [prequalified, setPrequalified] = useState('');
    const [firstTimeBuyer, setFirstTimeBuyer] = useState('');
    const [workingWithAgent, setWorkingWithAgent] = useState('');
    const [transactionType, setTransactionType] = useState('');
    const [pricePoint, setPricePoint] = useState('');
    const [buyerStatus, setBuyerStatus] = useState('');
    const [downPaymentAssistance, setDownPaymentAssistance] = useState('');
    const [buyerSeminar, setBuyerSeminar] = useState('');
    const [showScript, setShowScript] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [deadReason, setDeadReason] = useState('');
    const [isSpanishSpeaker, setIsSpanishSpeaker] = useState("")
    const [ylopoId, setYlopoId] = useState();
    const [ylopoSource, setYlopoSource] = useState();
    const [ylopoTags, setYlopoTags] = useState([]);
    const [ylopoPrice, setYlopoPrice] = useState();

    const onCloseLead = () => {
        if (props.needsToBeSaved === true) {
            props.setOpenConfirmation(true);

            if (props.user.userType === 'TM') {
                props.onClickNewTmLead();
            }
        }

        if (props.needsToBeSaved === false) {
            props.handleSetLead('');

            if (props.user.userType === 'TM') {
                props.onClickNewTmLead();
            }
        }
    };

    const getOpportunitiesJson = () => {
        return {
            dbIdLookup: dbIdLookup,
            purchase:
                document.getElementById('purchaseResponse') != null
                    ? document.getElementById('purchaseResponse').textContent
                    : '',
            vaIRRRL:
                document.getElementById('vaIRRRLResponse') != null
                    ? document.getElementById('vaIRRRLResponse').textContent
                    : '',
            vaCashout:
                document.getElementById('vaCashoutResponse') != null
                    ? document.getElementById('vaCashoutResponse').textContent
                    : '',
            vaConversion:
                document.getElementById('vaConversionResponse') != null
                    ? document.getElementById('vaConversionResponse')
                          .textContent
                    : '',
            fhaStreamline:
                document.getElementById('fhaStreamlineResponse') != null
                    ? document.getElementById('fhaStreamlineResponse')
                          .textContent
                    : '',
            fhaCashout:
                document.getElementById('fhaCashoutResponse') != null
                    ? document.getElementById('fhaCashoutResponse').textContent
                    : '',
            fhaRateTerm:
                document.getElementById('fhaRateTermResponse') != null
                    ? document.getElementById('fhaRateTermResponse').textContent
                    : '',
            convCashout:
                document.getElementById('convCashoutResponse') != null
                    ? document.getElementById('convCashoutResponse').textContent
                    : '',
            convRateTerm:
                document.getElementById('convRateTermResponse') != null
                    ? document.getElementById('convRateTermResponse')
                          .textContent
                    : '',
            renovation:
                document.getElementById('renovationResponse') != null
                    ? document.getElementById('renovationResponse').textContent
                    : '',
            other:
                document.getElementById('otherResponse') != null
                    ? document.getElementById('otherResponse').textContent
                    : '',
            armConversion:
                document.getElementById('armConversionResponse') != null
                    ? document.getElementById('armConversionResponse')
                          .textContent
                    : '',
            miRemoval:
                document.getElementById('miRemovalResponse') != null
                    ? document.getElementById('miRemovalResponse').textContent
                    : '',
            refiNow:
                document.getElementById('refiNowlResponse') != null
                    ? document.getElementById('refiNowlResponse').textContent
                    : '',
            contourCommunityLending:
                document.getElementById('contourCommunityLendingEligibilityResponse') != null
                    ? document.getElementById('contourCommunityLendingEligibilityResponse').textContent
                    : '',
        };
    };

    useEffect(() => {
        if (props.lead != undefined) {
            if (
                props.lead.campaignName === 'BSYL' ||
                props.lead.campaignName === 'TRIG'
            ) {
                setShowScript(true);
            }
        }
    }, [props.lead]);
    const handleChangeDate = (e) => {
        if (e == null) {
            setMortgageDate('');
        } else {
            let monthString = ('0' + (e.getMonth() + 1)).slice(-2);

            setMortgageDate(new Date(e).getFullYear().toString() + monthString);
        }
    };
    const onChangeCreditInquiryDate = (e) => {
        if (e == null) {
            setCreditInquiryDate('');
        } else {
            let monthString = ('0' + (e.getMonth() + 1)).slice(-2);

            setCreditInquiryDate(
                new Date(e).getFullYear().toString() + monthString
            );
        }

        props.setNeedsToBeSaved(true);
    };
    const onSaveAndClose = async (e) => {
        const updateLead = getWholeState();
        let opportunitiesJson = await getOpportunitiesJson();
        axios.post(
            '/backend/activeleads/update/tm/opportunities',
            opportunitiesJson
        );

        axios.post('backend/activeleads/tm/save', updateLead).then((res) => {
            alert('Lead Saved');
            props.setNeedsToBeSaved(false);
            props.handleSetLead('');
            props.getNewPageOfLeads(1);
            if (props.user.userType === 'TM') {
                props.onClickNewTmLead();
            }
        });
    };

    const onSave = async (e) => {
        const updateLead = getWholeState();
        let opportunitiesJson = await getOpportunitiesJson();
        axios.post(
            '/backend/activeleads/update/tm/opportunities',
            opportunitiesJson
        );

        axios.post('backend/activeleads/tm/save', updateLead).then((res) => {
            alert('Lead Saved');
            props.setNeedsToBeSaved(false);
        });
    };
    const continueWithoutSaving = () => {
        props.handleSetLead('');

        props.setNeedsToBeSaved(false);
    };
    const onChangeLoanOfficer = (e) => {
        setLoanOfficer(e.target.value);
        setLoanOfficerName(e.target.selectedOptions[0].label);
    };

    const getSetters = () => {
        return {
            setYlopoPhoneNumbers,
            setYlopoEmails,
            setNeedsToBeSaved: props.setNeedsToBeSaved,
            setPrimaryGoal,
            setState,
            setNotes,
            setB1FirstName,
            setB1LastName,
            setB2FirstName,
            setB2LastName,
            setPhoneNumber,
            setStreetAddress,
            setCity,
            setPostalCode,
            setLoanType,
            setContactType,
            setExpiredReason,
            setCallerAtt,
            setLeadStatus,
            setImportDataEmail,
            setRealtorContactTime,
            setPrequalified,
            setFirstTimeBuyer,
            setWorkingWithAgent,
            setTransactionType,
            setPricePoint,
            setBuyerStatus,
            setDownPaymentAssistance,
            setBuyerSeminar,
            setDeadReason,
            setCallBackLaterDate,
        };
    };
    const getWholeState = () => {
        return {
            ...props.lead,
            _id: props.lead != undefined ? props.lead._id : null,
            TriggerDate,
            dbIdLookup,
            vendorLeadCode,
            campaignName,
            campaignCode,
            b1LastName,
            b1FirstName,
            b1MiddleInitial,
            b1Suffix,
            b2LastName,
            b2FirstName,
            b2MiddleInitial,
            b2Suffix,
            streetAddress,
            city,
            state,
            postalCode,
            creditScore,
            numberOfMonthsSinceLastINQ,
            firstMortgageAmount,
            mortgageDate,
            loanOneType,
            DebtToCredit,
            DebtAmount,
            closedVaLoans,
            phoneNumber,
            segment,
            newLeadCreated,
            leadStatus,
            leadCreated,
            tm,
            tmId,
            primaryGoal,
            creditInquiryDate,
            loanBalance,
            estimatedValue,
            interestRate,
            isFixed,
            hasMortgageInsurance,
            isVeteran,
            occupancy,
            employment,
            hasCoBorrower,
            hasPrevCashOut,
            FinanceIQ,
            callerAtt,
            competingOffer,
            notes,
            isTransferred,
            transferredDate,
            loanOfficer,
            loanOfficerName,
            callBackLaterDate,
            insuranceInterest,
            importDataLastLoanOfficer:
                props.lead != undefined
                    ? props.lead.importDataLastLoanOfficer
                    : null,

            importDataAdverseReason:
                props.lead != undefined
                    ? props.lead.importDataAdverseReason
                    : null,
            encompassFileStarter:
                props.lead != undefined
                    ? props.lead.encompassFileStarter
                    : null,
            importDataLoanPurpose:
                props.lead != undefined
                    ? props.lead.importDataLoanPurpose
                    : null,
            importDataOccupancyType:
                props.lead != undefined
                    ? props.lead.importDataOccupancyType
                    : null,
            importDataCellPhone:
                props.lead != undefined ? props.lead.importDataCellPhone : null,
            importDataEmail: importDataEmail,
            importDataLastFinishedMilestone:
                props.lead != undefined
                    ? props.lead.importDataLastFinishedMilestone
                    : null,
            importDataClosingDate:
                props.lead != undefined
                    ? props.lead.importDataClosingDate
                    : null,
            importDataLoanAmount:
                props.lead != undefined
                    ? props.lead.importDataLoanAmount
                    : null,
            importDataNoteRate:
                props.lead != undefined ? props.lead.importDataNoteRate : null,
            importDataFico:
                props.lead != undefined ? props.lead.importDataFico : null,
            importDataLoanProgram:
                props.lead != undefined
                    ? props.lead.importDataLoanProgram
                    : null,
            mlsNumber: props.lead != undefined ? props.lead.mlsNumber : null,
            conditionCode:
                props.lead != undefined ? props.lead.conditionCode : null,
            ylopoDataFields:
                props.lead != undefined ? props.lead.ylopoDataFields : null,
            ylopoPhoneNumbers,
            ylopoEmails,
            loanType,
            isTransferredCummingsAndCo,
            expiredReason,
            contactType,
            isSpanishSpeaker,
            realtorContactTime,
            prequalified,
            workingWithAgent,
            transactionType,
            pricePoint,
            buyerStatus,
            downPaymentAssistance,
            buyerSeminar,
            deadReason,
            freddieFannieIndictor: props.lead.freddieFannieIndictor,
        };
    };
    const onCRMTransfer = () => {
        if (loanOfficer === '' || loanOfficer === undefined) {
            setTransferError('Must Select Loan Officer before Transferring');
        } else {
            setLoadingCRM(true);
            setTransferLock(true);

            const updateLead = getWholeState();

            let opportunitiesJson = getOpportunitiesJson();
            axios
                .post('/backend/transfer/CRMTransfer', updateLead)
                .then(() => {
                    setLoadingCRM(false);
                    axios.post(
                        '/backend/activeleads/update/tm/opportunities',
                        opportunitiesJson
                    );
                    axios
                        .post('backend/activeleads/tm/transfer', updateLead)
                        .then(() => {
                            props.setNeedsToBeSaved(false);
                        });
                    setIsTransferred(true);
                    setLeadStatus('Transferred');
                    setTransferredDate(Date.now());
                    alert('Transfer to CRM was Successful');
                })
                .catch((err) => {
                    setLoadingCRM(false);
                    if (err.response !== undefined) {
                        if (err.response.status === 403) {
                            window.location.reload();
                        } else {
                            alert('Transfer Error: unable to transfer to CRM');
                        }
                    } else {
                        alert('Transfer Error: unable to transfer to CRM');
                    }
                });
        }
    };

    const onCummingsTransfer = (type, stage, agent) => {
        const updateLead = getWholeState();
        setLoadingCummings(true);
        onSave();

        let reqJson = {
            transferStage: stage,
            transferType: type,
            lead: getWholeState(),
        };
        if (agent != undefined) {
            reqJson = {
                ...reqJson,
                selectedAgent: agent,
            };
        }

        axios
            .post('/backend/transfer/CummingsAndCoTransfer', reqJson)
            .then(() => {
                setLoadingCummings(false);
                setIsTransferredCummingsAndCo('true');
                onSave();
            })
            .catch(() => {
                setLoadingCummings(false);
            });
    };

    useEffect(() => {
        axios
            .get('/backend/user/LO')
            .then((res) => {
                setLO(res.data.users ? res.data.users : []);
            })
            .catch((err) => {
                console.log(err);
            });

        if (props.lead != undefined) {
            setTriggerDate(props.lead.TriggerDate);
            setDbIdLookup(props.lead.dbIdLookup);
            setVendorLeadCode(props.lead.vendorLeadCode);
            setCampaignName(props.lead.campaignName);
            setCampaignCode(props.lead.campaignCode);
            setB1LastName(props.lead.b1LastName);
            setB1FirstName(props.lead.b1FirstName);
            setB1MiddleInitial(props.lead.b1MiddleInitial);
            setB1Suffix(props.lead.b1Suffix);
            setB2LastName(props.lead.b2LastName);
            setB2FirstName(props.lead.b2FirstName);
            setB2MiddleInitial(props.lead.b2MiddleInitial);
            setB2Suffix(props.lead.b2Suffix);
            setStreetAddress(props.lead.streetAddress);
            setCity(props.lead.city);
            setState(props.lead.state);
            setPostalCode(props.lead.postalCode);
            setCreditScore(props.lead.creditScore);
            setNumberOfMonthsSinceLastINQ(
                props.lead.numberOfMonthsSinceLastINQ
            );
            setFirstMortgageAmount(props.lead.firstMortgageAmount);
            setMortgageDate(props.lead.mortgageDate);
            setLoanOneType(props.lead.loanOneType);
            setDebtToCredit(props.lead.DebtToCredit);
            setDebtAmount(props.lead.DebtAmount);
            setClosedVaLoans(props.lead.closedVaLoans);
            setPhoneNumber(props.lead.phoneNumber);
            setSegment(props.lead.segment);
            setNewLeadCreated(props.lead.newLeadCreated);
            setLeadStatus(props.lead.leadStatus);
            setLeadCreated(props.lead.leadCreated);
            setTm(props.lead.tm);
            setTmId(props.lead.tmId);
            setPrimaryGoal(props.lead.primaryGoal);
            setCreditInquiryDate(props.lead.creditInquiryDate);
            setLoanBalance(props.lead.loanBalance);
            setEstimatedValue(props.lead.estimatedValue);
            setInterestRate(props.lead.interestRate);
            setIsFixed(props.lead.isFixed);
            setHasMortgageInsurance(props.lead.hasMortgageInsurance);
            setIsVeteran(props.lead.isVeteran);
            setOccupancy(props.lead.occupancy);
            setEmployment(props.lead.employment);
            setHasCoBorrower(props.lead.hasCoBorrower);
            setHasPrevCashOut(props.lead.hasPrevCashOut);
            setFinanceIQ(props.lead.FinanceIQ);
            setCallerAtt(props.lead.callerAtt);
            setCompetingOffer(props.lead.competingOffer);
            setNotes(props.lead.notes);
            setIsTransferred(props.lead.isTransferred);
            setIsTransferredCummingsAndCo(
                props.lead.isTransferredCummingsAndCo
            );
            setLoanOfficer(props.lead.loanOfficer);
            setLoanOfficerName(props.lead.loanOfficerName);
            setTransferredDate(props.lead.transferredDate);
            setEncompassIdNumber(props.lead.encompassIdNumber);
            setCallBackLaterDate(props.lead.callBackLaterDate);
            setInsuranceInterest(props.lead.insuranceInterest);
            setImportDataEmail(props.lead.importDataEmail);
            setRealtorContactTime(
                props.lead.realtorContactTime != undefined
                    ? new Date(props.lead.realtorContactTime)
                    : new Date()
            );
            setFirstTimeBuyer(props.lead.firstTimeBuyer);
            setWorkingWithAgent(props.lead.workingWithAgent);
            setPrequalified(props.lead.prequalified);
            setTransactionType(props.lead.transactionType);
            setPricePoint(props.lead.pricePoint);
            setBuyerStatus(props.lead.buyerStatus);
            setDownPaymentAssistance(props.lead.downPaymentAssistance);
            setBuyerSeminar(props.lead.downPaymentAssistance);
            setYlopoEmails(
                props.lead.ylopoEmails == undefined
                    ? []
                    : props.lead.ylopoEmails
            );
            setYlopoPhoneNumbers(
                props.lead.ylopoPhoneNumbers == undefined
                    ? []
                    : props.lead.ylopoPhoneNumbers
            );
            setContactType(props.lead.contactType);
            setExpiredReason(props.lead.expiredReason);
            setIsSpanishSpeaker(props.lead.isSpanishSpeaker)
        }
    }, [props.lead]);
    return (
        <div>
            <div>
                <SavingModal
                    openConfirmation={openConfirmation}
                    continueWithoutSaving={continueWithoutSaving}
                    setOpenConfirmation={setOpenConfirmation}
                />
                <StatusLeadModal
                    show={showStatusModal}
                    setShow={setShowStatusModal}
                    lead={getWholeState()}
                    setters={getSetters()}
                    onSave={onSave}
                />
            </div>
            <div className="row justify-content-between mt-1">
                <h4 className="ml-3 mt-2 border-bottom border-">
                    Reference ID: {vendorLeadCode}
                </h4>

                <div className="mt-1">
                    {campaignName == 'BSYL' || campaignName == 'TRIG' ? (
                        <a
                            className="btn btn-warning mr-1"
                            onClick={() => setShowScript(true)}
                        >
                            <FaScroll />
                        </a>
                    ) : (
                        <></>
                    )}
                    {campaignName == 'BSYL' ? (
                        <a
                            className="btn btn-dark mr-1"
                            onClick={() => setShowStatusModal(true)}
                        >
                            Status Lead
                        </a>
                    ) : (
                        <></>
                    )}

                    <button
                        className="btn btn-primary btn-small mr-1"
                        onClick={onSave}
                    >
                        Save Lead
                    </button>
                    <button
                        className="btn btn-primary btn-small mr-4"
                        onClick={onCloseLead}
                    >
                        Close Lead
                    </button>
                </div>
            </div>
            <YlopoScript
                show={showScript}
                setShow={setShowScript}
                lead={getWholeState()}
                user={props.user}
            />
            <LeadTopSectionManager
                campaignName={campaignName}
                leadType={props.leadType}
                lead={getWholeState()}
                user={props.user}
                onChangeB1FirstName={setB1FirstName}
                onChangeB1LastName={setB1LastName}
                onChangeB2FirstName={setB2FirstName}
                onChangeB2LastName={setB2LastName}
                onChangePhoneNumber={setPhoneNumber}
                onChangeStreetAddress={setStreetAddress}
                onChangeCity={setCity}
                onChangeState={setState}
                onChangeZipCode={setPostalCode}
                setNeedsToBeSaved={props.setNeedsToBeSaved}
            />
            <br />
            <LeadMidSectionManager
                campaignName={campaignName}
                leadType={props.leadType}
                lead={getWholeState()}
                user={props.user}
                onChangeState={setState}
                handleChangeDate={handleChangeDate}
                onChangePrimaryGoal={setPrimaryGoal}
                onChangeInterestRate={setInterestRate}
                onChangeIsFixed={setIsFixed}
                onChangeHasMortgageInsurance={setHasMortgageInsurance}
                onChangeIsVeteran={setIsVeteran}
                onChangeOccupancy={setOccupancy}
                onChangeEmployment={setEmployment}
                onChangeHasCoBorrower={setHasCoBorrower}
                onChangeHasPreviousCashOut={setHasPrevCashOut}
                onChangeCreditScore={setCreditScore}
                onChangeEstimatedValue={setEstimatedValue}
                onChangeLoanBalance={setLoanBalance}
                onChangeCreditInquiryDate={setCreditInquiryDate}
                onChangeInsuranceInterest={setInsuranceInterest}
                onChangeLeadStatus={setLeadStatus}
                onChangeCallBackLaterDate={setCallBackLaterDate}
                onChangeFinanceIQ={setFinanceIQ}
                onChangeCallerAttitude={setCallerAtt}
                onChangeCompetingOffer={setCompetingOffer}
                onChangeNotes={setNotes}
                onChangeIsSpanishSpeaker={setIsSpanishSpeaker}
                setNeedsToBeSaved={props.setNeedsToBeSaved}
                setters={getSetters()}
            />

            <LeadBottomSectionManager
                campaignName={campaignName}
                leadType={props.leadType}
                lead={getWholeState()}
                user={props.user}
                LO={LO}
                onSave={onSave}
                onSaveAndClose={onSaveAndClose}
                onCRMTransfer={onCRMTransfer}
                transferLock={transferLock}
                onChangeLoanOfficer={onChangeLoanOfficer}
                loadingCRM={loadingCRM}
                loadingCummings={loadingCummings}
                onCummingsTransfer={onCummingsTransfer}
            />
            {props.user.matrixLeadPermissions.includes('Reassign TM') ? (
                <div className="row bg-light justify-content-center">
                    <div className="col-2">
                        <label className="mt-1"> Assigned Telemarketer</label>
                    </div>
                    <div className="col-2">
                        <ReassignTelemarketer
                            show={props.user.matrixLeadPermissions.includes(
                                'Reassign TM'
                            )}
                            leadId={props.lead._id}
                            activeLead={getWholeState()}
                            setTm={setTm}
                            setTmId={setTmId}
                        />
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};
