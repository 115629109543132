import React from "react"; import DangerAlert from "../generalUseComponents/dangerAlert";

export const AlreadyTransferrePage = ({ handleMessage, lead }) => {
  return (
    <>
      <div className="row p-3">
        <table className="col-6  table table-striped table-sm table-bordered shadow">
          <thead>
            <tr>
              <td colSpan={2} className="text-center bg-danger text-white">{handleMessage}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Vendor Lead Code</td>
              <td>
                <input className="form-control"
                  type="text"
                  value={lead?.vendorLeadCode || ''}
                />
              </td>
            </tr>
            <tr>
              <td>Transferred Date</td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  value={new Date(lead?.transferredDate).toLocaleString() || ''}
                />
              </td>
            </tr>
            <tr>
              <td>Loan Officer</td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  value={lead?.LOName || ''}
                />
              </td>
            </tr>
            <tr>
              <td>Telemarketer</td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  value={lead?.TMName || ""}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}
