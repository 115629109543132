import React, { useState, useEffect } from "react";
import axios from "axios"
import calcRefiNowEligibility from "./calcRefiNowEligibility";

export const RefiNow = ({lead}) => {
  const [jumboThreshold, setJumboThreshold] = useState("");
  const [refiNowStatus, setRefiNowStatus] = useState({ classStr: '', message: '' });

    useEffect(() => {
        axios.get("/backend/CompField").then((resp) => {
            if (resp.data && resp.data[0]) {
                setJumboThreshold(resp.data[0].jumboThreshold);
            }
        });
    }, []);

    useEffect(() => {
        const status = calcRefiNowEligibility(lead, jumboThreshold)
        if (status.classStr && status.message) {
            setRefiNowStatus(status)
        }
    }, [lead, jumboThreshold])

    const requiredFields = lead.loanBalance !== "" && lead.creditScore !== "" && lead.occupancy !== "" &&
    lead.estimatedValue !== "" && lead.mortgageDate !== undefined && lead.mortgageDate !== "";

  return (
    <tr>
        <td>Refi Now</td>
        <td className="results-td">
            {
                requiredFields ? (
                    <div id="refiNowResponse" className={refiNowStatus.classStr}>
                        {refiNowStatus.message}
                    </div>
                ) : <div id="refiNowResponse"></div>
            }
        </td>
    </tr>
  );
};
