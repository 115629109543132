import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import axios from "axios";
import DangerAlert from "../../generalUseComponents/dangerAlert";
import { PermissionModal } from "./permissionModal";

export const CreateUserModal = (props) => {
  const [userType, setUserType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmedPassword] = useState("");
  const [crmNavPermissions, setCrmNavPermissions] = useState([]);
  const [matrixNavPermissions, setMatrixNavPermissions] = useState([]);
  const [matrixLeadPermissions, setMatrixLeadPermissions] = useState([]);
  const [crmLeadPermissions, setCrmLeadPermissions] = useState([]);
  const [branchPermissions, setBranchPermissions] = useState([]);
  const [sitePermissions, setSitePermissions] = useState([]);
  const [branchCode, setBranchCode] = useState("");
  const [showBuildPermissions, setShowBuildPermissions] = useState(false);
 
  const [error, setError] = useState("");

  useEffect(() => {
    if (password == confirmPassword) {
      setError("");
    }
  }, [password, confirmPassword]);

  const createUser = () => {
    if (password == confirmPassword) {
      const userJson = {
        userType: userType,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        fullName: firstName.trim() + " " + lastName.trim(),
        email: email,
        password: password,
        userId: props.user._id,
        crmNavPermissions: crmNavPermissions,
        matrixNavPermissions: matrixNavPermissions,
        matrixLeadPermissions: matrixLeadPermissions,
        crmLeadPermissions: crmLeadPermissions,
        branchPermissions: branchPermissions,
        sitePermissions: sitePermissions
      };
      axios.post( "backend/user/add", userJson).then((resp) => {
        alert(userJson.fullName+"\'s profile added")
        props.setShow(false);
      });
    } else {
      setError("Please confirm that the password match");
    }
  };

  useEffect(() => {
      axios.post( "backend/user/getPermissionTemplate", {userType: userType}).then((resp) => {
        if (resp.data){
          if (resp.data.userType == "TM" || resp.data.userType == "Manager"){
            setCrmNavPermissions(resp.data.crmNavPermissions)
            setMatrixNavPermissions(resp.data.matrixNavPermissions)
            setMatrixLeadPermissions(resp.data.matrixLeadPermissions)
            setCrmLeadPermissions(resp.data.crmLeadPermissions)
            setBranchPermissions(["410"])
            setSitePermissions(resp.data.sitePermissions)
          }
          else {
            setCrmNavPermissions(resp.data.crmNavPermissions)
            setMatrixNavPermissions(resp.data.matrixNavPermissions)
            setMatrixLeadPermissions(resp.data.matrixLeadPermissions)
            setCrmLeadPermissions(resp.data.crmLeadPermissions)
            setBranchPermissions(props.branchCodes)
            setSitePermissions(resp.data.sitePermissions)
          }
        }
      });
  }, [userType])

  
    

  return (
    <Modal show={props.show}>
      <Modal.Header>
        <h4>Create User</h4>
        <button className="btn btn-danger" onClick={() => props.setShow(false)}>
          <AiOutlineClose />
        </button>
      </Modal.Header>
      <Modal.Body>
        <DangerAlert data={error} />
        <PermissionModal 
          setShowBuildPermissions={setShowBuildPermissions} 
          showBuildPermissions={showBuildPermissions}
          crmNavPermissions={crmNavPermissions}
          setCrmNavPermissions={setCrmNavPermissions}
          matrixNavPermissions={matrixNavPermissions}
          setMatrixNavPermissions={setMatrixNavPermissions}
          matrixLeadPermissions={matrixLeadPermissions}
          setMatrixLeadPermissions={setMatrixLeadPermissions}
          crmLeadPermissions={crmLeadPermissions}
          setCrmLeadPermissions={setCrmLeadPermissions}
          branchPermissions={branchPermissions}
          setBranchPermissions={setBranchPermissions}
          sitePermissions={sitePermissions}
          setSitePermissions={setSitePermissions}
          branchCodes={props.branchCodes}
        />
        <label>User Type</label>
        <select
          className="form-control input-group col shadow"
          value={userType}
          onChange={(e) => setUserType(e.target.value)}
        >
          <option value="">--User Type--</option>
          <option value="TM">Telemarketer</option>
          <option value="Manager">Manager</option> 
          <option value="LO">Loan Officer</option>       
          <option value="Admin">Admin</option>
        </select>

        <div className=" row mt-2">
          <div className="col">
            <input
              type="text"
              className="form-control shadow"
              placeholder="First Name"
              required="require"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="col">
            <input
              type="text"
              className="form-control shadow"
              placeholder="Last Name"
              required="require"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>

        <input
          type="text"
          className="form-control  col mt-2 shadow"
          placeholder="Email"
          required="require"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <input
          type="text"
          className="form-control  col mt-2  shadow"
          placeholder="Password"
          required="require"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="text"
          className="form-control  col mt-2  shadow"
          placeholder="Confirm Password"
          required="require"
          value={confirmPassword}
          onChange={(e) => setConfirmedPassword(e.target.value)}
        />

        <div className="text-center">
          <button className="mt-2 btn btn-primary col-8"  onClick={() => setShowBuildPermissions(true)}>
            {" "}
            Permissions
          </button>
        </div>  

        <div className="text-center">
          <button className="mt-2 btn btn-primary col-8" onClick={createUser}>
            {" "}
            Create User
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
