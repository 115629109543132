export default function calcEquity(estimatedValue, loanBalance) {
  if (estimatedValue == null && loanBalance == null) {
    return "Need est value and loan balance";
  } else if (estimatedValue == "" && loanBalance == "") {
    return "Need est value and loan balance";
  }else if(estimatedValue == "" ||estimatedValue == null){
    return "Need Estimated Value"
  } else if(loanBalance == ""||loanBalance == null){
    return "Need Loan Balance"
  }
  else {
    return estimatedValue - loanBalance;
  }
}
