export default function getLTVConfidence(ltv) {
    if (!ltv) return '';
    const firstDigit = ltv.charAt(0);
    switch (firstDigit) {
        case '1':
            return 'Extremely Likely';
        case '2':
            return 'Highly Likely';
        case '3':
            return 'Likely';
        default:
            return '';
    }
}