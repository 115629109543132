import React, { useState, useEffect } from "react";
import Tooltip from "react-bootstrap/Tooltip";
import { CgAdd } from "react-icons/cg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { YlopoTmInsight } from "./ylopoTmInsight";
var CurrencyFormat = require("react-currency-format");

export const YlopoDataInput = (props) => {
  const [email, setEmail] = useState("");
  const [emailType, setEmailType] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneType, setPhoneType] = useState("");

  let emailItems = props.lead.ylopoEmails.map((email) => {
    return (
      <div className="row mt-1 justify-content-center bg-light">
        <div className="col-2 p-2 mt-2 ">{email.type + " :"}</div>
        <div className="col-9  mt-2  form-control ">{email.value}</div>
      </div>
    );
  });

  let phoneItems = props.lead.ylopoPhoneNumbers.map((phone) => {
    return (
      <div className="row mt-1 justify-content-center bg-light">
        {" "}
        <div className="col-2 p-2 mt-2 ">{phone.type + " :"}</div>
        <div className="col-4 p-1 text-center">
          <CurrencyFormat
            className="form-control "
            format=" (###) ###-####"
            mask="_"
            placeholder=" (###) ###-####"
            value={phone.value}
          />
        </div>
      </div>
    );
  });

  useEffect(() => {
    let firstName = props.lead.ylopoDataFields.find((item) => {
      if (item.label == "First Name") {
        return item;
      } else {
        return undefined;
      }
    });

    if (firstName != undefined && props.lead.b1FirstName == undefined) {
      props.setters.setB1FirstName(firstName.value);
    }
    let lastName = props.lead.ylopoDataFields.find((item) => {
      if (item.label == "Last Name") {
        return item;
      } else {
        return undefined;
      }
    });

    if (lastName != undefined && props.lead.b1LastName == undefined) {
      props.setters.setB1LastName(lastName.value);
    }
  }, []);
  return (
    <div>
      <div className="row border">
        <div className="col">
          <div className="row contour-blue text-white text-center ">
            {" "}
            <div className="col"> Update Contact</div>
          </div>
          <div className="row justify-content-center mt-1 ">
            <div className="col">
              <label> First Name</label>
              <input
                className="form-control"
                type="text"
                value={props.lead.b1FirstName}
                onChange={(e) => {
                  props.setters.setB1FirstName(e.target.value);
                }}
              />
            </div>
            <div className="col">
              <label>Last Name</label>
              <input
                className="form-control"
                type="text"
                value={props.lead.b1LastName}
                onChange={(e) => {
                  props.setters.setB1LastName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="row justify-content-center mt-1 ">
            <div className="col-6">
              <label>Email</label>
              <input
                className="form-control"
                placeholder="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col">
              <label>Type</label>
              <select
                className="form-control"
                value={emailType}
                onChange={(e) => setEmailType(e.target.value)}
              >
                <option value="">--Type--</option>
                <option value="home">home</option>
                <option value="work">work</option>

                <option value="other">Other</option>
              </select>
            </div>
            <div className="col-1">
              <label></label>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className="mt-2">Add</Tooltip>}
              >
                <button
                  className="p-0 mt-2 btn btn-white"
                  onClick={() => {
                    if (email != "" && email.includes("@")) {
                      props.setters.setYlopoEmails([
                        ...props.lead.ylopoEmails,
                        { value: email, type: emailType },
                      ]);
                      setEmail("");
                    }
                  }}
                >
                  {" "}
                  <CgAdd />
                </button>
              </OverlayTrigger>
            </div>
          </div>
          {emailItems}
          <div className="row justify-content-center mt-1 mb-1">
            <div className="col-6">
              <label>Phone Number</label>
              <CurrencyFormat
                className="form-control p-0"
                format=" (###) ###-####"
                mask="_"
                placeholder=" (###) ###-####"
                value={phone}
                onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  // formattedValue = $2,223
                  // value ie, 2223
                  setPhone(value);
                }}
              />
            </div>
            <div className="col">
              <label>Type</label>
              <select
                className="form-control"
                value={phoneType}
                onChange={(e) => setPhoneType(e.target.value)}
              >
                <option value="">--Type--</option>
                <option value="home">home</option>
                <option value="work">work</option>
                <option value="mobile">mobile</option>
                <option value="fax">fax</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="col-1">
              <label></label>
              <button
                className="p-0 btn btn-white mt-2"
                onClick={() => {
                  if (phone != "") {
                    props.setters.setYlopoPhoneNumbers([
                      ...props.lead.ylopoPhoneNumbers,
                      { value: phone, type: phoneType },
                    ]);
                    setPhone("");
                  }
                }}
              >
                {" "}
                <CgAdd />
              </button>
            </div>
          </div>
          {phoneItems}
        </div>
      </div>
      <div className="mt-1">
        <YlopoTmInsight lead={props.lead} setters={props.setters} />
      </div>
    </div>
  );
};
