import React, { Component } from "react";

export default class FixedAdjustableInterviewQuestion extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (
      this.props.lead.primaryGoal == "Purchase" ||
      this.props.lead.primaryGoal == "Renovation" ||
      this.props.lead.primaryGoal == "Other"
    ) {
      return (
        <div className="input-group-sm">
          <select
            disabled
            className="form-control col"
            value={this.props.lead.isFixed}
            onChange={(e)=>{
              this.props.setNeedsToBeSaved(true)
              this.props.onChangeIsFixed(e.target.value)}}
          >
            <option value=""></option>
            <option value="Yes">Fixed </option>
            <option value="No">Adjustable</option>
          </select>
        </div>
      );
    } else {
      return (
        <div className="input-group-sm">
          <select
          className="form-control col"
          value={this.props.lead.isFixed}
          onChange={(e)=>{
            this.props.setNeedsToBeSaved(true)
            this.props.onChangeIsFixed(e.target.value)}}
        >
          <option value=""></option>
          <option value="Yes">Fixed </option>
          <option value="No">Adjustable</option>
        </select>
        </div>
      );
    }
  }
}
