import React, { Component } from "react";
import DatePicker from "react-datepicker";
export default class DateHandle extends Component {
  constructor(props) {
    super(props);
    this.formatDate = this.formatDate.bind(this);
    this.state = {
      date: "",
    };
  }

  formatDate(date) {
    if (date == undefined || date == "" || date == null) {
      return "2001-01-1";
    }
    let newDate =
      date[0] + date[1] + date[2] + date[3] + "-" + date[4] + date[5] + "-1";
    return newDate;
  }

  render() {
    if (
      this.props.lead.mortgageDate != "" &&
      this.props.lead.mortgageDate != null &&
      this.props.lead.mortgageDate != undefined &&
      this.props.lead.mortgageDate != "0"
    ) {
      return (
        <DatePicker
          className="form-control input-group col w-100"
          dateFormat="MM/yyyy"
          maxDate={Date.now()}
          showMonthYearPicker
          selected={new Date(this.formatDate(this.props.lead.mortgageDate))}
          onChange={this.props.handleChangeDate}
        />
      );
    } else {
      if (
        this.props.lead.primaryGoal === "Cash Out" ||
        this.props.lead.primaryGoal === "Lower Payment" ||
        this.props.lead.primaryGoal === "Other"
      ) {
        return (
          <DatePicker
            showMonthYearPicker
            maxDate={Date.now()}
            className="form-control input-group col w-100 bg-danger"
            dateFormat="MM/yyyy"
            onChange={this.props.handleChangeDate}
          />
        );
      } else
        return (
          <DatePicker
            showMonthYearPicker
            maxDate={Date.now()}
            className="form-control input-group col w-100 "
            dateFormat="MM/yyyy"
            onChange={this.props.handleChangeDate}
          />
        );
    }
  }
}
