import React, { Component } from "react";

import "react-datepicker/dist/react-datepicker.css";
import calcEquity from "./tmInsight/calcEquity";
import calcPaymentsMade from "./tmInsight/calcPaymentsMade";
import calcLTV from "../generalUseFunctions/calcLTV";
import DateHandle from "../generalUseComponents/dateHandle";
import TmInsightsEquity from "./tmInsight/tm-insights-equity";
import getLTVConfidence from "./tmInsight/getLTVConfidence";

export default class TmInsightOld extends Component {
  render() {
    return (
      <div className="col">
        <div className=" mt-2 ml-2">
          <table className="table table-striped table-sm table-bordered shadow">
            <thead>
              <tr className="table-active">
                <td colSpan="2" className="text-center font-weight-bold">
                  TM Insight
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Loan Date</td>
                <td>
                  <DateHandle
                    lead={this.props.lead}
                    handleChangeDate={this.props.handleChangeDate}
                  />
                </td>
              </tr>
              <tr>
                <td>Equity</td>
                <td>
                  <TmInsightsEquity
                    equity={calcEquity(
                      this.props.lead.estimatedValue,
                      this.props.lead.loanBalance
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>LTV</td>
                <td>
                  {calcLTV(
                    this.props.lead.loanBalance,
                    this.props.lead.estimatedValue
                  )}
                </td>
              </tr>
              <tr>
                <td>Experian LTV</td>
                <td>{this.props.lead.exLtvRange ? this.props.lead.exLtvRange.slice(2) : ''}</td>
              </tr>
              <tr>
                <td>LTV Confidence</td>
                <td>{getLTVConfidence(this.props.lead.exLtvRange)}</td>
              </tr>
              <tr>
                <td>Payments Made</td>
                <td>{calcPaymentsMade(this.props.lead.mortgageDate)}</td>
              </tr>
              <tr>
                <td>Community Lending</td>
                <td className={this.props.lead.chaseEligible === 'Yes' ? 'background-color-green text-white' : ''}>{this.props.lead.chaseEligible}</td>
              </tr>
              <tr>
                <td>Inquiries in last 3</td>
                <td>{this.props.lead.numberOfMonthsSinceLastINQ}</td>
              </tr>
              <tr>
                <td>Debt to Credit Ratio</td>
                <td>{this.props.lead.DebtToCredit}</td>
              </tr>
              <tr>
                <td>Debt Balance</td>
                <td>
                  {Intl.NumberFormat(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                    },
                    { maximumSignificantDigits: 3 }
                  )
                    .format(this.props.lead.DebtAmount)
                    .replace(/\.00/g, "")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
