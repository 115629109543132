export default function calcMiElimFloor(
  convBaseRate,
  creditScore,
  loanBalance,
  jumboThreshold,
  vendorLeadCode
) {
  let rate = convBaseRate + 0.125;

  if (creditScore < 720) {
    rate += 0.5;
  }
  if (loanBalance > jumboThreshold - 8000) {
    rate += 0.5;
  }
  if (vendorLeadCode.substring(0, 2) === "CO") {
    rate += 0.75;
  }

  return rate;
}
