export default function calcPaymentsMade(mortgageDate) {
  if (mortgageDate != "" && mortgageDate != undefined && mortgageDate!=null) {
    let currentYear = new Date().getFullYear();
    let currentMonth = new Date().getMonth() +1;
    return (
      12 * (currentYear - parseInt(mortgageDate.substring(0, 4)) - 1) +
      currentMonth +
      (11 - parseInt(mortgageDate.substring(4, 6)))
    );
  } else return "Loan date needed";
}
