import React, { Component } from 'react';
import axios from 'axios';
export default class AdminLeadDashBoardRow extends Component {
    constructor(props) {
        super(props);
        this.onClickViewButton = this.onClickViewButton.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.state = {
            loName: '',
        };
    }

    formatDate(string) {
        if (string === '' || string === null || string === undefined) {
            return '';
        }
        const time = new Date(string);
        return time.toDateString() + ' at ' + time.toLocaleTimeString([], { timeStyle: 'short' });
    }
    onClickViewButton(key) {
        axios
            .get('backend/activeleads/tm/' + key)
            .then((res) => {
                console.log(res.data);
                this.props.setLead(res.data);
            })
            .catch((err) => {
                if (err.response != undefined) {
                    if (err.response.status == 403) {
                        window.location.reload();
                    } else {
                        alert('Error in opening Lead');
                    }
                } else {
                    alert('Error in opening Lead');
                }
            });
    }

    render() {
        if (this.props.data) {
            let rowItems = '';
            if (this.props.user.matrixLeadPermissions.includes("Other Leads")) {
                rowItems = this.props.data.map((lead) => (
                    <tr key={lead.dbIdLookup}>
                        <td>{lead.vendorLeadCode}</td>
                        <td>{this.formatDate(lead.leadCreated)}</td>
                        <td>{this.formatDate(lead.transferredDate)}</td>
                        <td>{lead.b1FirstName + ' ' + lead.b1LastName}</td>
                        <td>{lead.streetAddress}</td>
                        <td>{lead.leadStatus}</td>
                        <td>{lead.deadReason}</td>
                        <td>{lead.encompassMilestone}</td>
                        <td>{lead.tm}</td>
                        <td>{lead.loanOfficerName}</td>
                        <td>{lead.callBackLaterDate ? this.formatDate(lead.callBackLaterDate) : ''}</td>
                        <td>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    this.onClickViewButton(lead.dbIdLookup);
                                }}
                            >
                                View
                            </button>
                        </td>
                    </tr>
                ));
            } else if (!this.props.user.matrixLeadPermissions.includes("Other Leads")) {
                rowItems = this.props.data.map((lead) => (
                    <tr key={lead.dbIdLookup}>
                        <td>{lead.vendorLeadCode}</td>
                        <td>{this.formatDate(lead.leadCreated)}</td>
                        <td>{this.formatDate(lead.transferredDate)}</td>
                        <td>{lead.b1FirstName + ' ' + lead.b1LastName}</td>
                        <td>
                            {' '}
                            {lead.phoneNumber == undefined || lead.phoneNumber == ''
                                ? ' '
                                : '(' +
                                  lead.phoneNumber.substring(0, 3) +
                                  ') ' +
                                  lead.phoneNumber.substring(3, 6) +
                                  '-' +
                                  lead.phoneNumber.substring(6, 10)}
                        </td>
                        <td>{lead.leadStatus}</td>
                        <td>{lead.encompassMilestone}</td>
                        <td>{lead.tm}</td>
                        <td>{lead.loanOfficerName}</td>
                        <td>{lead.callBackLaterDate ? this.formatDate(lead.callBackLaterDate) : ''}</td>
                        <td>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    this.onClickViewButton(lead.dbIdLookup);
                                }}
                            >
                                View
                            </button>
                        </td>
                    </tr>
                ));
            } else {
                rowItems = this.props.data.map((lead) => (
                    <tr key={lead.dbIdLookup}>
                        <td>{lead.vendorLeadCode}</td>
                        <td>{this.formatDate(lead.leadCreated)}</td>
                        <td>{this.formatDate(lead.transferredDate)}</td>
                        <td>{lead.b1FirstName + ' ' + lead.b1LastName}</td>
                        <td>{lead.streetAddress}</td>
                        <td>{lead.encompassMilestone}</td>
                        <td>{lead.tm}</td>
                        <td>{lead.callBackLaterDate ? this.formatDate(lead.callBackLaterDate) : ''}</td>
                        <td>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    this.onClickViewButton(lead.dbIdLookup);
                                }}
                            >
                                View
                            </button>
                        </td>
                    </tr>
                ));
            }

            return <tbody>{rowItems}</tbody>;
        } else return <div></div>;
    }
}
