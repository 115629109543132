import React, { Component } from "react";
import InterestRateDropdown from "./interest-rate-dropdown";
export default class InterestRateInterviewQuestion extends Component {
  render() {
      return (
        <InterestRateDropdown
          disabled={(
            this.props?.lead?.primaryGoal === "Purchase" ||
            this.props?.lead?.primaryGoal === "Renovation" ||
            this.props?.lead?.primaryGoal === "Other"
          )}
          interest={this.props.lead.interestRate}
          onChangeInterestRate={this.props.onChangeInterestRate}
          setNeedsToBeSaved={this.props.setNeedsToBeSaved}
        />
      );
  }
}
