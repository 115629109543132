import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ConfirmTelemarketerChange } from './confirmTelemarketerChange';

export const ReassignTelemarketer = (props) => {
    const [tms, setTms] = useState([]);
    const [selectedTmObject, setSelectedTmObject] = useState(undefined);
    const [showConfirmation, setShowConfirmation] = useState(false);
    useEffect(() => {
        //get all TMS
        axios.get('/backend/user/TM').then((resp) => {
            setTms(resp.data.users);
        });
    }, []);

    const findSelectedTM = async (userId) => {
        let foundTM = await tms.find((user) => user._id == userId);
        if (foundTM) setSelectedTmObject(foundTM);
        else setSelectedTmObject(undefined);
    };

    const tmItems = tms.map((tm) => {
        return (
            <option value={tm._id}> {tm.firstName + ' ' + tm.lastName} </option>
        );
    });

    if (props.show == true) {
        return (
            <div>
                <ConfirmTelemarketerChange
                    show={showConfirmation}
                    setShow={setShowConfirmation}
                    selectedTM={selectedTmObject}
                    leadId={props.leadId}
                    setTm={props.setTm}
                    setTmId={props.setTmId}
                />
                <select
                    className="form-control"
                    value={props.activeLead.tmId}
                    onChange={async (e) => {
                        setShowConfirmation(true);
                        await findSelectedTM(e.target.value);
                    }}
                >
                    <option value=""></option>
                    {tmItems}
                </select>
            </div>
        );
    } else return <></>;
};
