import React, { useState, useEffect } from "react";
import employmentTest from "./employementTest";
import calcArmConversionEligibility from "./calcArmConversionEligibility";

export const ArmConversion = ({lead}) => {
    const [armConversionStatus, setArmConversionStatus] = useState({ classStr: '', message: '' });

    useEffect(() => {
        const status = calcArmConversionEligibility(lead)
        if (status.classStr && status.message) {
            setArmConversionStatus(status)
        }
    }, [lead])

    const requiredFields = lead.isFixed !== "" && lead.mortgageDate !=="" && employmentTest(lead.employment, lead.hasCoBorrower) !== "";

    return (
        <tr>
            <td>ARM Conversion</td>
            <td>
                {
                    requiredFields ? (
                        <div id="armConversionResponse" className={armConversionStatus.classStr}>
                            {armConversionStatus.message}
                        </div>
                    ) : <div id="armConversionResponse"></div>
                }
            </td>
        </tr>
    );
}
