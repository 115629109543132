import React, { Component } from "react";

export default class ProgressBar extends Component {
  render() {
    if (this.props.showProgress == false) {
        console.log(this.props.progressPercentage)
      return <div></div>;
    } else {
        console.log(this.props.progressPercentage)
      return (
        <div>
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              style={{width: `${this.props.progressPercentage}%`}}
            >{this.props.progressPercentage}%</div>
          </div>
        </div>
      );
    }
  }
}
