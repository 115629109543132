import React, { useState, useEffect } from "react";
import employmentTest from "./employementTest";
import calcOtherEligibility from "./calcOtherEligibility";

export const Other = ({lead}) => {
    const [otherStatus, setOtherStatus] = useState({ classStr: '', message: '' });

    useEffect(() => {
        const status = calcOtherEligibility(lead)
        if (status.classStr && status.message) {
            setOtherStatus(status)
        }
    }, [lead])

    const requiredFields = lead.primaryGoal !== "" && employmentTest(lead.employment, lead.hasCoBorrower) !== "";

    return (
        <tr>
            <td>Other</td>
            <td>
                {
                    requiredFields ? (
                        <div id="otherResponse" className={otherStatus.classStr}>
                            {otherStatus.message}
                        </div>
                    ) : <div id="otherResponse"></div>
                }
            </td>
        </tr>
    );
}
