import React, { Component } from "react";
import DatePicker from "react-datepicker";

export default class CreditInquiryInterviewQuestion extends Component {
  constructor(props) {
    super(props);
    this.formatDate = this.formatDate.bind(this);
    this.state = {
      date: "",
    };
  }

 formatDate (date) {
    console.log("🚀 ~ file: creditInquiryInterviewQuestion.js ~ line 14 ~ CreditInquiryInterviewQuestion ~ formatDate ~ date", date)
    if (date == undefined || date == "" || date == null) {
      return "2001-01-1";
    }
    let newDate =
      date[0] + date[1] + date[2] + date[3] + "-" + date[4] + date[5] + "-1";
    return newDate;
  }
  render() {
    if (
      this.props.lead.primaryGoal == "Purchase" ||
      this.props.lead.primaryGoal == "Other"
    ) {
      if (this.props.lead.TriggerDate == null ) {
        return <input className="form-control" disabled />;
      } else {
        return (
          <div className="input-group-sm">
            <input
              className="form-control"
              disabled
              value={
                new Date(this.props.lead.TriggerDate).toDateString() 
              }
            />
          </div>
        );
      }
    } else {
      if (this.props.lead.TriggerDate == null&&this.props.lead.mailDate == undefined) {
        return <input className="form-control" />;
      } else {
        return (
          <div className="input-group-sm">
            <input
              className="form-control"
              value={ 
                 new Date(this.props.lead.TriggerDate).toDateString() 
              }
            />
          </div>
        );
      }
    }
  }
}
