import React, { Component } from "react";
var CurrencyFormat = require("react-currency-format");

export default class PaymentCalculator extends Component {
  constructor(props) {
    super(props);

    this.onChangeLoanAmount = this.onChangeLoanAmount.bind(this);
    this.onChangeTerm = this.onChangeTerm.bind(this);
    this.onChangeInterest = this.onChangeInterest.bind(this);
    this.calculatePI = this.calculatePI.bind(this);
    this.onChangeEscrow = this.onChangeEscrow.bind(this);
    this.onClose = this.onClose.bind(this);

    this.state = {
      loanAmount: "",
      term: "",
      interest: "",
      escrow: 0,
    };
  }

  componentDidMount() {
    if (this.props.lead.loanBalance == null || this.props.lead.undefined) {
      this.setState({
        loanAmount: 0,
        term: 360,
        escrow: 0,
      });
    } else {
      this.setState({
        loanAmount: this.props.lead.loanBalance,
        term: 360,
        escrow: 0,
      });
    }
  }

  onChangeLoanAmount(e) {
    this.setState({ loanAmount: e });
  }
  onChangeTerm(e) {
    this.setState({ term: e.target.value });
  }
  onChangeInterest(e) {
    this.setState({ interest: e.target.value });
  }
  onChangeEscrow(e) {
    this.setState({ escrow: e });
  }

  calculatePI(P, T, I) {
    if (
      P == "" ||
      T == "" ||
      I == "" ||
      P == undefined ||
      T == undefined ||
      I == undefined
    ) {
      return "";
    } else {
      return (
        Math.round(
          (((I / 100) * P) / (12 * (1 - Math.pow(1 + I / 1200, -T)))) * 100
        ) / 100
      ).toFixed(2);
    }
  }
  onClose() {
    this.props.close();
    if (this.props.lead.loanBalance == null || this.props.lead.undefined) {
      this.setState({
        loanAmount: 0,
        term: 360,
        escrow: 0,
      });
    } else {
      this.setState({
        loanAmount: this.props.lead.loanBalance,
        term: 360,
        escrow: 0,
      });
    }
  }

  render() {
    if (this.props.showPaymentCalculator) {
      return (
        <div className="list-group mt-2 shadow">
          <li className="list-group-item list-group-item-dark">
            <div className="font-weight-bold text-center">
              {" "}
              Payment Calculator
              <button
                className="close"
                aria-label="Close"
                onClick={this.onClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </li>
          <li className="list-group-item">
            <div className="row">
              <div className="col">Loan Amount</div>
              <div className="col">
                <CurrencyFormat
                  isNumericString={true}
                  className="form-control"
                  value={this.state.loanAmount}
                  thousandSeparator={true}
                  prefix={"$"}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    // formattedValue = $2,223
                    // value ie, 2223
                    this.onChangeLoanAmount(value);
                  }}
                />
              </div>
            </div>
          </li>
          <li className="list-group-item">
            <div className="row">
              <div className="col">Term Length (Months)</div>
              <div className="col">
                <input
                  className="form-control"
                  value={this.state.term}
                  onChange={this.onChangeTerm}
                />
              </div>
            </div>
          </li>
          <li className="list-group-item">
            <div className="row">
              <div className="col">Interest Rate</div>
              <div className="col">
                <input
                  className="form-control"
                  value={this.state.interest}
                  onChange={this.onChangeInterest}
                />
              </div>
            </div>
          </li>

          <li className="list-group-item list-group-item-success">
            <div className="row">
              <div className="col">PI</div>
              <div className="col">
                <input
                  className="form-control"
                  value={Intl.NumberFormat(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                    },
                    { maximumSignificantDigits: 3 }
                  )
                    .format(
                      this.calculatePI(
                        this.state.loanAmount,
                        this.state.term,
                        this.state.interest
                      )
                    )
                    .replace(/\.00/g, "")}
                />
              </div>
            </div>
          </li>
          <li className="list-group-item">
            <div className="row">
              <div className="col">Escrow</div>
              <div className="col">
                <CurrencyFormat
                  isNumericString={true}
                  className="form-control"
                  value={this.state.escrow}
                  thousandSeparator={true}
                  prefix={"$"}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    // formattedValue = $2,223
                    // value ie, 2223
                    this.onChangeEscrow(value);
                  }}
                />
              </div>
            </div>
          </li>
          <li className="list-group-item list-group-item-success">
            <div className="row">
              <div className="col">PITI</div>
              <div className="col">
                <input
                  className="form-control"
                  value={Intl.NumberFormat(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                    },
                    { maximumSignificantDigits: 3 }
                  )
                    .format(
                      +this.calculatePI(
                        this.state.loanAmount,
                        this.state.term,
                        this.state.interest
                      ) + +this.state.escrow
                    )
                    .replace(/\.00/g, "")}
                />
              </div>
            </div>
          </li>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
