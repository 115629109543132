import React, { Component } from "react";

export default class MortgageInsuranceInterviewQuestion extends Component {
  render() {
    if (
      this.props.lead.primaryGoal == "Purchase" ||
      this.props.lead.primaryGoal == "Renovation" ||
      this.props.lead.primaryGoal == "Other"
    ) {
      return (
        <div className="input-group-sm">
          <select
            disabled
            className="form-control  col "
            value={this.props.lead.hasMortgageInsurance}
            onChange={(e)=>{
              this.props.setNeedsToBeSaved(true)
              this.props.onChangeHasMortgageInsurance(e.target.value)}}
          >
            <option value=""></option>
            <option value="Yes">Yes </option>
            <option value="No">No</option>
          </select>
        </div>
      );
    } else {
      return (
        <div className="input-group-sm">
          <select
            className="form-control input-group col"
            value={this.props.lead.hasMortgageInsurance}
            onChange={(e)=>{
              this.props.setNeedsToBeSaved(true)
              this.props.onChangeHasMortgageInsurance(e.target.value)}}
          >
            <option value=""></option>
            <option value="Yes">Yes </option>
            <option value="No">No</option>
          </select>
        </div>
      );
    }
  }
}
