import React, { Component } from "react";

export default class ComparisonFieldValue extends Component {
  render() {
    if (this.props.edit == true) {
      return (
        <div className="input-group-sm">
          <input
            className="form-control"
            value={this.props.value}
            onChange={this.props.onChangeValue}
          />
        </div>
      );
    } else {
      return <div>{this.props.value}</div>;
    }
  }
}
