import React, { Component } from "react";

export default class FileAlert extends Component {
 
  render() {
      if(this.props.data){
        return (
            <div>
              <div className="alert alert-info mt-2" role="alert">
                {this.props.data}
              </div>
            </div>
          );

      }
      else return <div></div>
    


   
  }
}
