import React from "react";
var CurrencyFormat = require("react-currency-format");

export const YlopoDataFields = (props) => {
  console.log(props.lead);
  if (props.lead != undefined) {
    if (props.lead.ylopoId != undefined || props.lead.campaignName=='TRIG') {
      console.log(props.lead.ylopoDataFields);

      let tagItems = props.lead.ylopoTags.map((tag)=>{
        return <li>{tag}</li>
      })

      return (
        <div>
          <div className="row border contour-blue text-white">
            <div className="col-4 border-right">Field</div>
            <div className="col">Value</div>
          </div>
          <div className="row  border-bottom border-right border-left">
            <div className="col-4 border-right">
              <strong>
                <label>First Name</label>
              </strong>
            </div>
            <div className="col">{props.lead.b1FirstName}</div>
          </div>
          <div className="row  border-bottom border-right border-left">
            <div className="col-4 border-right">
              <strong>
                <label>Last Name</label>
              </strong>
            </div>
            <div className="col">{props.lead.b1LastName}</div>
          </div>
          <div className="row  border-bottom border-right border-left">
            <div className="col-4 border-right">
              <strong>
                <label>Email</label>
              </strong>
            </div>
            <div className="col">{props.lead.ylopoEmails[0]?props.lead.ylopoEmails[0].value:""}</div>
          </div>

          <div className="row  border-bottom border-right border-left">
            <div className="col-4 border-right">
              <strong>
                <label>Phone</label>
              </strong>
            </div>
            <div className="col"> <CurrencyFormat
                  className="border-0"
                  format=" (###) ###-####"
                  mask="_"
                  placeholder=" (###) ###-####"
                  value={props.lead.ylopoPhoneNumbers[0] ?props.lead.ylopoPhoneNumbers[0].value : props.lead.phoneNumber}
                /></div>
          </div>

          <div className="row  border-bottom border-right border-left">
            <div className="col-4 border-right">
              <strong>
                <label>Tags</label>
              </strong>
            </div>
            <div className="col"><ul>{tagItems}</ul></div>
          </div>

          {/* <div className="row border-bottom border-right border-left">
              <div className="col-4 border-right">
                <strong>
                  <label></label>
                </strong>
              </div>
              <div className="col">
                <CurrencyFormat
                  className="border-0"
                  format=" (###) ###-####"
                  mask="_"
                  placeholder=" (###) ###-####"
                  value={field.value}
                />
              </div>
            </div> */}
        </div>
      );
    } else {
      let fieldItems = props.lead.ylopoDataFields.map((field) => {
        if (field.label == "Ylopo ID" || field.label == "Stars") {
          return <div></div>;
        } else if (field.label == "Phone") {
          return (
            <div className="row border-bottom border-right border-left">
              <div className="col-4 border-right">
                <strong>
                  <label>{field.label}</label>
                </strong>
              </div>
              <div className="col">
                <CurrencyFormat
                  className="border-0"
                  format=" (###) ###-####"
                  mask="_"
                  placeholder=" (###) ###-####"
                  value={field.value}
                />
              </div>
            </div>
          );
        } else if (field.label == "Created") {
          return (
            <div className="row  border-bottom border-right border-left">
              <div className="col-4 border-right">
                <strong>
                  <label>{field.label}</label>
                </strong>
              </div>
              <div className="col">
                {new Date(field.value).toDateString() +
                  " at " +
                  new Date(field.value).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
              </div>
            </div>
          );
        } else {
          return (
            <div className="row  border-bottom border-right border-left">
              <div className="col-4 border-right">
                <strong>
                  <label>{field.label}</label>
                </strong>
              </div>
              <div className="col">{field.value}</div>
            </div>
          );
        }
      });

      return (
        <div>
          <div className="row border contour-blue text-white">
            <div className="col-4 border-right">Field</div>
            <div className="col">Value</div>
          </div>
          {fieldItems}
        </div>
      );
    }
  } else {
    return <div></div>;
  }
};
