import employmentTest from "./employementTest";
import calcLTV from "./calcLTV";
import calcMaxConvCoLtv from "./calcMaxConvCoLtv";
import calcEquity from "..//../generalUseFunctions/calcEquity"
import calcMinEquity from "./calcMinEquity";

export default function calcConvCashoutEligibility (lead, jumboThreshold) {
    if (!lead) {
        return {
            classStr: '',
            message: '',
        }
    }

    const danger = 'font-weight-bold bg-danger text-white';
    const success = 'bg-success font-weight-bold text-white';

    const ltv = calcLTV(lead.loanBalance, lead.estimatedValue);
    const maxLtv = calcMaxConvCoLtv(lead.occupancy);
    const equity = calcEquity(lead.estimatedValue, lead.loanBalance);
    const minEquity = calcMinEquity(ltv);

    if (employmentTest(lead.employment, lead.hasCoBorrower) === 'Fail') {
        return {
            classStr: danger,
            message: 'Employment Issue',
        }
    }

    if (lead.isVeteran === 'Yes' && lead.state !== 'TX' && lead.occupancy === 'Primary') {
        return {
            classStr: danger,
            message: 'Use VA options',
        }
    }

    if (lead.creditScore < 620) {
        return {
            classStr: danger,
            message: 'FICO too low: 620',
        }
    }

    if (lead.creditScore < 680 && lead.loanBalance > (jumboThreshold - 5000)) {
        return {
            classStr: danger,
            message: 'FICO too low: 680',
        }
    }

    if (ltv > maxLtv) {
        return {
            classStr: danger,
            message: `LTV too high: ${maxLtv}`,
        }
    }

    if (equity < minEquity) {
        return {
            classStr: danger,
            message: `Not enough equity: ${minEquity}`,
        }
    }

    if ((lead.loanBalance + equity) < 100000) {
        return {
            classStr: danger,
            message: "Doesn't meet minimum loan amount criteria.",
        }
    }

    return {
        classStr: success,
        message: 'Eligible',
    }
}