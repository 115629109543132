import React, { Component } from "react";
import ConversionQueueLogo from "../../img/ConversionQueueLogoPNG.png";
import axios from "axios";
import NavbarLink from "./navbarComp/navbarLink";
const crmURL = process.env.REACT_APP_CRM_URL;

export default class NavBar extends Component {
  constructor(props) {
    super(props);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.onClickCRM = this.onClickCRM.bind(this);
    this.onClickReports = this.onClickReports.bind(this);
    this.state = {};
  }

  handleSignOut() {
    axios.post("backend/user/signOut");
    this.props.handleSignOutState();
  }

  onClickCRM() {
      axios.post("backend/user/getToken").then((resp) => {
        // do something
        if (resp.data){
          window.open(crmURL + resp.data, "_blank");
        } 
      });
  }

  onClickReports() {
    // do something
  }

  render() {
    var navbarItemNames = [];
  if (this.props.data.user != undefined){
      navbarItemNames = this.props.data.user.matrixNavPermissions;
    }
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light transparent bg-light shadow-sm">
          <a className="navbar-brand  bg-light" href="#">
            <img className="img-fluid" width="200"  alt="Matrix Logo" src={ConversionQueueLogo} />
          </a>
          <div className="col">
            <div className="row">
              <div className="ml-auto dark-blue">
              {" "}
              Welcome {this.props.data.user.firstName}{" "}
              {this.props.data.user.lastName}
              </div>
              
            </div>

            <div
              className="collapse navbar-collapse row"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto ">
              {navbarItemNames.includes("TM Leads Dashboard")
                ? (
                <NavbarLink
                  navBarActive={this.props.navbarState.leadDashboard}
                  text="TM Lead Dashboard"
                  onClickNavBarItem={this.props.onClickLeadDashboard}
                />
                ) : (
                  <div></div>
                )}
                {navbarItemNames.includes("New TM Lead")
                ? (
                <NavbarLink
                  navBarActive={this.props.navbarState.newTmLead}
                  text="New TM Lead"
                  onClickNavBarItem={this.props.onClickNewTmLead}
                />
                ) : (
                  <div></div>
                )}
                {navbarItemNames.includes("Lead Queue")
                ? (
                <NavbarLink
                    navBarActive={this.props.navbarState.claimQueue}
                    text="Lead Queue"
                    onClickNavBarItem={this.props.onClickClaimQueue}
                    queueCount={this.props.queueCount}
                />
                ) : (
                  <div></div>
                )}
                {navbarItemNames.includes("Import Data")
                ? (
                <NavbarLink
                  navBarActive={this.props.navbarState.importMailer}
                  text="Import Data"
                  onClickNavBarItem={this.props.onClickImportMailer}
                />
                ) : (
                  <div></div>
                )}
                {navbarItemNames.includes("Comparison Fields")
                ? (
                <NavbarLink
                  navBarActive={this.props.navbarState.comparisonField}
                  text="Comparison Fields"
                  onClickNavBarItem={this.props.onClickComparisonField}
                />
                ) : (
                  <div></div>
                )}
                {navbarItemNames.includes("Edit Profile")
                ? (
                <NavbarLink
                  navBarActive={this.props.navbarState.editProfile}
                  text="Edit Profile"
                  onClickNavBarItem={this.props.onClickEditProfile}
                />
                ) : (
                  <div></div>
                )}
                {navbarItemNames.includes("Edit Users")
                ? (
                <NavbarLink
                  navBarActive={this.props.navbarState.createUser}
                  text="Edit Users"
                  onClickNavBarItem={this.props.onClickCreateUser}
                />
                ) : (
                  <div></div>
                )}
                {navbarItemNames.includes("Reports")
                ? (
                <NavbarLink
                  navBarActive={this.props.navbarState.reports}
                  text="Reports"
                  onClickNavBarItem={this.props.onClickReport}
                />
                ) : (
                  <div></div>
                )}

                {this.props.data.user && this.props.data.user.sitePermissions.includes('CRM')  ? (
                    <div>
                    <button
                      className="btn btn-primary p-1"
                      onClick={this.onClickCRM}
                    >
                    CRM
                    </button>
                    </div>
                    ) : (
                        <div></div>
                    )} 
                {this.props.data.user && this.props.data.user.sitePermissions.indexOf('Reports') >= 0 ? (
                    <div>
                    {/* <button
                      className="btn btn-primary z-index-top-1 p-1"
                      onClick={this.onClickReports}
                    >
                    Reports
                    </button> */}
                    </div>
                    ) : (
                        <div></div>
                    )}

                <li className="nav-item">
                  <a
                    className="nav-link  bg-light"
                    onClick={this.handleSignOut}
                    href="#"
                  >
                    Sign Out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
