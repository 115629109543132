import React, { Component } from "react";

export default class OccupancyInterviewQuestion extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (this.props.lead.primaryGoal == "Other" || this.props.lead.primaryGoal=="Purchase"|| this.props.lead.primaryGoal=="Renovation") {
      return (
        <div className="input-group-sm">
          <select
            disabled
            className="form-control input-group col"
            value={this.props.lead.occupancy}
            onChange={(e)=>{
              this.props.setNeedsToBeSaved(true)
              this.props.onChangeOccupancy(e.target.value)}}
          >
            <option value=""></option>
            <option value="Primary">Primary </option>
            <option value="Secondary">Secondary</option>
            <option value="Investment">Investment</option>
          </select>
        </div>
      );
    } else {
      return (
        <div className="input-group-sm">
          <select
            className="form-control input-group col"
            value={this.props.lead.occupancy}
            onChange={(e)=>{
              this.props.setNeedsToBeSaved(true)
              this.props.onChangeOccupancy(e.target.value)}}
          >
            <option value=""></option>
            <option value="Primary">Primary </option>
            <option value="Secondary">Secondary</option>
            <option value="Investment">Investment</option>
          </select>
        </div>
      );
    }
  }
}
