import React, { useState, useEffect } from "react";
import UpdateLoanOfficer from "./updateLoanOfficer";
import DangerAlert from "../generalUseComponents/dangerAlert";
import Loader from "react-loader-spinner";
import axios from "axios";

export const CummingsAndCoBottomSection = (props) => {
  const [transferStage, setTransferStage] = useState("");
  const [error, setError] = useState("");
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(-1);
  const selectLOOptions = props.LO.map((lo) => (
    <option key={lo._id} fullName={lo.fullName} email={lo.email} value={lo._id}>
      {lo.firstName}
      {"  "} {lo.lastName}
    </option>
  ));

  useEffect(() => {
    axios
      .get("/backend/transfer/getCummingsAndCoUsers")
      .then((resp) => {
        setAgents(resp.data);
      })
      .catch((err) => {});
  }, []);

  let agentItems = agents.map((agent) => {
    return <option value={agent.id}>{agent.name}</option>;
  });

  return (
    <div className="mt-2">
      <div className="text-center">
        <DangerAlert data={props.transferError} />
      </div>

      {/* <div className="row justify-content-center mb-2">
          <div className="col-3">
            <button
              className="btn btn-primary ml-2 mr-2 "
              onClick={props.onSave}
            >
              Save
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                props.onSaveAndClose();
              }}
            >
              Save and Close
            </button>
          </div>
        </div> */}

      <div className="row justify-content-center mb-5">
        <div className="col border bg-light">
          <h5 className="text-center mt-1">CRM</h5>
          <div className="row justify-content-center">
            <p className="h5 ml-2">Hunt Group #</p>
            <input
              disabled
              className="ml-1 h5 text-center"
              size="4"
              value={props.huntGroup}
            />
          </div>
          {props.loadingCRM ? (
            <div>
              <Loader
                type="Oval"
                color="#6CB33F"
                height={100}
                width={100}
                //3 secs
              />
            </div>
          ) : (
            <div>
              {" "}
              {props.lead.isTransferred != "true" ? (
                <div className="row justify-content-center mb-2">
                  <div className="col">
                    <select
                      className="form-control input-group w-100 ml-2"
                      onChange={(e) => props.onChangeLoanOfficer(e)}
                      value={props.loanOfficer}
                      disabled={props.transferLock}
                    >
                      <option value="">--Loan Officer--</option>
                      {selectLOOptions}
                    </select>
                  </div>
                  <div className="col ">
                    <button
                      className="btn btn-primary w-100 ml-2"
                      onClick={props.onCRMTransfer}
                    >
                      Transfer
                    </button>
                  </div>
                </div>
              ) : (
                <div className="row justify-content-center mb-2">
                  {" "}
                  <div className="text-center mb-2">
                    <h5 className="text-danger">
                      Loan Officer Transfer Completed
                    </h5>
                  </div>{" "}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="col border ml-2 bg-light">
          <h5 className="text-center mt-1">Cummings & Co.</h5>

          {props.loadingCummings == true ? (
            <div className="row justify-content-center">
              <Loader
                type="Oval"
                color="#6CB33F"
                height={100}
                width={100}
                //3 secs
              />
            </div>
          ) : (
            <div>
              {props.lead.isTransferredCummingsAndCo != "true" ? (
                <div>
                  <div className="row justify-content-center mt-1">
                    <select
                      className="form-control col-4"
                      value={transferStage}
                      onChange={(e) => setTransferStage(e.target.value)}
                    >
                      <option value="">Transfer Type</option>
                      <option value="Contour - Live Transfer">
                        Live Transfer
                      </option>
                      <option value="Contour - Post Lead">Post Lead</option>
                    </select>
                  </div>
                  {transferStage == "Contour - Live Transfer" ? (
                    <div className="row justify-content-center mt-1">
                      <select
                        className="form-control col-4"
                        value={selectedAgent}
                        onChange={(e) => setSelectedAgent(e.target.value)}
                      >
                        <option value={-1}></option>
                        {agentItems}
                      </select>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  <div className="row justify-content-center">
                    <DangerAlert data={error} />
                  </div>
                  <div className="row justify-content-center mt-1">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        if (transferStage == "") {
                          setError("Please select a transfer type");
                        } else if (
                          transferStage == "Contour - Live Transfer" &&
                          selectedAgent == -1
                        ) {
                          setError(
                            "Please Select an agent when completing a live transfer"
                          );
                        } else {
                          if (props.lead.campaignName == "BSEL") {
                            if (transferStage == "Contour - Live Transfer") {
                              props.onCummingsTransfer(
                                "update",
                                transferStage,
                                selectedAgent
                              );
                            } else {
                              props.onCummingsTransfer("update", transferStage);
                            }
                          } else if (props.lead.campaignName == "BSYL") {
                            if (transferStage == "Contour - Live Transfer") {
                              props.onCummingsTransfer(
                                "update",
                                transferStage,
                                selectedAgent
                              );
                            } else {
                              props.onCummingsTransfer("update", transferStage);
                            }
                          }
                        }
                      }}
                    >
                      Transfer to Cummings & Co.
                    </button>
                  </div>
                </div>
              ) : (
                <div className="row justify-content-center mb-2">
                  {" "}
                  <div className="text-center mb-2">
                    <h5 className="text-danger">
                      Cummings & Co Transfer Completed
                    </h5>
                  </div>{" "}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
