import React, { Component } from "react";
import axios from "axios";
import FileAlert from "./file-alert";
import DangerAlert from "../generalUseComponents/dangerAlert";
import ProgressBar from "./progressBar";
import download from "downloadjs";

export default class DmListUpload extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeFileType = this.onChangeFileType.bind(this);
    this.onClickDownload = this.onClickDownload.bind(this);
    this.onChangeDownloadSample = this.onChangeDownloadSample.bind(this);

    this.state = {
      file: "",
      fileName: "Select file",
      uploadedFile: "",
      uploadStatus: "",
      error: "",
      fileName2: "",
      uploadPercentage: 0,
      showProgress: false,
     
    };
  }

  onClickDownload(e) {
    e.preventDefault();
      axios
        .get("backend/import-dailer-list/download-sample/tm")
        .then((resp) => {
          download(resp.data, "Sample-TM.csv", "csv");
        });
    
  }
  onChangeDownloadSample(e) {
    this.setState({ downloadSample: e.target.value });
  }
  onChange(e) {
    this.setState({
      file: e.target.files[0],
      fileName: e.target.files[0].name,
      fileName2: e.target.files[0].name,
      showProgress: false,
      uploadPercentage: 0,
      uploadStatus: "",
      error: "",
    });
  }

  onChangeFileType(e) {
    this.setState({
      fileType: e.target.value,
      uploadPercentage: 0,
      showProgress: false,
      uploadStatus: "",
      error: "",
    });
  }

  async onSubmit(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", this.state.file);

    try {
      this.setState({
        uploadStatus: "Sending TM file please wait....",
        error: "",
        fileName: "Select file",
        file: "",
      });
      await axios
        .post("backend/import-dailer-list/upload/tm", formData, {
          headers: {
            "content-Type": "multipart/form-data",
          },
          onUploadProgress: (ProgressEvent) => {
            let progressCompleted = parseInt(
              Math.round((ProgressEvent.loaded / ProgressEvent.total) * 30)
            );
            this.setState({
              showProgress: true,
              uploadPercentage: progressCompleted,
            });

            setTimeout(() => {
              if (
                this.state.uploadPercentage < 40 &&
                this.state.error == "" &&
                this.state.showProgress != false
              ) {
                this.setState({
                  showProgress: true,
                  uploadPercentage: 40,
                });
              }
            }, 3000);

            setTimeout(() => {
              if (
                this.state.uploadPercentage < 50 &&
                this.state.error == "" &&
                this.state.showProgress != false
              ) {
                this.setState({
                  showProgress: true,
                  uploadPercentage: 50,
                });
              }
            }, 5000);
            setTimeout(() => {
              if (
                this.state.uploadPercentage < 60 &&
                this.state.error == "" &&
                this.state.showProgress != false
              ) {
                this.setState({
                  showProgress: true,
                  uploadPercentage: 70,
                });
              }
            }, 8000);
            setTimeout(() => {
              if (
                this.state.uploadPercentage < 70 &&
                this.state.error == "" &&
                this.state.showProgress != false
              ) {
                this.setState({
                  showProgress: true,
                  uploadPercentage: 70,
                });
              }
            }, 10000);
            setTimeout(() => {
              if (
                this.state.uploadPercentage < 80 &&
                this.state.error == "" &&
                this.state.showProgress != false
              ) {
                this.setState({
                  showProgress: true,
                  uploadPercentage: 80,
                });
              }
            }, 12000);
            setTimeout(() => {
              if (
                this.state.uploadPercentage < 90 &&
                this.state.error == "" &&
                this.state.showProgress != false
              ) {
                this.setState({
                  showProgress: true,
                  uploadPercentage: 90,
                });
              }
            }, 15000);
          },
        })
        .then((res) => {
          this.setState({
            uploadPercentage: 100,
            uploadStatus:
              this.state.fileName2 + " has been uploaded to the database",
          });
        })
        .catch((err) =>
          this.setState({
            error: err.response.data.message,
            uploadStatus: "",
            showProgress: false,
            uploadPercentage: 0,
            uploadStatus: "",
          })
        );
    } catch (error) {
      console.log("error: " + error);
    }
  }
  render() {
    return (
      <div>
        <div className="card mt-4 container shadow">
          <form className=" container form-group mt-2" onSubmit={this.onSubmit}>
            <div className="border-bottom border-grey">
              <label className="h4 "> Import Telemarketing List</label>
            </div>
            <FileAlert data={this.state.uploadStatus} />
            <DangerAlert data={this.state.error} />
            <ProgressBar
              progressPercentage={this.state.uploadPercentage}
              showProgress={this.state.showProgress}
            />
            <br />

            <div className="row mt-3">
              <div className="col">
                <div className="form-group  custom-file shadow">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="exampleInputFile"
                    onChange={this.onChange}
                  />
                  <label className="custom-file-label" data-browse="Browse">
                    {this.state.fileName}
                  </label>
                </div>
              </div>

              <button type="submit" className="ml-2 btn btn-primary shadow">
                {" "}
                Submit
              </button>

              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col-3"></div>
                  <div className="col">
                    <button
                      className="btn btn-primary shadow "
                      onClick={this.onClickDownload}
                    >
                      {" "}
                      Download Sample Leads
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <br />
          </form>
        </div>
      </div>
    );
  }
}
