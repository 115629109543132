import React, { Component } from "react";
import { Purchase } from "./opportunities/purchase";
import { VAIRRRL } from "./opportunities/VAIRRRL";
import { VaCashout } from "./opportunities/vaCashout";
import { VaConversion } from "./opportunities/vaConversion";
import { FhaCashout } from "./opportunities/fhaCashout";
import { FhaStreamLine } from "./opportunities/fhaStreamLine";
import { FhaRateTerm } from "./opportunities/fhaRateTerm";
import { ConvCashout } from "./opportunities/convCashout";
import { ConvRateTerm } from "./opportunities/convRateTerm";
import { Renovation } from "./opportunities/renovation";
import { Other } from "./opportunities/other";
import { ArmConversion } from "./opportunities/armConversion";
import { MiRemoval } from "./opportunities/miRemoval";
import ScheduleCallBack from "./loInsight/scheduleCallBack";
import { RefiNow } from "./opportunities/refiNow";
import { ContourCommunityLending } from "./opportunities/ContourCommunityLending";

export default class Opportunity extends Component {
  constructor(props) {
    super(props);
    this.formatDate = this.formatDate.bind(this);

    this.state = {};
  }

  formatDate(string) {
    const time = new Date(string);
    return (
      time.toDateString() +
      " at " +
      time.toLocaleTimeString([], { timeStyle: "short" })
    );
  }

  render() {
    return (
      <div className="col">
        <div className="mt-2">
          <table className="table table-striped table-sm table-bordered shadow">
            <thead>
              <tr className="table-active">
                <td colSpan="2" className="text-center font-weight-bold">
                  Opportunities
                </td>
              </tr>
            </thead>
            <tbody>
              <Purchase lead={this.props.lead} />
              <VAIRRRL lead={this.props.lead} />
              <VaCashout lead={this.props.lead} />
              <VaConversion lead={this.props.lead} />
              <FhaStreamLine lead={this.props.lead} />
              <FhaCashout lead={this.props.lead} />
              <FhaRateTerm lead={this.props.lead} />
              <ConvCashout lead={this.props.lead} />
              <ConvRateTerm lead={this.props.lead} />
              <Renovation lead={this.props.lead} />
              <Other lead={this.props.lead} />
              <ArmConversion lead={this.props.lead} />
              <MiRemoval lead={this.props.lead} />
              <RefiNow lead={this.props.lead}/>
              <ContourCommunityLending lead={this.props.lead} />
            </tbody>
          </table>

          <br />
          <br />

          <table className="table table-striped table-sm table-bordered shadow">
            <tbody>
              <tr>
                <td>Lead Status</td>
                <td>
                  <select
                    className="form-control input-group col"
                    value={this.props.lead.leadStatus}
                    onChange={(e) =>
                      this.props.onChangeLeadStatus(e.target.value)
                    }
                  >
                    <option value=""></option>
                    <option value="Call Back Later">Call Back Later</option>
                    <option value="Dead">Dead</option>
                  </select>
                </td>
              </tr>
              <ScheduleCallBack
                leadStatus={this.props.lead.leadStatus}
                callBackLaterDate={this.props.lead.callBackLaterDate}
                onChangeCallBackLaterDate={
                  this.props.onChangeCallBackLaterDate
                }
              />
              <tr>
                <td>Lead Created Date</td>
                <td>{this.formatDate(this.props.lead.leadCreated)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
