import React, { useState, useEffect } from "react";
import employmentTest from "./employementTest";
import calcRenovationEligibility from "./calcRenovationEligibility";

export const Renovation = ({lead}) => {
    const [renovationStatus, setRenovationStatus] = useState({ classStr: '', message: '' });

    useEffect(() => {
        const status = calcRenovationEligibility(lead)
        if (status.classStr && status.message) {
            setRenovationStatus(status)
        }
    }, [lead])

    const requiredFields = lead.primaryGoal !== "" && lead.creditScore !== "" && lead.isVeteran !== "" && employmentTest(lead.employment, lead.hasCoBorrower) !== "";

  return (
    <tr>
        <td>Renovation</td>
        <td>
            {
                requiredFields ? (
                    <div id="renovationResponse" className={renovationStatus.classStr}>
                        {renovationStatus.message}
                    </div>
                ) : <div id="renovationResponse"></div>
            }
        </td>
    </tr>
  )
}
