import React, { Component } from "react";
import TmManualEntryForm from "./tm-manual-entry-form";
import axios from "axios";
import CreateLead from "./create-lead";
import DangerAlert from "../generalUseComponents/dangerAlert";
import { AlreadyTransferrePage } from "./alreadyTransferredPage";

export default class TmLeadForm extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);

    this.state = {
      search: "",
      referenceNumber: "",
      lastName: "",
      lead: "",
      handleMessage: "",
      lastNameLeads: [],
      showLastNameLeads: false,
      next: "",
      prev: "",
      last: "",
      error: "",
      currentPage: "",
      alreadyCreatedLead: "",
    };
  }
  componentDidMount() {
    this.setState({
      search: true, createLead: false
    });
  }
  onChange(e) {
    this.setState({ referenceNumber: e.target.value, error: "" });
  }
  onChangeLastName(e) {
    this.setState({ lastName: e.target.value, error: "" });
  }
  onSubmit(e) {
    e.preventDefault();

    if (this.state.referenceNumber == "") {
      this.setState({ error: "Please fill out Reference ID " });
    } else {

      axios
        .get("backend/prospectleads/search-tm/" + this.state.referenceNumber)
        .then((res) => {

          this.setState({ search: true, lead: res.data.lead, handleMessage: res.data.handleMessage });
        })
        .catch((err) => {
          console.log(err)

          this.setState({ error: err.response != undefined ? err.response.data : "Error in getting lead" });
        });
    }
  }





  handleSetManualLead(newLead) {
    this.props.handleShowLead(newLead);
    this.props.onClickLeadDashboard(true);
  }

  handleLeadCreated(LeadId) {
    axios.get("backend/activeleads/tm/" + LeadId).then((res) => {
      this.props.handleShowLead(res.data);
      this.props.onClickLeadDashboard(true);
    });
  }
  closeShowResults() {
    this.setState({ showLastNameLeads: false });
  }
  render() {
    if (this.props.data.isManualEntry) {
      return (
        <div className="card mt-4 container shadow">
          <div className=" container form-group mt-2">
            <h4 className="h4 text-center">Manual Entry</h4>
            <TmManualEntryForm
              handleSetManualLead={this.handleSetManualLead.bind(this)}
              user={this.props.user}
            />
          </div>
        </div>
      );
    } else if (this.state.search && (this.state.handleMessage === "Standard create" || this.state.handleMessage === 'Reassign TM')) {
      return (
        <div className=" mp-3 ">
          <CreateLead
            handleMessage={this.state.handleMessage}
            data={this.state.lead}
            user={this.props.user}
            handleLeadCreated={this.handleLeadCreated.bind(this)}
          />
        </div>
      );
    } else if (this.state.search && this.state.handleMessage !== '') {
      return (
        <div className="card">
          <AlreadyTransferrePage
            handleMessage={this.state.handleMessage}
            lead={this.state.lead} />
        </div>
      )
    }
    else {
      return (
        <div>
          <div className="card mt-4 container shadow">
            <div className=" container form-group mt-2">
              <form
                className=" container form-group mt-2"
                onSubmit={this.onSubmit}
              >
                <h4 className="h4 text-center">New TM Lead</h4>
                <DangerAlert data={this.state.error} />
                <label> Reference ID</label>
                <input
                  required
                  type="text"
                  className="form-control  col shadow"
                  placeholder="Reference ID"
                  value={this.state.referenceNumber}
                  onChange={this.onChange}
                />
                <div className="text-center">
                  <button
                    className="mt-2 btn btn-primary col-4"
                    type="submit"
                  >
                    {" "}
                    Search
                  </button>
                  <button
                    className=" mt-2 ml-4 btn btn-primary col-4"
                    onClick={this.props.onClickManualEntry}
                  >
                    Manual Entry
                  </button>
                </div>
              </form>
            </div>
          </div>

        </div>
      );
    }
  }
}
