import React from "react";
import StateDropdown from "../generalUseComponents/state-dropdown";

export const ExpiredListingsInput = (props) => {
  return (
    <div className="col">
      <div className="mt-2 mr-2">
        <table className="table table-striped table-sm table-bordered shadow">
          <thead>
            <tr className="table-active">
              <td colSpan="2" className="text-center font-weight-bold">
                Interview Questions
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Caller Attitude</td>
              <td>
                <select
                  className="form-control input-group col"
                  value={props.lead.callerAtt}
                  onChange={(e) => props.setters.setCallerAtt(e.target.value)}
                >
                  <option value=""></option>
                  <option value="EX">Excited/Eager / Interested </option>
                  <option value="ID">Indifferent / Skeptical</option>
                  <option value="AN">Annoyed / Bad Time</option>
                  <option value="SH">Short / Blunt / Rude</option>
                </select>
              </td>
            </tr>

            <tr>
              <td>Property State</td>
              <td>
                <StateDropdown
                  state={props.lead.state}
                  onChangeState={props.onChangeState}
                />
              </td>
            </tr>

            <tr>
              <td>Expired Reason</td>
              <td>
                <select
                  className="form-control input-group col"
                  value={props.lead.expiredReason}
                  onChange={(e) =>
                    props.setters.setExpiredReason(e.target.value)
                  }
                >
                  <option value=""></option>
                  <option value="No Offers">No Offers</option>
                  <option value="Could Not Find Home">
                    Could Not Find Home
                  </option>
                  <option value="Personal Reasons">Personal Reasons</option>
                  <option value="Home Repair">Home Repairs</option>
                </select>
              </td>
            </tr>

            <tr>
              <td>Contact Type</td>
              <td>
                <select
                  className="form-control input-group col"
                  value={props.lead.contactType}
                  onChange={(e) => props.setters.setContactType(e.target.value)}
                >
                  <option value=""></option>
                  <option value="Text">Text</option>
                  <option value="Call">Call</option>
                  <option value="Email">Email</option>

                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
