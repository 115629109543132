import React, { Component } from "react";

export default class VeteranInterviewQuestion extends Component {
    constructor(props){
        super(props);
    }
  render() {
    if (this.props.lead.primaryGoal == "Other") {
      return (
        <div className="input-group-sm">
          <select
          className="form-control input-group col"
          disabled
          value={this.props.lead.isVeteran}
          onChange={(e)=>{
            this.props.setNeedsToBeSaved(true)
            this.props.onChangeIsVeteran(e.target.value)}}
        >
          <option value=""></option>
          <option value="Yes">Yes </option>
          <option value="No">No</option>
        </select>
        </div>
      );
    } else {
      return (
        <div className="input-group-sm">
          <select
          className="form-control input-group col"
          value={this.props.lead.isVeteran}
          onChange={(e)=>{
            this.props.setNeedsToBeSaved(true)
            this.props.onChangeIsVeteran(e.target.value)}}
        >
          <option value=""></option>
          <option value="Yes">Yes </option>
          <option value="No">No</option>
        </select>
        </div>
      );
    }
  }
}
