import React, { useState, useEffect } from "react";
import employmentTest from "./employementTest";
import axios from "axios";
import calcFhaCashoutEligibility from "./calcFhaCashoutEligibility";

export const FhaCashout = ({lead}) => {
    const [maxFhaCoLtv, setMaxFhaCoLtv] = useState('');
    const [fhaFICO, setFhaFICO] = useState('');
    const [jumboThreshold, setJumboThreshold] = useState('');
    const [fhaCashoutStatus, setFhaCashoutStatus] = useState({ classStr: '', message: '' })
    
    useEffect(() => {
        axios.get("backend/CompField").then((res) => {
            if (res.data && res.data[0]) {
                setMaxFhaCoLtv(res.data[0].maxFHACOLTV);
                setFhaFICO(res.data[0].fhaFICO);
                setJumboThreshold(res.data[0].jumboThreshold);
            }
        });
    }, [])

    useEffect(() => {
        const status = calcFhaCashoutEligibility(lead, maxFhaCoLtv, fhaFICO, jumboThreshold)
        if (status.classStr && status.message) {
            setFhaCashoutStatus(status)
        }
    }, [lead, maxFhaCoLtv, fhaFICO, jumboThreshold])

    const requiredFields = lead.mortgageDate !== "" && lead.creditScore !== "" && lead.state !== "" &&
    (lead.loanBalance !== "" &&lead.loanBalance !== null) && (lead.estimatedValue !== "" && lead.loanBalance!== null) &&
    lead.occupancy !== "" && employmentTest(lead.employment, lead.hasCoBorrower) !== "";

    return (
        <tr>
            <td>FHA Cashout</td>
            <td>
                {
                    requiredFields ? (
                        <div id="fhaCashoutResponse" className={fhaCashoutStatus.classStr}>
                            {fhaCashoutStatus.message}
                        </div>
                    ) : <div id="fhaCashoutResponse"></div>
                }
            </td>
        </tr>
    )
}
