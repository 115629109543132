import calcLTV from "./calcLTV";
import calcMiElimFloor from "./calcMiElimFloor";
import calcMaxConvMiLtv from "./calcMaxConvMiLtv";
import employmentTest from "./employementTest"
import calcPaymentsMade from "../../generalUseFunctions/calcPaymentsMade"

export default function calcMiRemovalEligibility (lead, convBaseRate, jumboThreshold) {
    if (!lead || !lead.vendorLeadCode) {
        return {
            classStr: '',
            message: '',
        }
    }

    const danger = 'font-weight-bold bg-danger text-white';
    const success = 'bg-success font-weight-bold text-white';

    const ltv = calcLTV(lead.loanBalance, lead.estimatedValue);
    const miElimFloor = calcMiElimFloor(convBaseRate, lead.creditScore, lead.loanBalance, jumboThreshold, lead.vendorLeadCode);
    const maxMiLtv = calcMaxConvMiLtv(lead.state);

    if (employmentTest(lead.employment, lead.hasCoBorrower) === 'Fail') {
        return {
            classStr: danger,
            message: 'Employment Issue',
        }
    }

    if (lead.hasMortgageInsurance === 'No') {
        return {
            classStr: danger,
            message: 'Loan does not have MI',
        }
    }

    if (calcPaymentsMade(lead.mortgageDate) < 5) {
        return {
            classStr: danger,
            message: 'Seasoning Needed: 5 months',
        }
    }

    if (lead.loanBalance < 75000) {
        return {
            classStr: danger,
            message: 'Loan Balance too low: $75,000',
        }
    }

    if (ltv < 78 && lead.creditScore >= 640) {
        return {
            classStr: success,
            message: 'Eligible',
        }
    }

    if (lead.creditScore < 680) {
        return {
            classStr: danger,
            message: 'FICO too low: 680',
        }
    }

    if (lead.interestRate < miElimFloor) {
        return {
            classStr: danger,
            message: `Interest rate too low: ${miElimFloor}`,
        }
    }

    if (ltv > maxMiLtv) {
        return {
            classStr: danger,
            message: `LTV too high: ${maxMiLtv}`,
        }
    }

    return {
        classStr: success,
        message: 'Eligible',
    }
}