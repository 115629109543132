import React from "react";
import CurrencyFormat from "react-currency-format";
import StateDropdown from "../generalUseComponents/state-dropdown";

export const ExpiredListingContact = (props) => {
  return (
    <div className="col">
      <div className="mt-2 ml-2">
        <table className="table table-striped table-sm table-bordered shadow">
          <thead>
            <tr className="table-active">
              <td colSpan="4" className="text-center font-weight-bold">
                Contact
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>B1</td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  placeholder="First Name"
                  value={props.lead.b1FirstName || ""}
                  onChange={(e) => props.setters.setB1FirstName(e.target.value)}
                />
              </td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Last Name"
                  value={props.lead.b1LastName || ""}
                  onChange={(e) => props.setters.setB1LastName(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>B2</td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  placeholder="First Name"
                  value={props.lead.b2FirstName || ""}
                  onChange={(e) => props.setters.setB2FirstName(e.target.value)}
                />
              </td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Last Name"
                  value={props.lead.b2LastName || ""}
                  onChange={(e) => props.setters.setB2LastName(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Phone</td>
              <td colSpan="2">
                <CurrencyFormat
                  className="form-control"
                  format="(###) ###-####"
                  mask="_"
                  placeholder="(###) ###-####"
                  value={props.lead.importDataCellPhone!=undefined? props.lead.importDataCellPhone:""}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    // formattedValue = $2,223
                    // value ie, 2223
                    props.setters.setPhoneNumber(value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Email</td>
              <td colSpan="2">
                <input
                  className="form-control"
                  value={props.lead.importDataEmail}
                  onChange={(e) =>
                    props.setters.setImportDataEmail(e.target.value)
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Address</td>
              <td colSpan="2">
                <input
                  className="w-100 form-control"
                  type="text"
                  placeholder="Street Address"
                  value={props.lead.streetAddress}
                  onChange={props.setters.setStreetAddress}
                />
              </td>
            </tr>
            <tr>
              <td>City</td>
              <td colSpan="2">
                <input
                  className="form-control"
                  type="text"
                  placeholder="city"
                  value={props.lead.city}
                  onChange={props.setters.setCity}
                />
              </td>
            </tr>
            <tr>
              <td>State</td>
              <td colSpan="2">
                <StateDropdown
                  state={props.lead.state}
                  onChangeState={props.setters.setState}
                />
              </td>
            </tr>
            <tr>
              <td>Zip</td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  placeholder="zipcode"
                  value={props.lead.postalCode}
                  onChange={props.setters.setPostalCode}
                />
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
