import React, { useState, useEffect } from "react";
import axios from 'axios';
import { CummingsAndCoBottomSection } from "./CummingsAndCoBottomSection";
import { StandardBottomSection } from "./standardBottomSection";
import determineHuntGroupMitel from "./determineHuntGroupMitel";
import determineHuntGroupRingCentral from "./determineHuntGroupRingCentral";

export const LeadBottomSectionManager = (props) => {
  const [huntGroup, setHuntGroup] = useState();

  useEffect(() => {
    async function getAndSetHuntGroup () {
      const resp = await axios.get(`/backend/activeleads/huntGroup`).catch((err) => console.log(err.message));
      if (resp.data === 'Ring Central') {
        setHuntGroup(determineHuntGroupRingCentral(props.lead));
        return;
      }

      setHuntGroup(determineHuntGroupMitel(props.lead, 'Yes'));
    }
  
    getAndSetHuntGroup();
  }, [props.lead])

  if (props.lead.campaignName === "BSEL" || props.campaignName === "BSYL") {
    return (
      <div className="container">
        <CummingsAndCoBottomSection
          LO={props.LO}
          user={props.user}
          lead={props.lead}
          onSave={props.onSave}
          onSaveAndClose={props.onSaveAndClose}
          onCRMTransfer={props.onCRMTransfer}
          transferLock={props.transferLock}
          onChangeLoanOfficer={props.onChangeLoanOfficer}
          loadingCRM={props.loadingCRM}
          loadingCummings={props.loadingCummings}
          onCummingsTransfer={props.onCummingsTransfer}
          huntGroup={huntGroup}
        />
      </div>
    );
  } else {
    return (
      <div className="container">
        <StandardBottomSection
          LO={props.LO}
          user={props.user}
          lead={props.lead}
          onSave={props.onSave}
          onSaveAndClose={props.onSaveAndClose}
          onCRMTransfer={props.onCRMTransfer}
          transferLock={props.transferLock}
          onChangeLoanOfficer={props.onChangeLoanOfficer}
          loadingCRM={props.loadingCRM}
          loadingCummings={props.loadingCummings}
          huntGroup={huntGroup}
        />
      </div>
    );
  }
};
