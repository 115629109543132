import React, { useState } from "react";
import UpdateLoanOfficer from "./updateLoanOfficer";
import DangerAlert from "../generalUseComponents/dangerAlert";
export const StandardBottomSection = (props) => {
  const [loading, setLoading] = useState(false);
  const selectLOOptions = props.LO.map((lo) => (
    <option key={lo._id} fullName={lo.fullname} email={lo.email} value={lo._id}>
      {lo.firstName}
      {"  "} {lo.lastName}
    </option>
  ));
  if (props.lead.isTransferred == "true" ) {
    return (
      <div>
        <div className="row ">
          <div className=" col-4"></div>
          <div className="mb-2 col-4">
            <div className="row">
              <div className="mb-2 col">
                <a
                  className="btn btn-primary mp-2 col"
                  onClick={() => {
                    props.onSave();
                  }}
                >
                  Save
                </a>
              </div>
              <div className="col">
                <a
                  className="btn btn-primary mp-2 col"
                  onClick={() => {
                    props.onSaveAndClose();
                  }}
                >
                  Save And Close
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mb-2">
          <h5 className="text-danger">Loan Officer Transfer Completed</h5>
        </div>
        <div>
          <UpdateLoanOfficer
            user={props.user}
            LO={props.LO}
            onChangeLoanOfficer={props.onChangeLoanOfficer}
            lead={props.lead}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="text-center">
          <DangerAlert data={props.transferError} />
        </div>

        <div className="row justify-content-center mb-1 ">
          <button className="btn btn-primary ml-2 mr-2" onClick={props.onSave}>
            Save
          </button>
          <button
            className="btn btn-primary "
            onClick={() => {
              props.onSaveAndClose();
            }}
          >
            Save and Close
          </button>
          <p className="h5 ml-2">Hunt Group #</p>
          <input
            disabled
            className="ml-1 h5 text-center"
            size="4"
            value={props.huntGroup}
          />
          <select
            className="form-control input-group col-3 ml-2"
            onChange={(e) => props.onChangeLoanOfficer(e)}
            value={props.loanOfficer}
            disabled={props.transferLock}
          >
            <option value="">--Loan Officer--</option>
            {selectLOOptions}
          </select>
          <button
            className="btn btn-primary ml-2"
            onClick={props.onCRMTransfer}
          >
            Transfer
          </button>
        </div>
      </div>
    );
  }
};
