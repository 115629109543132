import React, { Component } from "react";
var CurrencyFormat = require("react-currency-format");
export default class EstimatedValueInterviewQuestion extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (
      this.props.lead.primaryGoal == "Purchase" ||
      this.props.lead.primaryGoal == "Renovation" ||
      this.props.lead.primaryGoal == "Other"
    ) {
      if (this.props.lead.estimatedValue == null) {
        return (
          <div className="input-group-sm">
            <CurrencyFormat
            disabled
              isNumericString={true}
              className="form-control"
              thousandSeparator={true}
              prefix={"$"}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                // formattedValue = $2,223
                // value ie, 2223
                this.props.onChangeEstimatedValue(value);
              }}
            />
          </div>
        );
      } else{
        return (
          <div className="input-group-sm">
            <CurrencyFormat
              disabled
              isNumericString={true}
              className="form-control"
              value={this.props.lead.estimatedValue}
              thousandSeparator={true}
              prefix={"$"}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                // formattedValue = $2,223
                // value ie, 2223
                this.props.onChangeEstimatedValue(value);
              }}
            />
          </div>
        );
      }
  
    } else {
      if (this.props.lead.estimatedValue == null) {
        return (
          <div className="input-group-sm">
            <CurrencyFormat
              isNumericString={true}
              className="form-control"
              thousandSeparator={true}
              prefix={"$"}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                // formattedValue = $2,223
                // value ie, 2223
                this.props.onChangeEstimatedValue(value);
              }}
            />
          </div>
        );
      } else {
        return (
          <div className="input-group-sm">
            <CurrencyFormat
              isNumericString={true}
              className="form-control"
              value={this.props.lead.estimatedValue}
              thousandSeparator={true}
              prefix={"$"}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                // formattedValue = $2,223
                // value ie, 2223
                this.props.onChangeEstimatedValue(value);
              }}
            />
          </div>
        );
      }
    }
  }
}
