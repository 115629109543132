import React, { Component } from "react";
import TmLeadForm from "./tm-lead-form";

export default class NewTmLead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isManualEntry: "",
    };
  }

  componentDidMount() {
    this.setState({ isManualEntry: false });
  }

  onClickManualEntry(e) {

    this.setState({ isManualEntry: !this.state.isManualEntry});
  }
  render() {
    return (
      <div>
        
        <TmLeadForm
          data={this.state}
          onClickManualEntry={this.onClickManualEntry.bind(this)}
          user={this.props.user}
          handleShowLead={this.props.handleShowLead}
          onClickLeadDashboard={this.props.onClickLeadDashboard}
        />
      </div>
    );
  }
}
