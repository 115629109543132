import React, { Component } from "react";

import "react-datepicker/dist/react-datepicker.css";
import calcEquity from "./tmInsight/calcEquity";
import calcPaymentsMade from "./tmInsight/calcPaymentsMade";
import calcLTV from "../generalUseFunctions/calcLTV";
import DateHandle from "../generalUseComponents/dateHandle";
import TmInsightsEquity from "./tmInsight/tm-insights-equity";
import getLTVConfidence from "./tmInsight/getLTVConfidence";

export default class TmInsight extends Component {
  render() {
    return (
      <div className="col">
        <div className=" mt-2 ml-2">
          <div className="shadow">{/** start tm insights */}
            <div className="mb-2 table-header">{/** start header */}
              TM Insight
            </div>{/** start header */}
            <div className="p-2">{/** start current loan */}
              <h6>Current loan</h6>
              <table className="table table-striped table-sm table-bordered infoTable">
                <tbody>
                  <tr>
                      <td>Loan Date</td>
                      <td>
                        <DateHandle
                          lead={this.props.lead}
                          handleChangeDate={this.props.handleChangeDate}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Equity</td>
                      <td>
                        <TmInsightsEquity
                          equity={calcEquity(
                            this.props.lead.estimatedValue,
                            this.props.lead.loanBalance
                          )}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>LTV</td>
                      <td>
                        {calcLTV(
                          this.props.lead.loanBalance,
                          this.props.lead.estimatedValue
                        )}
                      </td>
                    </tr>
                    <tr>
                        <td>Experian LTV</td>
                        <td>{this.props.lead.exLtvRange ? this.props.lead.exLtvRange.slice(2) : ''}</td>
                    </tr>
                    <tr>
                        <td>LTV Confidence</td>
                        <td>{getLTVConfidence(this.props.lead.exLtvRange)}</td>
                    </tr>
                    <tr>
                      <td>Payments Made</td>
                      <td>{calcPaymentsMade(this.props.lead.mortgageDate)}</td>
                    </tr>
                    <tr>
                        <td>Community Lending</td>
                        <td className={this.props.lead.chaseEligible === 'Yes' ? 'background-color-green text-white' : ''}>{this.props.lead.chaseEligible}</td>
                    </tr>
                </tbody>
              </table>
            </div>{/** end current loan */}
            <div className="p-2">{/** start Mortgage insights */}
              <h6>Mortgage insight</h6>
              <table className="table table-striped table-sm table-bordered infoTable">
                <tbody>
                  <tr>
                    <td>Total First Mortgages</td>
                    <td>{this.props.lead.totalFirstMortgages || this.props.lead.totalFirstMortgages === 0? this.props.lead.totalFirstMortgages : '' }</td>
                  </tr>
                  <tr>
                    <td>Mortages inquiries</td>
                    <td>{this.props.lead.mortgageInquiries || this.props.lead.mortgageInquiries === 0? this.props.lead.mortgageInquiries : '' }</td>
                  </tr>
                  <tr>
                    <td>HELOC Utilization</td>
                    <td>{this.props.lead.helockUtilization ? `${this.props.lead.helockUtilization}%` : '' }</td>
                  </tr>
                </tbody>
              </table>
            </div>{/** end mortgage insights */}
            <div className="p-2">{/** start Revolving debt */}
              <h6>Revolving debt</h6>
              <table className="table table-striped table-sm table-bordered infoTable">
                <tbody>
                  <tr>
                    <td>Revolving Debt to Credit</td>
                    <td>{this.props.lead.revolvingDebtToCreditRatio || this.props.lead.revolvingDebtToCreditRatio ===0 ? `${this.props.lead.revolvingDebtToCreditRatio}%` : '' }</td>
                  </tr>
                  <tr>
                    <td>Revolving Debt balance</td>
                    <td>{this.props.lead.revolvingDebtBalance ||this.props.lead.revolvingDebtBalance === 0? `$${this.props.lead.revolvingDebtBalance.toLocaleString("en-US")}` : '' }</td>
                  </tr>
                  <tr>
                    <td>Revolving Debt Payment</td>
                    <td>{this.props.lead.revolvingDebtPayment ? `$${this.props.lead.revolvingDebtPayment.toLocaleString("en-US")}` : '' }</td>
                  </tr>
                </tbody>
              </table>
            </div>{/** end Revolving debt */}
            <div className="p-2">{/** start Revolving debt trends */}
              <h6>Revolving debt trends</h6>
              <table className="table table-striped table-sm table-bordered infoTable">
                <tbody>
                  <tr>
                    <td>Late payments trends</td>
                    <td>{this.props.lead.latePaymentTrends || this.props.lead.latePaymentTrends === 0? this.props.lead.latePaymentTrends : '' }</td>
                  </tr>
                  <tr>
                    <td>New Credit Cards</td>
                    <td>{this.props.lead.newCreditCard || this.props.lead.newCreditCard === 0? this.props.lead.newCreditCard : '' }</td>
                  </tr>
                </tbody>
              </table>
            </div>{/** end Revolving debt trends */}
          </div>{/** end tm insights */}
        </div>
      </div>
    );
  }
}
