export default function determineHuntGroupMitel(lead, prime) {
    if (!lead) return;

    let stateCodeMap = {
        'AL': '11',
        'AZ': '43',
        'CA': '12',
        'CO': '13',
        'CT': '14',
        'DE': '16',
        'DC': '15',
        'FL': '17',
        'GA': '18',
        'ME': '19',
        'MD': '20',
        'MA': '21',
        'MI': '22',
        'MN': '23',
        'NH': '24',
        'NJ': '25',
        'NM': '26',
        'NY': '27',
        'NC': '28',
        'OR': '29',
        'PA': '30',
        'SC': '32',
        'TN': '33',
        'TX': '34',
        'VA': '35',
        'WA': '36',
        'WV': '37',
        'OH': '38',
        'IL': '39',
        'OK': '40',
        'MS': '41',
        'UT': '42',
        'LA': '44',
        'WI': '45',
        'MO': '46',
        'IN': '47',
  }

  let huntGroupString = stateCodeMap[lead.state] ?? "--"

    let type = '';

    if (lead.vendorLeadCode != undefined) {
        if (
            lead.vendorLeadCode.substring(0, 2) == 'VA' ||
            lead.vendorLeadCode.substring(0, 2) == 'CO' ||
            lead.vendorLeadCode.substring(0, 2) == 'FH'

        ) {
            type = lead.vendorLeadCode.substring(0, 2);
        } else  if(lead.vendorLeadCode.substring(0, 4) == 'PCLS'){
  type = "CO"
        }else if (lead.importDataLoanProgram) {
            if (lead.importDataLoanProgram.toLowerCase().includes('fha')) {
                type = 'FH';
            } else if (
                lead.importDataLoanProgram.toLowerCase().includes('va')
            ) {
                type = 'VA';
            } else type = 'CO';
        } else {
            type = lead.loanType;
        }
    }

    if (type == 'VA') {
        huntGroupString += ' 1';
    } else if (type == 'CO') {
        huntGroupString += ' 2';
    } else {
        huntGroupString += ' 3';
    } 

    if (lead.primaryGoal == 'Lower Payment') {
        huntGroupString += '4';
    } else if (lead.primaryGoal == 'Cash Out') {
        huntGroupString += '5';
    } else if (
        lead.primaryGoal == 'Purchase' ||
        lead.primaryGoal == 'Renovation' ||
        lead.primaryGoal == 'Other' ||
        (lead.vendorLeadCode != undefined &&
            lead.vendorLeadCode.substring(0, 2) == 'BS')
    ) {
        huntGroupString += '6';
    } else {
        huntGroupString += '-';
    }

    if (prime === 'Yes') {
        huntGroupString += '1';
    } else if (prime === 'No') {
        huntGroupString += '2';
    } else {
        huntGroupString += '-';
    }

    return huntGroupString;
}
