import calcPaymentsMade from "../../generalUseFunctions/calcPaymentsMade";
import employmentTest from "./employementTest";
import calcFhaStreamRate from "./calcFhaStreamRate";

export default function calcFhaStreamlineEligibility (lead, fhaFICO, jumboThreshold, fhaBase) {
    if (!lead || !lead.vendorLeadCode) {
        return {
            classStr: '',
            message: '',
        }
    }

    const danger = 'font-weight-bold bg-danger text-white';
    const success = 'bg-success font-weight-bold text-white';

    const fhaRate = calcFhaStreamRate(fhaBase, lead.creditScore, lead.loanBalance, jumboThreshold);

    if (employmentTest(lead.employment, lead.hasCoBorrower) === 'Fail') {
        return {
            classStr: danger,
            message: 'Employment Issue',
        }
    }

    if (lead.vendorLeadCode.substring(0, 2) !== 'FH') {
        return {
            classStr: danger,
            message: 'Not an FHA loan',
        }
    }

    if (calcPaymentsMade(lead.mortgageDate) < 5) {
        return {
            classStr: danger,
            message: 'Seasoning needed: 5 months',
        }
    }

    if (lead.interestRate < fhaRate) {
        return {
            classStr: danger,
            message: `InterestRate too low: ${fhaRate}`,
        }
    }

    if (lead.creditScore < fhaFICO) {
        return {
            classStr: danger,
            message: `FICO too low: ${fhaFICO}`,
        }
    }

    if (lead.loanBalance < 75000) {
        return {
            classStr: danger,
            message: 'Loan Balance too low: $75,000',
        }
    }

    if (lead.loanBalance < (jumboThreshold - 5000) || lead.creditScore >= 640) {
        return {
            classStr: success,
            message: 'Eligible',
        }
    }

    return {
        classStr: danger,
        message: 'Credit Score too low: 640',
    }
}