import React, {Component, useState, useEffect } from "react";
import NavBar from "./admin-veiw-components/NavBar";
import LeadDashboardContent from "./admin-veiw-components/dashboard-content";
import SavingModal from "./admin-veiw-components/savingModal";
import axios from "axios";

// import React from 'react'

// export const AdminDashboard = (props) => {
//   return (
//     <div>
      
//     </div>
//   )
// }


export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.handleShowLead = this.handleShowLead.bind(this);
    this.setOpenConfirmation = this.setOpenConfirmation.bind(this);
    this.claimLeadsQueue = this.setClaimLeadsQueue.bind(this);

    this.state = {
      homePage: "",
      leadDashboard: "",
      editProfile: "",
      newTmLead: "",
      DmLeadsDashboard: "",
      newDmLead: "",
      importMailer: "",
      comparisonField: "",
      createUser: "",
      reports:"",
      claimQueue:"",
      showLead: "",
      showDMLead: "",
      needsToBeSaved: false,
      openConfirmation: false,
      navigationPage: "",
      claimLeadsQueue:[]
    
    };
  }
  componentDidMount() {
    this.getQueueLeads();
    setInterval(() => this.getQueueLeads(), 1000 * 10);

    this.setState({
      home: false,
      leadDashboard: true,
      editProfile: false,
      newTmLead: false,
      DmLeadsDashboard: false,
      newDmLead: false,
      importMailer: false,
      comparisonField: false,
      createUser: false,
      claimQueue: false
    });
  }

  onClickHome(e) {
    e.preventDefault();
    this.setState({
      home: true,
      leadDashboard: false,
      editProfile: false,
      newTmLead: false,
      newDmLead: false,
      importMailer: false,
      comparisonField: false,
      createUser: false,
    });
  }
  onClickReports(e) {
    if (this.state.needsToBeSaved === true) {
      this.setOpenConfirmation(true);
      this.setState({ navigationPage: "Reports" });
    }

    if (this.state.needsToBeSaved === false) {
      this.handleShowLead("");
      this.setState({
        home: false,
        DmLeadsDashboard: false,
        leadDashboard: false,
        editProfile: false,
        newTmLead: false,
        newDmLead: false,
        importMailer: false,
        comparisonField: false,
        createUser: false,
        reports: true,
        claimQueue: false
      });
    }
  }
  onClickLeadDashboard(e) {
    if (this.state.needsToBeSaved === true) {
      this.setOpenConfirmation(true);
      this.setState({ navigationPage: "Lead Dashboard" });
    }

    if (this.state.needsToBeSaved === false) {
      this.handleShowLead("");
      this.setState({
        home: false,
        DmLeadsDashboard: false,
        leadDashboard: true,
        editProfile: false,
        newTmLead: false,
        newDmLead: false,
        importMailer: false,
        comparisonField: false,
        createUser: false,
        reports: false,
        claimQueue: false
      });
    }
  }
  onClickEditProfile(e) {
    e.preventDefault();

    if (this.state.needsToBeSaved === true) {
      this.setOpenConfirmation(true);
      this.setState({ navigationPage: "Edit Profile" });
    }

    if (this.state.needsToBeSaved === false) {
      this.handleShowLead("");
      this.setState({
        home: false,
        leadDashboard: false,
        DmLeadsDashboard: false,
        editProfile: true,
        newTmLead: false,
        newDmLead: false,
        importMailer: false,
        comparisonField: false,
        createUser: false,
        reports: false,
        claimQueue: false
      });
    }
  }

  onClickNewDmLead(e) {
    e.preventDefault();
    if (this.state.needsToBeSaved === true) {
      this.setOpenConfirmation(true);
      this.setState({ navigationPage: "New DM Lead" });
    }

    if (this.state.needsToBeSaved === false) {
      this.handleShowLead("");
      this.setState({
        home: false,
        leadDashboard: false,
        DmLeadsDashboard: false,
        editProfile: false,
        newTmLead: false,
        newDmLead: true,
        importMailer: false,
        comparisonField: false,
        createUser: false,
        reports: false,
        claimQueue: false
      });
    }
  }
  onClickNewTmLead(e) {
 
    if (this.state.needsToBeSaved === true) {
      this.setOpenConfirmation(true);
      this.setState({ navigationPage: "New TM Lead" });
    }

    if (this.state.needsToBeSaved === false) {
      this.handleShowLead("");
      this.setState({
        home: false,
        leadDashboard: false,
        DmLeadsDashboard: false,
        editProfile: false,
        newTmLead: true,
        newDmLead: false,
        importMailer: false,
        comparisonField: false,
        createUser: false,
        reports: false,
        claimQueue: false
      });
    }
  }

  onClickClaimQueue(e) {
    if (this.state.needsToBeSaved === true) {
      this.setOpenConfirmation(true);
      this.setState({ navigationPage:"Claim Queue"});
    }

    if (this.state.needsToBeSaved === false) {
      this.handleShowLead("");
      this.setState({
        home: false,
        leadDashboard: false,
        DmLeadsDashboard: false,
        editProfile: false,
        newTmLead: false,
        newDmLead: false,
        importMailer: false,
        comparisonField: false,
        createUser: false,
        reports: false,
        claimQueue: true
      });
    }
  };

  onClickImportMailer(e) {
    e.preventDefault();
    if (this.state.needsToBeSaved === true) {
      this.setOpenConfirmation(true);
      this.setState({ navigationPage: "Import Data" });
    }

    if (this.state.needsToBeSaved === false) {
      this.handleShowLead("");
      this.setState({
        home: false,
        leadDashboard: false,
        DmLeadsDashboard: false,
        editProfile: false,
        newTmLead: false,
        newDmLead: false,
        importMailer: true,
        comparisonField: false,
        createUser: false,
        reports: false,
        claimQueue: false
      });
    }
  }
  onClickComparisonField(e) {
    e.preventDefault();
    if (this.state.needsToBeSaved === true) {
      this.setOpenConfirmation(true);
      this.setState({ navigationPage: "Comparison Fields" });
    }

    if (this.state.needsToBeSaved === false) {
      this.handleShowLead("");
      this.setState({
        home: false,
        leadDashboard: false,
        DmLeadsDashboard: false,
        editProfile: false,
        newTmLead: false,
        newDmLead: false,
        importMailer: false,
        comparisonField: true,
        createUser: false,
        reports: false,
        claimQueue: false
      });
    }
  }
  onClickDMLeadsDashboard(e) {
  
    if (this.state.needsToBeSaved === true) {
      this.setOpenConfirmation(true);
      this.setState({ navigationPage: "DM Leads Dashboard" });
    }

    if (this.state.needsToBeSaved === false) {
      this.handleShowLead("");
      this.setState({
        home: false,
        leadDashboard: false,
        DmLeadsDashboard: true,
        editProfile: false,
        newTmLead: false,
        newDmLead: false,
        importMailer: false,
        comparisonField: false,
        createUser: false,
        reports: false,
        claimQueue: false
      });
    }
  }
  onClickCreateUser(e) {
    if (this.state.needsToBeSaved === true) {
      this.setOpenConfirmation(true);
      this.setState({ navigationPage: "Create User" });
      // this.setState({
      //   home: false,
      //   leadDashboard: false,
      //   editProfile: false,
      //   newTmLead: false,
      //   newDmLead: false,
      //   importMailer: false,
      //   comparisonField: false,
      //   createUser: true,
      //   needsToBeSaved: false
      // });
    }

    if (this.state.needsToBeSaved === false) {
      this.handleShowLead("");
      this.setState({
        home: false,
        leadDashboard: false,
        DmLeadsDashboard: false,
        editProfile: false,
        newTmLead: false,
        newDmLead: false,
        importMailer: false,
        comparisonField: false,
        createUser: true,
        reports: false,
        claimQueue: false
      });
    }
  }

  setLeadDashboard(bool) {
    this.setState({
      home: false,
      leadDashboard: bool,
      editProfile: false,
      newTmLead: false,
      newDmLead: false,
      DmLeadsDashboard: false,
      importMailer: false,
      comparisonField: false,
      createUser: false,
      claimQueue: false
    });
  }
  setDMLeadDashboard(bool) {
    this.setState({
      home: false,
      leadDashboard: false,
      DmLeadsDashboard: bool,
      editProfile: false,
      newTmLead: false,
      newDmLead: false,
      importMailer: false,
      comparisonField: false,
      createUser: false,
      reports: false,
      claimQueue: false
    });
  }

  getQueueLeads = () => {
    axios.get("/backend/activeleads/ClaimQueue").then((resp) => {
      this.setClaimLeadsQueue(resp.data);
    });
  };

  handleShowLead(lead) {
    this.setState({ showLead: lead });
  }
  handleShowDMLead(lead) {
    this.setState({ showDMLead: lead });
  }
  setNeedsToBeSaved(bool) {
    this.setState({ needsToBeSaved: bool });
  }
  setOpenConfirmation(bool) {
    this.setState({ openConfirmation: bool });
  }
  setNewTmLead(bool) {
    this.setState({ NewTmLead: bool });
  }
  setTmLeadsDashboard(bool) {
    this.setState({ TmLeadsDashboard: bool });
  }
  setClaimQueue(bool) {
    this.setState({ ClaimQueue: bool });
  }
  setClaimLeadsQueue(lead) {
    this.setState({ claimLeadsQueue: lead });
  }
  continueWithoutSaving() {
    this.handleShowLead("");
    this.handleShowDMLead("");
    this.setNeedsToBeSaved(false);
    this.setOpenConfirmation(false);

    if (this.state.navigationPage == "Create User") {
      this.setState({
        home: false,
        leadDashboard: false,
        DmLeadsDashboard: false,
        editProfile: false,
        newTmLead: false,
        newDmLead: false,
        importMailer: false,
        comparisonField: false,
        createUser: true,
        needsToBeSaved: false,
        reports: false,
        navigationPage: "",
      });
    } else if (this.state.navigationPage == "New TM Lead") {
      this.setState({
        home: false,
        leadDashboard: false,
        DmLeadsDashboard: false,
        editProfile: false,
        newTmLead: true,
        newDmLead: false,
        importMailer: false,
        comparisonField: false,
        createUser: false,
        needsToBeSaved: false,
        reports: false,
        navigationPage: "",
      });
    } else if (this.state.navigationPage == "New DM Lead") {
      this.setState({
        home: false,
        leadDashboard: false,
        DmLeadsDashboard: false,
        editProfile: false,
        newTmLead: false,
        newDmLead: true,
        importMailer: false,
        comparisonField: false,
        createUser: false,
        needsToBeSaved: false,
        reports: false,
        navigationPage: "",
      });
    } else if (this.state.navigationPage == "Import Data") {
      this.setState({
        home: false,
        leadDashboard: false,
        DmLeadsDashboard: false,
        editProfile: false,
        newTmLead: false,
        newDmLead: false,
        importMailer: true,
        comparisonField: false,
        createUser: false,
        needsToBeSaved: false,
        reports: false,
        navigationPage: "",
      });
    } else if (this.state.navigationPage == "Comparison Fields") {
      this.setState({
        home: false,
        leadDashboard: false,
        DmLeadsDashboard: false,
        editProfile: false,
        newTmLead: false,
        newDmLead: false,
        importMailer: false,
        comparisonField: true,
        createUser: false,
        needsToBeSaved: false,
        reports: false,
        navigationPage: "",
      });
    } else if (this.state.navigationPage == "Edit Profile") {
      this.setState({
        home: false,
        leadDashboard: false,
        DmLeadsDashboard: false,
        editProfile: true,
        newTmLead: false,
        newDmLead: false,
        importMailer: false,
        comparisonField: false,
        createUser: false,
        needsToBeSaved: false,
        reports: false,
        navigationPage: "",
      });
    } else if (this.state.navigationPage == "DM Leads Dashboard") {
      this.setState({
        home: false,
        leadDashboard: false,
        DmLeadsDashboard: true,
        editProfile: false,
        newTmLead: false,
        newDmLead: false,
        importMailer: false,
        comparisonField: false,
        createUser: false,
        needsToBeSaved: false,
        reports: false,
        navigationPage: "",
      });
    }else if (this.state.navigationPage == "Reports") {
      this.setState({
        home: false,
        leadDashboard: false,
        DmLeadsDashboard: false,
        editProfile: false,
        newTmLead: false,
        newDmLead: false,
        importMailer: false,
        comparisonField: false,
        createUser: false,
        needsToBeSaved: false,
        reports: true,
        navigationPage: "",
      });
    }
    else if (this.state.navigationPage == "Lead Queue") {
      this.setState({
        home: false,
        leadDashboard: false,
        DmLeadsDashboard: false,
        editProfile: false,
        newTmLead: false,
        newDmLead: false,
        importMailer: false,
        comparisonField: false,
        createUser: false,
        needsToBeSaved: false,
        reports: false,
        leadQueue: true,
        navigationPage: "",
      });
    }
  }

  render() {
    return (
      <div>
        <SavingModal
          openConfirmation={this.state.openConfirmation}
          setOpenConfirmation={this.setOpenConfirmation.bind(this)}
          continueWithoutSaving={this.continueWithoutSaving.bind(this)}
        />
        <NavBar
          navbarState={this.state}
          data={this.props.data}
          onClickLeadDashboard={this.onClickLeadDashboard.bind(this)}
          onClickDMLeadsDashboard={this.onClickDMLeadsDashboard.bind(this)}
          onClickComparisonField={this.onClickComparisonField.bind(this)}
          onClickNewTmLead={this.onClickNewTmLead.bind(this)}
          onClickClaimQueue={this.onClickClaimQueue.bind(this)}
          onClickNewDmLead={this.onClickNewDmLead.bind(this)}
          onClickImportMailer={this.onClickImportMailer.bind(this)}
          onClickEditProfile={this.onClickEditProfile.bind(this)}
          onClickCreateUser={this.onClickCreateUser.bind(this)}
          onClickReport={this.onClickReports.bind(this)}
          handleSignOutState={this.props.handleSignOutState}
          setNeedsToBeSaved={this.setNeedsToBeSaved.bind(this)}
          needsToBeSaved={this.state.needsToBeSaved}
          queueCount={this.state.claimLeadsQueue.length}
        />

        <LeadDashboardContent
          navbarState={this.state}
          data={this.props.data}
          user={this.props.data.user}
          onClickLeadDashboard={this.setLeadDashboard.bind(this)}
          onClickDMLeadDashboard={this.onClickDMLeadsDashboard.bind(this)}
          onClickNewTmLead={this.onClickNewTmLead.bind(this)}
          setNeedsToBeSaved={this.setNeedsToBeSaved.bind(this)}
          needsToBeSaved={this.state.needsToBeSaved}
          showLead={this.state.showLead}
          showDMLead={this.state.showDMLead}
          handleShowLead={this.handleShowLead.bind(this)}
          handleShowDMLead={this.handleShowDMLead.bind(this)}
          updateUser={this.props.updateUser}
          setOpenConfirmation={this.setOpenConfirmation.bind(this)}
          claimLeadsQueue={this.state.claimLeadsQueue}
          getQueueLeads={this.getQueueLeads}
        />
      </div>
    );
  }
}
