import React, { Component } from "react";

export default class DangerAlert extends Component {
  render() {
    if (this.props.data) {
      if (this.props.data == "") {
        return <div></div>;
      } else {
        return (
          <div>
            <div className="alert alert-success mt-1" role="alert">
              {this.props.data}
            </div>
          </div>
        );
      }
    } else return <div></div>;
  }
}
