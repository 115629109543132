import React, { useState, useEffect } from "react";
import axios from "axios";
import employmentTest from "./employementTest";
import determineIfCashOutNeeded from './determineIfCashOutNeeded'
import calcFhaRateTermEligibility from "./calcFhaRateTermEligibility";

export const FhaRateTerm = ({lead}) => {
    const [fhaFICO, setFhaFICO] = useState('');
    const [jumboThreshold, setJumboThreshold] = useState('');
    const [fhaBase, setFhaBase] = useState('');
    const [maxFHAConvRTLTV, setMaxFHAConvRTLTV] = useState('');
    const [fhaRateTermStatus, setFhaRateTermStatus] = useState({ classStr: '', message: '' });

    useEffect(() => {
        axios.get("backend/CompField").then((res) => {
            if (res.data && res.data[0]) {
                setFhaFICO(res.data[0].fhaFICO);
                setJumboThreshold(res.data[0].jumboThreshold);
                setFhaBase(res.data[0].vaBaseRate + 0.125);
                setMaxFHAConvRTLTV(res.data[0].maxFHAConvRTLTV);
            }
        });
    }, [])

    useEffect(() => {
        const status = calcFhaRateTermEligibility(lead, fhaFICO, jumboThreshold, fhaBase, maxFHAConvRTLTV)
        if (status.classStr && status.message) {
            setFhaRateTermStatus(status)
        }
    }, [lead, fhaFICO, jumboThreshold, fhaBase, maxFHAConvRTLTV])

    const requiredFields = lead.creditScore !== "" && lead.state !== "" && lead.interestRate !== "" && lead.hasMortgageInsurance !== "" &&
    lead.loanBalance !== "" && lead.loanBalance !== null && lead.estimatedValue !== "" && lead.loanBalance !== null &&
    lead.mortgageDate !== "" && determineIfCashOutNeeded(lead.hasPrevCashOut, lead.state) && employmentTest(lead.employment, lead.hasCoBorrower) !== "";

    return (
        <tr>
            <td>FHA Rate/Term</td>
            <td>
                {
                    requiredFields ? (
                        <div id="fhaRateTermResponse" className={fhaRateTermStatus.classStr}>
                            {fhaRateTermStatus.message}
                        </div>
                    ) : <div id="fhaRateTermResponse"></div>
                }
            </td>
        </tr>
    )
}
