import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import { YlopoDataFields } from "./leadMidSectionComponents/ylopoDataFields";
import axios from "axios";

export const ClaimLeadModal = (props) => {
  const onClickClaimLead = () => {
    let reqJson = {
      lead: props.lead,
      user: props.user,
    };
    console.log("🚀 ~ file: claimLeadModal.js ~ line 13 ~ onClickClaimLead ~ reqJson", reqJson)

    axios
      .post("/backend/activeleads/ClaimQueue", reqJson)
      .then((resp) => {
      console.log("🚀 ~ file: claimLeadModal.js ~ line 17 ~ .then ~ resp", resp)
      

        props.setShowLead(resp.data);
        props.onClickLeadDashboard(true);
        props.setShow(false)
        props.getQueueLeads()
      })
      .catch((err) => {
      console.log("🚀 ~ file: claimLeadModal.js ~ line 26 ~ onClickClaimLead ~ err", err)

        alert(err.response.data)
          props.getQueueLeads()
      });
  };
  return (
    <Modal show={props.show}>
      <Modal.Header>
        <h4>CLaim Lead</h4>
        <button
          className="btn btn-danger p-1"
          onClick={() => props.setShow(false)}
        >
          <AiOutlineClose />
        </button>
      </Modal.Header>
      <Modal.Body>
        <YlopoDataFields lead={props.lead} />
        <div className="row mt-2 justify-content-center">
          <button className="btn btn-primary" onClick={onClickClaimLead}>
            Claim Lead
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
