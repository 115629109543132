import React from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';

export const ConfirmTelemarketerChange = (props) => {
    const updateTm = () => {
        axios
            .post('/backend/activeLeads/updateTM', {
                leadId: props.leadId,
                tmId: props.selectedTM._id,
                tm:
                    props.selectedTM.firstName +
                    ' ' +
                    props.selectedTM.lastName,
            })
            .then((resp) => {
                props.setTm(
                    props.selectedTM.firstName + ' ' + props.selectedTM.lastName
                );
                props.setTmId(props.selectedTM._id);
                console.log(resp.data);
            })
            .catch((err) => {});
    };

    return (
        <Modal show={props.show}>
            <Modal.Body>
                <h5>
                    Are you sure you want to reassign the lead to{' '}
                    {props.selectedTM ? props.selectedTM.firstName : ''}?
                </h5>

                <button
                    className="btn btn-primary mr-1"
                    onClick={() => {
                        updateTm();
                        props.setShow(false);
                    }}
                >
                    Yes
                </button>
                <button
                    className="btn btn-secondary"
                    onClick={() => props.setShow(false)}
                >
                    No
                </button>
            </Modal.Body>
        </Modal>
    );
};
