import React, { Component } from "react";
import Modal from "react-modal";

export default class SavingModal extends Component {
  render() {
    if (this.props.openConfirmation) {
      return (
        <Modal
          isOpen={this.props.openConfirmation}
          ariaHideApp={false}
          disableAutoFocus={true}
          className="modal-wrapper"
        >
          <div className="modal-header"></div>
          <div className="modal-body">
            <p>Are you sure you want to close the lead without saving?</p>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-primary"
              onClick={this.props.continueWithoutSaving}
            >
              Yes
            </button>
            <button
              className="btn btn-primary ml-4"
              onClick={() => this.props.setOpenConfirmation(false)}
            >
              No
            </button>
          </div>
        </Modal>
      );
    } else return <div></div>;
  }
}
