import React, { Component } from "react";

import "react-datepicker/dist/react-datepicker.css";
import CoBorrower from "./interviewQuestions/coBorrower";
import LoanBalanceInterviewQuestion from "./interviewQuestions/loanBalanceInterviewQuestion";
import EstimatedValueInterviewQuestion from "./interviewQuestions/estimatedValueInterviewQuestion";
import InterestRateInterviewQuestion from "./interviewQuestions/interestRateInterviewQuestion";
import FixedAdjustableInterviewQuestion from "./interviewQuestions/fixedAdjustbaleInterviewQuestion";
import MortgageInsuranceInterviewQuestion from "./interviewQuestions/mortgageInsuranceInterviewQuestion";
import PrevCashoutInterviewQuestion from "./interviewQuestions/prevCashoutInterviewQuestion";
import VeteranInterviewQuestion from "./interviewQuestions/veteranInterviewQuestion";
import OccupancyInterviewQuestion from "./interviewQuestions/occupancyInterviewQuestion";
import CreditInquiryInterviewQuestion from "./interviewQuestions/creditInquiryInterviewQuestion";

export default class InterviewQuestions extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="col">
        <div className="mt-2 ">
          <table className="table table-striped table-sm table-bordered shadow">
            <thead>
              <tr className="table-active">
                <td colSpan="2" className="text-center font-weight-bold">
                  Interview Questions
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Primary Goal</td>
                <td>
                  <div className="input-group-sm">
                    <select
                      className="form-control col"
                      value={this.props.lead.primaryGoal}
                      onChange={(e) => {
                        this.props.setNeedsToBeSaved(true);
                        this.props.onChangePrimaryGoal(e.target.value);
                      }}
                    >
                      <option value=""></option>
                      <option value="Purchase">Purchase </option>
                      <option value="Lower Payment">Lower Payment</option>
                      <option value="Cash Out">Cash Out </option>
                      <option value="Renovation">Renovation </option>
                      <option value="Other">Other </option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Credit Inquiry Date</td>
                <td>
                  <CreditInquiryInterviewQuestion lead={this.props.lead} />
                </td>
              </tr>
              <tr>
                <td>Credit Score</td>
                <td>
                  <div className="input-group-sm">
                    <input
                      type="number"
                      className="form-control "
                      value={this.props.lead.creditScore}
                      onChange={(e) => {
                        this.props.setNeedsToBeSaved(true);
                        this.props.onChangeCreditScore(e.target.value);
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Loan Balance</td>
                <td>
                  <LoanBalanceInterviewQuestion
                    lead={this.props.lead}
                    onChangeLoanBalance={this.props.onChangeLoanBalance}
                    setNeedsToBeSaved={this.props.setNeedsToBeSaved}
                  />
                </td>
              </tr>
              <tr>
                <td>Estimated Value</td>
                <td>
                  <EstimatedValueInterviewQuestion
                    lead={this.props.lead}
                    onChangeEstimatedValue={this.props.onChangeEstimatedValue}
                    setNeedsToBeSaved={this.props.setNeedsToBeSaved}
                  />
                </td>
              </tr>
              <tr>
                <td>Interest Rate</td>
                <td>
                  <InterestRateInterviewQuestion
                    lead={this.props.lead}
                    onChangeInterestRate={this.props.onChangeInterestRate}
                    setNeedsToBeSaved={this.props.setNeedsToBeSaved}
                  />
                </td>
              </tr>
              <tr>
                <td>Fixed/Adjustable</td>
                <td>
                  <FixedAdjustableInterviewQuestion
                    lead={this.props.lead}
                    onChangeIsFixed={this.props.onChangeIsFixed}
                    setNeedsToBeSaved={this.props.setNeedsToBeSaved}
                  />
                </td>
              </tr>
              <tr>
                <td>Mortgage Insurance?</td>
                <td>
                  {" "}
                  <MortgageInsuranceInterviewQuestion
                    lead={this.props.lead}
                    onChangeHasMortgageInsurance={
                      this.props.onChangeHasMortgageInsurance
                    }
                    setNeedsToBeSaved={this.props.setNeedsToBeSaved}
                  />
                </td>
              </tr>
              <tr>
                <td>Veteran?</td>
                <td>
                  <VeteranInterviewQuestion
                    lead={this.props.lead}
                    onChangeIsVeteran={this.props.onChangeIsVeteran}
                    setNeedsToBeSaved={this.props.setNeedsToBeSaved}
                  />
                </td>
              </tr>
              <tr>
                <td>Occupancy?</td>
                <td>
                  <OccupancyInterviewQuestion
                    lead={this.props.lead}
                    onChangeOccupancy={this.props.onChangeOccupancy}
                    setNeedsToBeSaved={this.props.setNeedsToBeSaved}
                  />
                </td>
              </tr>
              <tr>
                <td>Employment</td>
                <td>
                  <div className="input-group-sm">
                    <select
                      className="form-control input-group col"
                      value={this.props.lead.employment}
                      onChange={(e) => {
                        this.props.setNeedsToBeSaved(true);
                        this.props.onChangeEmployment(e.target.value);
                      }}
                    >
                      <option value=""></option>
                      <option value="Employed">Employed </option>
                      <option value="Retired">Retired</option>
                      <option value="Disabled">Disabled</option>
                      <option value="Unemployed">Unemployed</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Co-borrower</td>
                <td>
                  <CoBorrower
                    lead={this.props.lead}
                    onChangeHasCoBorrower={(e) => {
                      this.props.setNeedsToBeSaved(true);
                      this.props.onChangeHasCoBorrower(e.target.value);
                    }}
                    setNeedsToBeSaved={this.props.setNeedsToBeSaved}
                  />
                </td>
              </tr>
              <tr>
                <td>Previous Cash Out?</td>
                <td>
                  <PrevCashoutInterviewQuestion
                    lead={this.props.lead}
                    onChangeHasPreviousCashOut={(e) => {
                      this.props.setNeedsToBeSaved(true);
                      this.props.onChangeHasPreviousCashOut(e.target.value);
                    }}
                    setNeedsToBeSaved={this.props.setNeedsToBeSaved}
                  />
                </td>
              </tr>
              <tr>
                <td className="bg-warning">
                  <strong>Insurance Interest?</strong>
                </td>
                <td className="bg-warning">
                  <div className="input-group-sm">
                    <select
                      className="form-control input-group col"
                      value={this.props.lead.insuranceInterest}
                      onChange={(e) =>{
                        this.props.onChangeInsuranceInterest(e.target.value)
                        this.props.setNeedsToBeSaved(true)
                      }
              
                      }
                    >
                      <option value=""></option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="">
                  <strong>Spanish Speaker?</strong>
                </td>
                <td className="">
                  <div className="input-group-sm">
                    <select
                      className="form-control input-group col"
                      value={this.props.lead.isSpanishSpeaker}
                      onChange={(e) =>{
                        this.props.onChangeIsSpanishSpeaker(e.target.value)
                        this.props.setNeedsToBeSaved(true)
                      }

                      }
                    >
                      <option value=""></option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
