import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import { UpdatePassword } from "./updatePassword";
import { DeactiveUserModal } from "./deactiveUserModal";
import DangerAlert from "./dangerAlert";
import axios from "axios";
import { PermissionModal } from "./permissionModal";

export const EditUserModal = (props) => {
  const [resetPassword, setResetPassword] = useState(false);
  const [deactiveUserModal, setDeactivateUserModal] = useState(false);

  const [firstName, setFirstName] = useState(
    props.editUser != undefined ? props.editUser.firstName : ""
  );
  const [lastName, setLastName] = useState(
    props.editUser != undefined ? props.editUser.lastName : ""
  );
  const [
    , setFullName] = useState(
    props.editUser != undefined ? props.editUser.fullName : ""
  );
  const [email, setEmail] = useState(
    props.editUser != undefined ? props.editUser.email : ""
  );
  const [userType, setUserType] = useState(
    props.editUser != undefined ? props.editUser.userType : ""
  );

  const [error, setError] = useState("");
  const [resetPasswordError, setResetPasswordError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showBuildPermissions, setShowBuildPermissions] = useState(false);

  //sends data to server for updating in database. checks if
  //first name and last name are not blank as well as if the email address
  // is a contour email as well as userType are not blank
  const onClickUpdateUser = () => {
    if (firstName === "") {
      setError("You must include a first name");
    } else if (lastName === "") {
      setError("You must include a last name");
    } else if (!email.includes("@contourmtg.com")) {
      setError("Email must be a contour email address");
    } else if (userType == "") {
      setError("Must select a User Type");
    } else {
      const reqJson = {
        firstName: firstName,
        lastName: lastName,
        fullName: firstName + " " + lastName,
        email: email,
        userType: userType,
        userId: props.editUser._id,
        crmNavPermissions: props.crmNavPermissions,
        matrixNavPermissions: props.matrixNavPermissions,
        matrixLeadPermissions: props.matrixLeadPermissions,
        crmLeadPermissions: props.crmLeadPermissions,
        branchPermissions: props.branchPermissions,
        sitePermissions: props.sitePermissions
      };

      console.log(reqJson);

      axios
        .post("backend/user/updateUserBasic", reqJson)
        .then((resp) => {
          console.log(resp.data);
          props.setShow(false);
          // props.store.addNotification({
          //   title: "User Updated",
          //   message: resp.data.name + "'s Information has been updated",
          //   type: "info",
          //   insert: "top",
          //   container: "top-center",
          //   animationIn: ["animate__animated", "animate__fadeIn"],
          //   animationOut: ["animate__animated", "animate__fadeOut"],
          //   dismiss: {
          //     duration: 4000,
          //     click: true,
          //     showIcon: true,
          //   },
          // });
          alert(reqJson.fullName + "\'s profile updated")
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  };

  //resets error when data is inputted
  useEffect(() => {
    setError("");
  }, [firstName, lastName, email, userType]);

  //loads in data from newly selected uer
  useEffect(() => {
    setFirstName(props.editUser != undefined ? props.editUser.firstName : "");
    setLastName(props.editUser != undefined ? props.editUser.lastName : "");
    setEmail(props.editUser != undefined ? props.editUser.email : "");
    setUserType(props.editUser != undefined ? props.editUser.userType : "");

    return () => {
      setFirstName("");
      setLastName("");
      setEmail("");
      setUserType("");
    };
  }, [props.editUser]);

  const onClickUpdateUserPassword = () => {
    if (password === "") {
      setResetPasswordError("Please enter a password.");
    } else if (password !== confirmPassword) {
      setResetPasswordError("Password must match.");
    } else {
      const reqJson = {
        userId: props.editUser._id,
        password: password,
        fullName: firstName + " " + lastName,
      };

      axios.post("/backend/user/updateUserPassword", reqJson).then((resp) => {
        setPassword("");
        setConfirmPassword("");
        props.setShow(false);
        alert(reqJson.fullName+"\'s Password Updated")
        // props.store.addNotification({
        //   title: "User Updated",
        //   message: resp.data.name + "'s password has been updated",
        //   type: "info",
        //   insert: "top",
        //   container: "top-center",
        //   animationIn: ["animate__animated", "animate__fadeIn"],
        //   animationOut: ["animate__animated", "animate__fadeOut"],
        //   dismiss: {
        //     duration: 4000,
        //     click: true,
        //     showIcon: true,
        //   },
        // });
      });
    }
  };

  useEffect(() => {
    setResetPasswordError("");
  }, [password, confirmPassword]);

  //sets isActive property to false in the database
  const onClickDeactivateUser = () => {
    const reqJson = {
      userId: props.editUser._id,
      fullName:  firstName + " " + lastName
    };

    axios.post("/backend/user/deactivateUser", reqJson).then((resp) => {
      setDeactivateUserModal(false);
      props.setShow(false);
      // props.store.addNotification({
      //   title: "User DeactivatereqJsond",
      //   message: resp.data.name + "'s account has been deactivated",
      //   type: "danger",
      //   insert: "top",
      //   container: "top-center",
      //   animationIn: ["animate__animated", "animate__fadeIn"],
      //   animationOut: ["animate__animated", "animate__fadeOut"],
      //   dismiss: {
      //     duration: 4000,
      //     click: true,
      //     showIcon: true,
      //   },
      // });

      alert(reqJson.fullName + "\'s Deactivated")
    });
  };
  return (
    <Modal show={props.show}>
      <Modal.Header>
        <h4>Edit User</h4>
        <button className="btn btn-danger" onClick={() => props.setShow(false)}>
          <AiOutlineClose />
        </button>
      </Modal.Header>
      <Modal.Body>
        <DangerAlert data={error} />
        <PermissionModal 
          setShowBuildPermissions={setShowBuildPermissions} 
          showBuildPermissions={showBuildPermissions}
          crmNavPermissions={props.crmNavPermissions}
          setCrmNavPermissions={props.setCrmNavPermissions}
          matrixNavPermissions={props.matrixNavPermissions}
          setMatrixNavPermissions={props.setMatrixNavPermissions}
          matrixLeadPermissions={props.matrixLeadPermissions}
          setMatrixLeadPermissions={props.setMatrixLeadPermissions}
          crmLeadPermissions={props.crmLeadPermissions}
          setCrmLeadPermissions={props.setCrmLeadPermissions}
          branchPermissions={props.branchPermissions}
          setBranchPermissions={props.setBranchPermissions}
          sitePermissions={props.sitePermissions}
          setSitePermissions={props.setSitePermissions}
          branchCodes={props.branchCodes}
        />
        <div className="row justify-content-center">
          <div className="col-3 text-center">
            <label> First Name</label>
          </div>
          <div className="col-4">
            <input
              className="ml-1 form-control input-small pl-1"
              value={props.editUser != undefined ? firstName : ""}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
        </div>

        <div className="row mt-1 justify-content-center">
          <div className="col-3 text-center">
            <label> Last Name</label>
          </div>
          <div className="col-4">
            <input
              className="ml-1 form-control input-small pl-1"
              value={props.editUser != undefined ? lastName : ""}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>

        <div className="row mt-1 justify-content-center">
          <div className="col-3 text-center">
            <label> Email</label>
          </div>
          <div className="col-4">
            <input
              className="ml-1 form-control input-small pl-1 "
              value={props.editUser != undefined ? email : ""}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="row mt-1 justify-content-center">
          <div className="col-3 text-center">
            <label>User Type</label>
          </div>
          <div className="col-4">
            <select
              className="form-control input-small pl-1"
              select={props.editUser != undefined ? userType : ""}
              onChange={(e) => setUserType(e.target.value)}
            >
            <option value="TM">Telemarketer</option>
            <option value="Manager">Manager</option> 
            <option value="LO">Loan Officer</option>       
            <option value="Admin">Admin</option>
            </select>
          </div>
        </div>

        <div className="row mt-3 justify-content-center">
          <button className="btn btn-dark" onClick={() => setShowBuildPermissions(true)}>
            Permissions
          </button>
        </div>


        <div className="row mt-3 justify-content-center">
          <button className="btn btn-dark" onClick={onClickUpdateUser}>
            Update User
          </button>
        </div>

        <div className="row mt-3 border mr-1 ml-1 justify-content-center bg-light">
          <UpdatePassword
            show={resetPassword}
            password={password}
            confirmPassword={confirmPassword}
            setPassword={setPassword}
            setConfirmPassword={setConfirmPassword}
            onClickUpdateUserPassword={onClickUpdateUserPassword}
            error={resetPasswordError}
          />
        </div>

        <div className="row mt-2 justify-content-center">
          <button
            className="btn btn-danger"
            onClick={() => setDeactivateUserModal(!deactiveUserModal)}
          >
            Deactivate User
          </button>
        </div>

        <DeactiveUserModal
          show={deactiveUserModal}
          setShow={setDeactivateUserModal}
          user={props.editUser}
          onClickDeactivateUser={onClickDeactivateUser}
        />
      </Modal.Body>
    </Modal>
  );
};
