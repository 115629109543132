import React, { useState, useEffect } from 'react';
import axios from 'axios';
import calcLTV from '../../generalUseFunctions/calcLTV';
import calcContourCommunityLendingEligibility from './calcContourCommunityLendingEligibility';

export const ContourCommunityLending = ({lead}) => {
    const [jumboThreshold, setJumboThreshold] = useState("");
    const [eligible, setEligible] = useState({ classStr: '', message: '' });

    useEffect(() => {
        axios.get("/backend/CompField").then((resp) => {
            if (resp.data && resp.data[0]) {
                setJumboThreshold(resp.data[0].jumboThreshold);
            }
        });
    }, [])

    useEffect(() => {
        const ltv = calcLTV(lead?.loanBalance, lead?.estimatedValue);
        const eligibility = calcContourCommunityLendingEligibility(lead, ltv, jumboThreshold);
        setEligible(eligibility);
    }, [lead, jumboThreshold])

    return (
        <tr>
            <td>Contour Community Lending</td>
            <td className="results-td">
                {eligible && eligible.classStr !== undefined && eligible.message !== undefined ? (
                    <div id="contourCommunityLendingEligibilityResponse" className={eligible.classStr}>{eligible.message}</div>
                ) : <div></div>}
            </td>
        </tr>
    )
}