export default function calcIRRRLRate(VaBaseRate, JumboThreshold, creditScore, loanAmount){
    let rate = VaBaseRate +.5;

    if(loanAmount>(JumboThreshold-5000)){
        rate = rate-0;
    }
    if(creditScore<580){
        rate = rate+.125
    }

    if(creditScore<600){
        rate = rate +.625
    }


    if(loanAmount<150000){
        rate=rate+.5
    }
    return rate

}