export default function calcFhaStreamRate(
  fhaBase,
  creditScore,
  loanBalance,
  jumboThreshold
) {
  let rate = fhaBase + 1.25;

  if (creditScore >= 620) {
    rate = rate;
  } else {
    rate = rate + 0.875;
  }
  if (loanBalance > jumboThreshold - 5000) {
    rate = rate + 0.75;
  } else {
    rate = rate;
  }

  return rate;
}
