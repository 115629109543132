import React, { useState, useEffect } from "react";
import StateDropdown from "../generalUseComponents/state-dropdown";

export const YlopoCrmTransferInput = (props) => {
  useEffect(() => {
    if (props.lead != undefined && props.lead.campaignName == "YLOPO") {
      props.setters.setPrimaryGoal("Purchase");
    }
    return () => {};
  }, [props.lead]);
  return (
    <div>
     
     
      <div className="row contour-blue text-white text-center mt-1 border">
        <div className="col">CRM Transfer</div>
      </div>
      <div className="row border-right border-bottom border-left">
        <div className="col p-2 border-right">LoanType</div>
        <div className="col">
          {" "}
          <select
            className="form-control col  p-2"
            value={props.lead.loanType}
            onChange={(e) => props.setters.setLoanType(e.target.value)}
          >
            <option value=""></option>
            <option value="VA">VA </option>
            <option value="FH">FHA</option>
            <option value="CO">Conventional </option>
          </select>
        </div>
      </div>
      <div className="row border-right border-bottom border-left">
        <div className="col p-2 border-right">State</div>
        <div className="col">
          {" "}
          <StateDropdown
            state={props.lead.state}
            onChangeState={props.setters.setState}
          />
        </div>
      </div>

      <div className="row contour-blue text-white text-center mt-3 ">
        <div className="col"> Notes</div>
      </div>
      <div className="row">
        <textarea
          className="form-control textarea-control"
          value={props.lead.notes}
          onChange={(e) => props.setters.setNotes(e.target.value)}
        ></textarea>
      </div>
    </div>
  );
};
