import employmentTest from "./employementTest";

export default function calcRenovationEligibility (lead) {
    if (!lead) {
        return {
            classStr: '',
            message: '',
        }
    }

    const danger = 'font-weight-bold bg-danger text-white';
    const success = 'bg-success font-weight-bold text-white';

    if (employmentTest(lead.employment, lead.hasCoBorrower) === 'Fail') {
        return {
            classStr: danger,
            message: 'Employment Issue',
        }
    }

    if (lead.primaryGoal !== 'Renovation') {
        return {
            classStr: danger,
            message: 'Not looking to renovate',
        }
    }

    if (lead.isVeteran === 'Yes' && lead.creditScore >= 640) {
        return {
            classStr: success,
            message: 'Eligible',
        }
    }

    if (lead.creditScore < 600) {
        return {
            classStr: danger,
            message: 'FICO too low: 600',
        }
    }

    return {
        classStr: success,
        message: 'Eligible',
    }
}