import React, { Component } from "react";
import axios from "axios";
import Contact from "../leadTopSectionComponents/contact";
import DangerAlert from "../generalUseComponents/dangerAlert";

export default class CreateLead extends Component {
  constructor(props) {
    super(props);
    this.onSubmitCreateLead = this.onSubmitCreateLead.bind(this);
    this.state = {
      error: ""

    };
  }

  onSubmitCreateLead(e) {
    e.preventDefault();

    if (this.props?.handleMessage === "Standard create") {

      const newLead = {
        dbIdLookup: this.props.data.dbIdLookup,
        tm: this.props.user.firstName + " " + this.props.user.lastName,
        tmId: this.props.user._id
      };

      axios.post("backend/activeleads/add/tm", newLead).then((res) => {
        this.props.handleLeadCreated(newLead.dbIdLookup);
      });

      axios
        .get("backend/prospectleads/update/leadCreated/" + newLead.dbIdLookup)
        .then((res) => {
          if (res.n == 1) {
            console.log("lead created");
          }
        });
    }

    if (this.props?.handleMessage === "Reassign TM") {

      const updateTm = {
        leadId: this.props?.data?._id,
        tm: this.props.user.firstName + " " + this.props.user.lastName,
        tmId: this.props.user._id
      }

      axios.post("backend/activeleads/updateTm", updateTm).then((resp) => {
        this.props.handleLeadCreated(this.props?.data?.dbIdLookup)
      }).catch((error) => {
        this.setState({ error: error?.response?.data ?? "There was an error assigning this lead to you." })


        setTimeout(() => { this.setState({ error: "" }) }, 3000)
      })

    }
  }
  render() {
    return (
      <div>
        <DangerAlert data={this.state.error} />
        <div className="col-3 mt-3">
          <p>Reference ID: {this.props.data.dbIdLookup}</p>

          {this.props.campaignName == "BSYL" ? (
            <Contact
              lead={{
                ...this.props.data,
                phoneNumber: this.props.data.ylopoPhoneNumbers[0].value,
              }}
            />
          ) : (
            <Contact lead={this.props.data} />
          )}

          <button
            className="btn mp-3 col btn-primary"
            onClick={this.onSubmitCreateLead}
          >
            Create Lead
          </button>
        </div>
      </div>
    );
  }
}
