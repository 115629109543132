import React, { Component } from "react";
import InterestRateDropdown from "./interviewQuestions/interest-rate-dropdown";
export default class LoInsights extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="col">
        <div className="mt-2 mr-2">
          <table className="table table-striped table-sm table-bordered shadow">
            <thead>
              <tr className="table-active">
                <td colSpan="2" className="text-center font-weight-bold">
                  LO Insight
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Finance IQ</td>
                <td>
                  <select
                    className="form-control input-group col"
                    value={this.props.lead.FinanceIQ}
                    onChange={(e) => {
                      this.props.setNeedsToBeSaved(true);
                      this.props.onChangeFinanceIQ(e.target.value);
                    }}
                  >
                    <option value=""></option>
                    <option value="low">Low </option>
                    <option value="Medium">Medium</option>
                    <option value="High">High</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>Caller Attitude</td>
                <td>
                  <select
                    className="form-control input-group col"
                    value={this.props.lead.callerAtt}
                    onChange={(e) => {
                      this.props.setNeedsToBeSaved(true);
                      this.props.onChangeCallerAttitude(e.target.value);
                    }}
                  >
                    <option value=""></option>
                    <option value="EX">Excited/Eager / Interested </option>
                    <option value="ID">Indifferent / Skeptical</option>
                    <option value="AN">Annoyed / Bad Time</option>
                    <option value="SH">Short / Blunt / Rude</option>
                  </select>
                </td>
              </tr>

              <tr>
                <td>Competing Offer</td>
                <td>
                  <InterestRateDropdown
                    interest={this.props.lead.competingOffer}
                    onChangeInterestRate={this.props.onChangeCompetingOffer}
                    setNeedsToBeSaved={this.props.setNeedsToBeSaved}
                  />
                </td>
              </tr>
              <tr>
                <td>TM</td>
                <td>{this.props.lead.tm}</td>
              </tr>
              <tr>
                <td>Notes</td>
                <td>
                  <textarea
                    className="form-control textarea-control"
                    value={this.props.lead.notes}
                    onChange={(e) => {
                      this.props.setNeedsToBeSaved(true);
                      this.props.onChangeNotes(e.target.value);
                    }}
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
