import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import DangerAlert from "../../generalUseComponents/dangerAlert";

export const PermissionModal = (props) => {
    const [showOptions, setShowOptions] = useState("DEFAULT");
    const [error, setError] = useState("");

    const crmNavOptions = [
        { value: 'Select All', label: 'Select All' },
        { value: 'Dashboard', label: 'Dashboard' },
        { value: 'Reports', label: 'Reports' },
        { value: 'Upload Data', label: 'Upload Data' },
        { value: 'Edit Users', label: 'Edit Users' },
        { value: 'Campaigns', label: 'Campaigns' },
        { value: 'Edit Profile', label: 'Edit Profile' },
        { value: 'Texts', label: 'Texts' }
      ]
    
      const matrixNavOptions = [
        { value: 'Select All', label: 'Select All' },
        { value: 'TM Leads Dashboard', label: 'TM Leads Dashboard' },
        { value: 'Lead Queue', label: 'Lead Queue' },
        { value: 'New TM Lead', label: 'New TM Lead' },
        { value: 'Import Data', label: 'Import Data' },
        { value: 'Comparison Fields', label: 'Comparison Fields' },
        { value: 'Edit Users', label: 'Edit Users' }
      ]
    
      const crmLeadOptions = [
        { value: 'Select All', label: 'Select All' },
        { value: 'My Leads', label: 'My Leads' },
        { value: 'Other Leads', label: 'Other Leads' },
        { value: 'Reassign Leads', label: 'Reassign Leads' }
      ]

      const matrixLeadOptions = [
        { value: 'Select All', label: 'Select All' },
        { value: 'My Leads', label: 'My Leads' },
        { value: 'Other Leads', label: 'Other Leads' },
        { value: 'Reassign TM', label: 'Reassign TM' },
        { value: 'Reassign LO', label: 'Reassign LO' }
      ]
    
      const siteOptions = [
        { value: 'Select All', label: 'Select All' },
        { value: 'Matrix', label: 'Matrix' },
        { value: 'CRM', label: 'CRM' },
        { value: 'Reports', label: 'Reports' }
      ]

      const navItems = [
        { value: 'CRM', label: 'CRM' },
        { value: 'Matrix', label: 'Matrix' },
        { value: 'Branches', label: 'Branches' },
        { value: 'Site', label: 'Site' },
      ]

      const addCrmNavPermission = (param) => {
        if(param == "Select All"){
          props.setCrmNavPermissions(['Dashboard', 'Reports', 'Upload Data', 'Edit Users','Campaigns','Edit Profile', 'Texts','Select All']);
        }
        else{
          props.setCrmNavPermissions([...props.crmNavPermissions, param])
        }
     
      }

      const removeCrmNavPermission = (param) =>{
        if(param == "Select All"){
          props.setCrmNavPermissions([]);
        }
        else {
          var index = props.crmNavPermissions.indexOf(param)
            
          if (index !== -1) {
            props.crmNavPermissions.splice(index, 1);
            props.setCrmNavPermissions([...props.crmNavPermissions]);
          }
        } 
      }

      const addMatrixNavPermission = (param) => {
        if(param == "Select All"){
          props.setMatrixNavPermissions(['TM Leads Dashboard', 'Lead Queue', 'New TM Lead', 'Import Data','Comparison Fields','Edit Profile', 
            'Edit Users', 'Reports', 'Select All']);
        }
        else{
          props.setMatrixNavPermissions([...props.matrixNavPermissions, param])
        }
   
      }

      const removeMatrixNavPermission = (param) =>{
        if(param == "Select All"){
          props.setMatrixNavPermissions([]);
        }
        else {
          var index = props.matrixNavPermissions.indexOf(param)
            
          if (index !== -1) {
            props.matrixNavPermissions.splice(index, 1);
            props.setMatrixNavPermissions([...props.matrixNavPermissions]);
          }
        } 
      }

      const addMatrixLeadNavPermission = (param) => {
        if(param == "Select All"){
          props.setMatrixLeadPermissions(['My Leads', 'Other Leads', 'Select All']);
        }

        else{
          props.setMatrixLeadPermissions([...props.matrixLeadPermissions, param])
        }
   
      }

      const removeMatrixLeadNavPermission = (param) =>{
        if(param == "Select All"){
          props.setMatrixLeadPermissions([]);
        }
        else {
          var index = props.matrixLeadPermissions.indexOf(param)
            
          if (index !== -1) {
            props.matrixLeadPermissions.splice(index, 1);
            props.setMatrixLeadPermissions([...props.matrixLeadPermissions]);
          }
        } 
      }

      const addCrmLeadNavPermission = (param) => {
        if(param == "Select All"){
          props.setCrmLeadPermissions(['My Leads', 'Other Leads', 'Select All']);
        }

        else{
          props.setCrmLeadPermissions([...props.crmLeadPermissions, param])
        }
   
      }

      const removeCrmLeadNavPermission = (param) =>{
        if(param == "Select All"){
          props.setCrmLeadPermissions([]);
        }
        else {
          var index = props.crmLeadPermissions.indexOf(param)
            
          if (index !== -1) {
            props.crmLeadPermissions.splice(index, 1);
            props.setCrmLeadPermissions([...props.crmLeadPermissions]);
          }
        } 
      }

      const addSiteNavPermission = (param) => {
        if(param == "Select All"){
          props.setSitePermissions(['Matrix', 'CRM', 'Reports','Select All']);
        }

        else{
          props.setSitePermissions([...props.sitePermissions, param])
        }
   
      }

      const removeSiteNavPermission = (param) =>{
        if(param == "Select All"){
          props.setSitePermissions([]);
        }
        else{
          var index = props.sitePermissions.indexOf(param)
            
          if (index !== -1) {
            props.sitePermissions.splice(index, 1);
            props.setSitePermissions([...props.sitePermissions]);
          } 
        }
      }

      const addBranchNavPermission = (param) => {
        if(param == "Select All"){
          props.setBranchPermissions([...props.branchCodes,'Select All'])
        }
        else{
          props.setBranchPermissions([...props.branchPermissions, param])
        }
   
      }

      const removeBranchNavPermission = (param) =>{
        if(param == "Select All"){
          props.setBranchPermissions([]);
        }
        else{
          var index = props.branchPermissions.indexOf(param)
            
          if (index !== -1) {
            props.branchPermissions.splice(index, 1);
            props.setBranchPermissions([...props.branchPermissions]);
          } 
        }
      }

      let crmOptions = crmNavOptions.map((option, i) => {
        if (props.crmNavPermissions.includes(option.value)){
          return (
          <div>
            <a class="btn p1-2 pr-2 pt-1 pb-1 m-1 btn-default" onClick={() => removeCrmNavPermission(option.value)}>{option.value}</a>
          </div>)
        }
        else {
          return (
            <div>
              <a class="btn btn-light p1-2 pr-2 pt-1 pb-1 m-1" onClick={() => addCrmNavPermission(option.value) }>{option.value}</a>
            </div>)
        }
      });
        
      let matrixOptions = matrixNavOptions.map((option, i) => {
        if (props.matrixNavPermissions.includes(option.value)){
          return (
          <div>
            <button class="btn p1-2 pr-2 pt-1 pb-1 m-1 btn-default" onClick={() => removeMatrixNavPermission(option.value)}>{option.value}</button>
          </div>)
        }
        else {
          return (
            <div>
              <button class="btn btn-light p1-2 pr-2 pt-1 pb-1 m-1" onClick={() => addMatrixNavPermission(option.value)}>{option.value}</button>
            </div>)
        }
      });

      let matrixLeadNavOptions = matrixLeadOptions.map((option, i) => {
        if (props.matrixLeadPermissions.includes(option.value)){
          return (
          <div>
            <button class="btn p1-2 pr-2 pt-1 pb-1 m-1 btn-default" onClick={() => removeMatrixLeadNavPermission(option.value)}>{option.value}</button>
          </div>)
        }
        else {
          return (
            <div>
              <button class="btn btn-light p1-2 pr-2 pt-1 pb-1 m-1" onClick={() => addMatrixLeadNavPermission(option.value)}>{option.value}</button>
            </div>)
        }
      });

      let crmLeadNavOptions = crmLeadOptions.map((option, i) => {
        if (props.crmLeadPermissions.includes(option.value)){
          return (
          <div>
            <button class="btn p1-2 pr-2 pt-1 pb-1 m-1 btn-default" onClick={() => removeCrmLeadNavPermission(option.value)}>{option.value}</button>
          </div>)
        }
        else {
          return (
            <div>
              <button class="btn btn-light p1-2 pr-2 pt-1 pb-1 m-1" onClick={() => addCrmLeadNavPermission(option.value)}>{option.value}</button>
            </div>)
        }
      });

      const branchOptionItems = props.branchCodes.map((branch, i)=>{
        return (
            {value: branch, label: branch}
        )
      })

      let branchNavOptions = branchOptionItems.map((option, i) => {
        if (props.branchPermissions.includes(option.value)){
          return (
          <div>
            <button class="btn p1-2 pr-2 pt-1 pb-1 m-1 btn-default" onClick={() => removeBranchNavPermission(option.value)}>{option.value}</button>
          </div>)
        }
        else {
          return (
            <div>
              <button class="btn btn-light p1-2 pr-2 pt-1 pb-1 m-1" onClick={() => addBranchNavPermission(option.value)}>{option.value}</button>
            </div>)
        }
      });

      let siteNavOptions = siteOptions.map((option, i) => {
        if (props.sitePermissions.includes(option.value)){
          return (
          <div>
            <button class="btn p1-2 pr-2 pt-1 pb-1 m-1 btn-default" onClick={() => removeSiteNavPermission(option.value)}>{option.value}</button>
          </div>)
        }
        else {
          return (
            <div>
              <button class="btn btn-light p1-2 pr-2 pt-1 pb-1 m-1" onClick={() => addSiteNavPermission(option.value)}>{option.value}</button>
            </div>)
        }
      });

      let navList = navItems.map((option, i) => {
        if(showOptions == "DEFAULT" && option.value == "CRM"){
          return (
            <button className="btn p1-2 pr-2 pt-1 pb-1 m-1 btn-focus btn-default" onClick={() => setShowOptions(option.value)}>{option.value}</button>
          )
        }
        else {
          return (
            <button className="btn btn-color-change p1-2 pr-2 pt-1 pb-1 m-1 btn-focus" onClick={() => setShowOptions(option.value)}>{option.value}</button>
          )
        }
      })


    return (
        <Modal size="lg" show={props.showBuildPermissions}>
            <Modal.Header>
                <h4>Permissions</h4>
                <button className="btn btn-danger" onClick={() => props.setShowBuildPermissions(false)}>
                <AiOutlineClose />
                </button>
            </Modal.Header>
            <Modal.Body>
            <DangerAlert data={error} />
            <div className="row text-left">
              {navList}
            </div>
            { showOptions == "DEFAULT" && (
              <div>
                <div className="row text-left">
                  <h4 className="btn btn-default p1-2 pr-2 pt-1 pb-1 m-1 btn-focus">Nav</h4>
                </div>
              <div className="row text-left">
                {crmOptions}
              </div>
              <div className="row text-left">
                  <h4 className="btn btn-default p1-2 pr-2 pt-1 pb-1 m-1 btn-focus">Leads</h4>
              </div>
              <div className="row text-left">
                {crmLeadNavOptions}
              </div>
              </div>
            )}
            { showOptions == "CRM" && (
              <div>
                <div className="row text-left">
                  <h4 className="btn btn-default p1-2 pr-2 pt-1 pb-1 m-1">Nav</h4>
                </div>
              <div className="row text-left">
                {crmOptions}
              </div>
              <div className="row text-left">
                  <h4 className="btn btn-default p1-2 pr-2 pt-1 pb-1 m-1">Leads</h4>
              </div>
              <div className="row text-left">
                {crmLeadNavOptions}
              </div>
              </div>
            )}

            { showOptions == "Matrix" && (
              <div>
              <div className="row text-left">
                <h4 className="btn btn-default p1-2 pr-2 pt-1 pb-1 m-1">Nav</h4>
              </div>
              <div className="row text-left">
                {matrixOptions}
              </div>
              <div className="row text-left">
                  <h4 className="btn btn-default p1-2 pr-2 pt-1 pb-1 m-1">Leads</h4>
              </div>
              <div className="row text-left">
                {matrixLeadNavOptions}
              </div>
              </div>
            )}

            { showOptions == "Branches" && (
              <div className="row text-left">
                {branchNavOptions}
              </div>
            )}

            { showOptions == "Site" && (
              <div className="row text-left">
                {siteNavOptions}
              </div>
            )}

            <div className="text-center">
              <button className="mt-2 btn btn-secondary col-1 btn-default"  onClick={() => props.setShowBuildPermissions(false)}>
                {" "}
                OK
              </button>
            </div>  
            </Modal.Body>
        </Modal>

    );
};
