import React, { Component } from "react";
import LeadsDashboardRow from "./lead-dashboard-row";
import PageNav from "../generalUseComponents/page-nav";
import { SortButton } from '../../sortButton';

export default class LeadsDashboardTable extends Component {
  
  constructor(props) {
    super(props);

  }
  componentDidMount() {}

  render() {
    if (this.props.leads ) {
      if (
        this.props.user.matrixLeadPermissions.includes("Other Leads")
      ) {
        if (this.props.isSearchResults) {
          return (
            <div>
              <h5>Matrix found {this.props.searchNumberOfLeads} results</h5>

              <div className="mt-3">
                <table className="table table-bordered">
                  <thead>
                    <tr className="table-active">
                      <th>Vendor Lead Code</th>
                      <th className="sortHeaderCell" onClick={() => { this.props.setSortOptions("leadCreated") }}>
                        Lead Created Date
                        { this.props.sortBy === "leadCreated" && <SortButton sortType={this.props.sortType} /> }
                      </th>
                      <th>Lead Transferred Date</th>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Lead Status</th>
                      <th>Reason</th>
                      <th>Milestone</th>
                      <th>TM Name</th>
                      <th>Loan Officer</th>
                      <th className="sortHeaderCell" onClick={() => { this.props.setSortOptions("callBackLaterDate") }}>
                        Call back date
                        { this.props.sortBy === "callBackLaterDate" && <SortButton sortType={this.props.sortType} /> }
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <LeadsDashboardRow
                    user={this.props.user}
                    data={this.props.searchLeads}
                    loanOfficers={this.props.LO}
                    setLead={this.props.setLead}
                  />
                </table>
                <div className="container">
                  <PageNav
                    onClickNext={this.props.onClickSearchNext}
                    onClickPrevious={this.props.onClickSearchPrevious}
                    pre={this.props.searchPrePage}
                    next={this.props.searchNextPage}
                    current={this.props.searchCurrentPage}
                    last={this.props.searchLastPage}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div>
              <h5>There are a total of {this.props.numberOfLeads} leads</h5>

              <div className="mt-3">
                <table className="table table-bordered">
                  <thead>
                    <tr className="table-active">
                      <th>Vendor Lead Code</th>
                      <th className="sortHeaderCell" onClick={() => { this.props.setSortOptions("leadCreated") }}>
                        Lead Created Date
                        { this.props.sortBy === "leadCreated" && <SortButton sortType={this.props.sortType} /> }
                      </th>
                      <th>Lead Transferred Date</th>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Lead Status</th>
                      <th>Reason</th>
                      <th>Milestone</th>
                      <th>TM Name</th>
                      <th>Loan Officer</th>
                      <th className="sortHeaderCell" onClick={() => { this.props.setSortOptions("callBackLaterDate") }}>
                        Call back date
                        { this.props.sortBy === "callBackLaterDate" && <SortButton sortType={this.props.sortType} /> }
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <LeadsDashboardRow
                    user={this.props.user}
                    data={this.props.leads}
                    loanOfficers={this.props.LO}
                    setLead={this.props.setLead}
                  />
                </table>
                <div className="container">
                  <PageNav
                    onClickNext={this.props.onClickNext}
                    onClickPrevious={this.props.onClickPrevious}
                    pre={this.props.prePage}
                    next={this.props.nextPage}
                    current={this.props.currentPage}
                    last={this.props.lastPage}
                  />
                </div>
              </div>
            </div>
          );
        }
      } else if (!this.props.user.matrixLeadPermissions.includes("Other Leads")) {
        if (this.props.isSearchResults) {
          return (
            <div>
              <h5>Matrix found {this.props.searchNumberOfLeads} results</h5>

              <div className="mt-3">
                <table className="table table-bordered">
                  <thead>
                    <tr className="table-active">
                      <th>Vendor Lead Code</th>
                      <th className="sortHeaderCell" onClick={() => { this.props.setSortOptions("leadCreated") }}>
                        Lead Created Date
                        { this.props.sortBy === "leadCreated" && <SortButton sortType={this.props.sortType} /> }
                      </th>
                      <th>Lead Transferred Date</th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>Lead Status</th>
                      <th>Milestone</th>
                      <th>TM Name</th>
                      <th>Loan Officer</th>
                      <th className="sortHeaderCell" onClick={() => { this.props.setSortOptions("callBackLaterDate") }}>
                        Call back date
                        { this.props.sortBy === "callBackLaterDate" && <SortButton sortType={this.props.sortType} /> }
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <LeadsDashboardRow
                    user={this.props.user}
                    data={this.props.searchLeads}
                    loanOfficers={this.props.LO}
                    setLead={this.props.setLead}
                  />
                </table>
                <div className="container">
                  <PageNav
                    onClickNext={this.props.onClickSearchNext}
                    onClickPrevious={this.props.onClickSearchPrevious}
                    pre={this.props.searchPrePage}
                    next={this.props.searchNextPage}
                    current={this.props.searchCurrentPage}
                    last={this.props.searchLastPage}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div>
              <h5>You have {this.props.numberOfLeads} leads</h5>

              <div className="mt-3">
                <table className="table table-bordered">
                  <thead>
                    <tr className="table-active">
                      <th>Vendor Lead Code</th>
                      <th className="sortHeaderCell" onClick={() => { this.props.setSortOptions("leadCreated") }}>
                        Lead Created Date
                        { this.props.sortBy === "leadCreated" && <SortButton sortType={this.props.sortType} /> }
                      </th>
                      <th>Lead Transferred Date</th>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Lead Status</th>
                      <th>Milestone</th>
                      <th>TM Name</th>
                      <th>Loan Officer</th>
                      <th className="sortHeaderCell" onClick={() => { this.props.setSortOptions("callBackLaterDate") }}>
                        Call back date
                        { this.props.sortBy === "callBackLaterDate" && <SortButton sortType={this.props.sortType} /> }
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <LeadsDashboardRow
                    user={this.props.user}
                    data={this.props.leads}
                    loanOfficers={this.props.LO}
                    setLead={this.props.setLead}
                  />
                </table>
                <div className="container">
                  <PageNav
                    onClickNext={this.props.onClickNext}
                    onClickPrevious={this.props.onClickPrevious}
                    pre={this.props.prePage}
                    next={this.props.nextPage}
                    current={this.props.currentPage}
                    last={this.props.lastPage}
                  />
                </div>
              </div>
            </div>
          );
        }
      } else {
        if (this.props.isSearchResults) {
          return (
            <div>
              <h5>Matrix found {this.props.searchNumberOfLeads} results</h5>

              <div className="mt-3">
                <table className="table table-bordered">
                  <thead>
                    <tr className="table-active">
                    <th>Vendor Lead Code</th>
                    <th className="sortHeaderCell" onClick={() => { this.props.setSortOptions("leadCreated") }}>
                        Lead Created Date
                        { this.props.sortBy === "leadCreated" && <SortButton sortType={this.props.sortType} /> }
                      </th>
                    <th>Lead Transferred Date</th>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Milestone</th>
                    <th>TM Name</th>
                    <th className="sortHeaderCell" onClick={() => { this.props.setSortOptions("callBackLaterDate") }}>
                        Call back date
                        { this.props.sortBy === "callBackLaterDate" && <SortButton sortType={this.props.sortType} /> }
                    </th>
                    <th>Actions</th>
                    </tr>
                  </thead>

                  <LeadsDashboardRow
                    user={this.props.user}
                    data={this.props.searchLeads}
                    loanOfficers={this.props.LO}
                    setLead={this.props.setLead}
                  />
                </table>
                <div className="container">
                  <PageNav
                    onClickNext={this.props.onClickSearchNext}
                    onClickPrevious={this.props.onClickSearchPrevious}
                    pre={this.props.searchPrePage}
                    next={this.props.searchNextPage}
                    current={this.props.searchCurrentPage}
                    last={this.props.searchLastPage}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div>
              <h5>You have {this.props.numberOfLeads} leads</h5>
              <div className="mt-3">
              <table className="table table-bordered">
                <thead>
                  <tr className="table-active">
                    <th>Vendor Lead Code</th>
                    <th className="sortHeaderCell" onClick={() => { this.props.setSortOptions("leadCreated") }}>
                        Lead Created Date
                        { this.props.sortBy === "leadCreated" && <SortButton sortType={this.props.sortType} /> }
                      </th>
                    <th>Lead Transferred Date</th>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Milestone</th>
                    <th>TM Name</th>
                    <th className="sortHeaderCell">
                        Call back date
                        { this.props.sortBy === "callBackLaterDate" && <SortButton sortType={this.props.sortType} /> }
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <LeadsDashboardRow
                  user={this.props.user}
                  data={this.props.leads}
                  loanOfficers={this.props.LO}
                  setLead={this.props.setLead}
                />
              </table>
              <div className="container">
                <PageNav
                  onClickNext={this.props.onClickNext}
                  onClickPrevious={this.props.onClickPrevious}
                  pre={this.props.prePage}
                  next={this.props.nextPage}
                  current={this.props.currentPage}
                  last={this.props.lastPage}
                />
              </div>
            </div>
            </div>
           
          );
        }
      }
    } else {
      return <div></div>;
    }
  }
}
