import React, { Component } from "react";
import edit from "../../../img/edit-icon.png";
import axios from "axios";
import ComparisonFieldValue from "./comparison-field-value";
import ComparisonFieldButton from "./comparison-field-button";

export default class ComparisonFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vaBase: "",
      jumboThreshold: "",
      MaxFhaCoLtv: "",
      MaxFhaConvRtLtv: "",
      fhaFICO: "",
      irrrlFICO: "",
      editVaBase: "",
      editJumboThreshold: "",
      editFhaCoLtv: "",
      editFhaConvRtLtv: "",
      editfhaFICO: "",
      editirrrlFICO: "",
    };
  }

  componentDidMount() {
    axios.get("backend/CompField").then((res) => {
      if (res.data && res.data[0]) {
        this.setState({
          vaBase: res.data[0].vaBaseRate,
          jumboThreshold: res.data[0].jumboThreshold,
          MaxFhaCoLtv: res.data[0].maxFHACOLTV,
          MaxFhaConvRtLtv: res.data[0].maxFHAConvRTLTV,
          fhaFICO: res.data[0].fhaFICO,
          irrrlFICO: res.data[0].irrrlFICO,
        });
     }
    });
  }

  onClickVaBase() {
    this.setState({ editVaBase: true });
  }
  onSaveVaBase() {
    axios
      .get("backend/CompField/update/vaBaseRate?value=" + this.state.vaBase)
      .then((res) => console.log(res.data))
      .catch((err) => console.log("Error:" + err));
    this.setState({ editVaBase: "" });
  }
  onChangeVaBase(e) {
    this.setState({ vaBase: e.target.value });
  }

  onClickJumboThreshold() {
    this.setState({ editJumboThreshold: true });
  }
  onSaveJumboThreshold() {
    axios
      .get(
        "backend/CompField/update/jumboThreshold?value=" +
          this.state.jumboThreshold
      )
      .then((res) => console.log(res.data))
      .catch((err) => console.log("Error:" + err));
    this.setState({ editJumboThreshold: "" });
  }
  onChangeJumboThreshold(e) {
    this.setState({ jumboThreshold: e.target.value });
  }

  onClickMaxFhaCoLtv() {
    this.setState({ editMaxFhaCoLtv: true });
  }
  onSaveMaxFhaCoLtv() {
    axios
      .get(
        "backend/CompField/update/maxFHACOLTV?value=" + this.state.MaxFhaCoLtv
      )
      .then((res) => console.log(res.data))
      .catch((err) => console.log("Error:" + err));
    this.setState({ editMaxFhaCoLtv: "" });
  }
  onChangeMaxFhaCoLtv(e) {
    this.setState({ MaxFhaCoLtv: e.target.value });
  }

  onClickMaxFhaConvRtLtv() {
    this.setState({ editMaxFhaConvRtLtv: true });
  }
  onSaveMaxFhaConvRtLtv() {
    axios
      .get(
        "backend/CompField/update/maxFHAConvRTLTV?value=" +
          this.state.MaxFhaConvRtLtv
      )
      .then((res) => console.log(res.data))
      .catch((err) => console.log("Error:" + err));
    this.setState({ editMaxFhaConvRtLtv: "" });
  }
  onChangeMaxFhaConvRtLtv(e) {
    this.setState({ MaxFhaConvRtLtv: e.target.value });
  }

  onClickfhaFICO() {
    this.setState({ editfhaFICO: true });
  }
  onSavefhaFICO() {
    axios
      .get("backend/CompField/update/fhaFICO?value=" + this.state.fhaFICO)
      .then((res) => console.log(res.data))
      .catch((err) => console.log("Error:" + err));
    this.setState({ editfhaFICO: "" });
  }
  onChangefhaFICO(e) {
    this.setState({ fhaFICO: e.target.value });
  }
  onClickirrrlFICO() {
    this.setState({ editirrrlFICO: true });
  }
  onSaveirrrlFICO() {
    axios
      .get("backend/CompField/update/irrrlFICO?value=" + this.state.irrrlFICO)
      .then((res) => console.log(res.data))
      .catch((err) => console.log("Error:" + err));
    this.setState({ editirrrlFICO: "" });
  }
  onChangeirrrlFICO(e) {
    this.setState({ irrrlFICO: e.target.value });
  }
  render() {
    return (
      <div>
        <div className="card mt-4 container shadow ">
          <h4 className="h4 text-center">Comparison Field</h4>
          <table className="table table-striped table-sm table-bordered shadow mt-3">
            <thead>
              <tr>
                <td>Comparison Field</td>
                <td>Value</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Va Base Rate</td>
                <td className="edit-col">
                  {" "}
                  <ComparisonFieldValue
                    value={this.state.vaBase}
                    edit={this.state.editVaBase}
                    onChangeValue={this.onChangeVaBase.bind(this)}
                  />
                </td>
                <td className="edit-col">
                  <ComparisonFieldButton
                    edit={this.state.editVaBase}
                    onSaveValue={this.onSaveVaBase.bind(this)}
                    onClickValue={this.onClickVaBase.bind(this)}
                  />
                </td>
              </tr>
              <tr>
                <td>Jumbo Threshold</td>
                <td className="edit-col">
                  {" "}
                  <ComparisonFieldValue
                    value={this.state.jumboThreshold}
                    edit={this.state.editJumboThreshold}
                    onChangeValue={this.onChangeJumboThreshold.bind(this)}
                  />
                </td>
                <td className="edit-col">
                  <ComparisonFieldButton
                    edit={this.state.editJumboThreshold}
                    onSaveValue={this.onSaveJumboThreshold.bind(this)}
                    onClickValue={this.onClickJumboThreshold.bind(this)}
                  />
                </td>
              </tr>
              <tr>
                <td>Max FHA CO LTV</td>
                <td className="edit-col">
                  <ComparisonFieldValue
                    value={this.state.MaxFhaCoLtv}
                    edit={this.state.editMaxFhaCoLtv}
                    onChangeValue={this.onChangeMaxFhaCoLtv.bind(this)}
                  />
                </td>
                <td className="edit-col">
                  <ComparisonFieldButton
                    edit={this.state.editMaxFhaCoLtv}
                    onSaveValue={this.onSaveMaxFhaCoLtv.bind(this)}
                    onClickValue={this.onClickMaxFhaCoLtv.bind(this)}
                  />
                </td>
              </tr>
              <tr>
                <td>Max FHA Conversion Rate LTV</td>
                <td className="edit-col">
                  {" "}
                  <ComparisonFieldValue
                    value={this.state.MaxFhaConvRtLtv}
                    edit={this.state.editMaxFhaConvRtLtv}
                    onChangeValue={this.onChangeMaxFhaConvRtLtv.bind(this)}
                  />
                </td>
                <td className="edit-col">
                  <ComparisonFieldButton
                    edit={this.state.editMaxFhaConvRtLtv}
                    onSaveValue={this.onSaveMaxFhaConvRtLtv.bind(this)}
                    onClickValue={this.onClickMaxFhaConvRtLtv.bind(this)}
                  />
                </td>
              </tr>
              <tr>
                <td>FHA FICO</td>
                <td className="edit-col">
                  <ComparisonFieldValue
                    value={this.state.fhaFICO}
                    edit={this.state.editfhaFICO}
                    onChangeValue={this.onChangefhaFICO.bind(this)}
                  />
                </td>
                <td className="edit-col">
                  <ComparisonFieldButton
                    edit={this.state.editfhaFICO}
                    onSaveValue={this.onSavefhaFICO.bind(this)}
                    onClickValue={this.onClickfhaFICO.bind(this)}
                  />
                </td>
              </tr>
              <tr>
                <td>IRRRL FICO</td>
                <td className="edit-col">
                  <ComparisonFieldValue
                    value={this.state.irrrlFICO}
                    edit={this.state.editirrrlFICO}
                    onChangeValue={this.onChangeirrrlFICO.bind(this)}
                  />
                </td>
                <td className="edit-col">
                  <ComparisonFieldButton
                    edit={this.state.editirrrlFICO}
                    onSaveValue={this.onSaveirrrlFICO.bind(this)}
                    onClickValue={this.onClickirrrlFICO.bind(this)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="card mt-4 container shadow ">
          <h4 className="h4 text-center">Comparison Field Calculations</h4>
          <div className="border shadow mt-3 ">
            <div className="row container ">
              <div className="col">
                <p>
                  <strong>FHA Base Rate</strong> = (VA Base) + 0.125{" "}
                </p>
              </div>
              <div className="col">
                <p>
                  <strong>Conv Base Rate</strong> = (VA Base) + 0.375{" "}
                </p>
              </div>
            </div>
            <div className="row container">
              <div className="col bg-light">
                <p>
                  <strong>IRRRL Rate</strong> = (VA Base) + 0.500 <br />
                  &nbsp; &nbsp; &nbsp; <strong>if </strong>( loan amount {">"}{" "}
                  Jumbo Threshold - 5,000 ) <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; add 0<br />
                  &nbsp; &nbsp; &nbsp; <strong>if </strong>( credit score {"<"}{" "}
                  580 ) <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; add 0.125
                  <br />
                  &nbsp; &nbsp; &nbsp; <strong>if </strong> (credit score {"<"}{" "}
                  600 ) <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; add 0.625
                  <br />
                  &nbsp; &nbsp; &nbsp; <strong>if </strong> ( loan amount {"<"}{" "}
                  150,000) <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; add 0.500
                  <br />
                </p>
              </div>
              <div className="col bg-light">
                {" "}
                <p>
                  <strong>FHA Stream Rate </strong> = (FHA Base Rate) + 1.25{" "}
                  <br />
                  &nbsp; &nbsp; &nbsp; <strong>if </strong>( credit score {">="}{" "}
                  620 ) <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; add 0<br />
                  &nbsp; &nbsp; &nbsp; <strong>else</strong> <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; add 0.875
                  <br />
                  &nbsp; &nbsp; &nbsp; <strong>if </strong> ( loan amount {">"}{" "}
                  Jumbo Threshold - 5,000) <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; add 0.750
                  <br />
                </p>
              </div>
            </div>
            <div className="row container">
              <div className="col">
                {" "}
                <p>
                  <strong>FHA Floor Rate </strong> = (FHA Base Rate) <br />
                  &nbsp; &nbsp; &nbsp; <strong>if </strong>( FH lead) <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; add 0.750
                  <br />
                  &nbsp; &nbsp; &nbsp; <strong>else</strong> <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>if </strong>( has mortgage insurance) <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp;&nbsp; &nbsp; &nbsp; add 1.300 <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>else</strong> <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp;&nbsp; &nbsp; &nbsp; add 1.600 <br />
                  &nbsp; &nbsp; &nbsp; <strong>if </strong> ( credit score {"<"}{" "}
                  640) <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; add 0.25
                  <br />
                  &nbsp; &nbsp; &nbsp; <strong>if </strong> ( loan amount {">"}{" "}
                  Jumbo Threshold - 5,000) <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; add 0.250
                  <br />
                </p>
              </div>
              <div className="col">
                <p>
                  <strong>MI Elim Floor </strong> = (Conv Base Rate) + 0.125{" "}
                  <br />
                  &nbsp; &nbsp; &nbsp; <strong>if </strong>( credit score {"<"}{" "}
                  720 ) <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; add 0.500
                  <br />
                  &nbsp; &nbsp; &nbsp; <strong>if </strong> ( loan amount {">"}{" "}
                  Jumbo Threshold - 8,000) <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; add 0.500
                  <br />
                  &nbsp; &nbsp; &nbsp; <strong>if </strong> ( CO lead) <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; add 0.750
                  <br />
                </p>
              </div>
            </div>
            <div className="row container">
              <div className="col bg-light">
                <p>
                  <strong>Conv Floor Rate</strong> = (Conv Base Rate) + 0.375{" "}
                </p>
              </div>
              <div className="col bg-light">
                <p>
                  <strong>Min Equity </strong> = <br />
                  &nbsp; &nbsp; &nbsp; <strong>if </strong>( LTV {"<"} 85 ){" "}
                  <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; return 10,000
                  <br />
                  &nbsp; &nbsp; &nbsp; <strong>else</strong> <br /> &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; return 15,000
                  <br />
                </p>
              </div>
            </div>


          </div>
        </div>
      </div>
    );
  }
}
