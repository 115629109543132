import React, { Component } from "react";
import DatePicker from "react-datepicker";

export default class ScheduleCallBack extends Component {
  render() {
    if (this.props.leadStatus == "Call Back Later") {
      if (
        this.props.callBackLaterDate == null ||
        this.props.callBackLaterDate == undefined
      ) {
        return (
          <tr>
            <td>Call Back Later Date</td>
            <td>
              <DatePicker
                selected={new Date(Date.now())}
                onChange={(e)=>this.props.onChangeCallBackLaterDate(e)}
                className="form-control input-group"
                showTimeSelect
                timeFormat="h :mm aa"
                timeIntervals={5}
                timeCaption="time"
                dateFormat="MMM d, yyyy h:mm aa"
              />
            </td>
          </tr>
        );
      } else {
        return (
          <tr>
            <td>Call Back Later Date</td>
            <td>
              <DatePicker
                selected={new Date(this.props.callBackLaterDate)}
                onChange={(e)=>this.props.onChangeCallBackLaterDate(e)}
                className="form-control input-group"
                showTimeSelect
                timeFormat="h:mm aa"
                timeIntervals={5}
                timeCaption="time"
                dateFormat="MMM d, yyyy h:mm aa"
              />
            </td>
          </tr>
        );
      }
    } else {
      return <tr></tr>;
    }
  }
}
