import React, { Component } from "react";
import PageNavItems from "./page-nav-items";

export default class PageNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pagesToRender: [],
      currentPage: "",
    };
  }


  render() {
    if (this.props.pre ===-1 && this.props.next ===-1) {
      return (
        <ul className=" pagination justify-content-center">
          <PageNavItems text={this.props.current} active={true} />
        </ul>
      );
    } else if (this.props.pre ===-1) {
      return (
        <ul className=" pagination justify-content-center">
          <PageNavItems text={this.props.current} active={true} />
          <PageNavItems text="Next" onClickNext={this.props.onClickNext}/>
        </ul>
      );
    } else if (this.props.next ===-1) {
      return (
        <ul className=" pagination justify-content-center">
          <PageNavItems text="Previous"  onClickPrevious={this.props.onClickPrevious}/>
          <PageNavItems text={this.props.current} active={true} />
        </ul>
      );
    } else {
      return (
        <ul className=" pagination justify-content-center">
          <PageNavItems text="Previous"  onClickPrevious={this.props.onClickPrevious}/>
          <PageNavItems text={this.props.current} active={true} />
          <PageNavItems text="Next"  onClickNext={this.props.onClickNext} />
        </ul>
      );
    }
  }
}
