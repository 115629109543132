import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { EditUserModal } from "./editUserModal";
import { BiSearchAlt, BiAddToQueue } from "react-icons/bi";
import { CreateUserModal } from "./createUserModal";
import Pagination from "react-js-pagination";
import axios from "axios";


export const EditUsers = (props) => {
  const [crmNavPermissions, setCrmNavPermissions] = useState([]);
  const [matrixNavPermissions, setMatrixNavPermissions] = useState([]);
  const [matrixLeadPermissions, setMatrixLeadPermissions] = useState([]);
  const [crmLeadPermissions, setCrmLeadPermissions] = useState([]);
  const [branchPermissions, setBranchPermissions] = useState([]);
  const [sitePermissions, setSitePermissions] = useState([]);
  const [showEditUser, setShowEditUser] = useState(false);
  const [showDeactivatedUsers, setDeactivatedUsers] = useState(false);
  const [activeEditUser, setActiveEditUSer] = useState();
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [userData, setUserData] = useState([]);
  const [userSearchData, setUserSearchData] = useState([]);
  const [userSearchText, setUSerSearchText] = useState("");
  const [currentEditUserPage, setCurrentEditUserPage] = useState(1);
  const [editUserItemCount, setEditUserItemCount] = useState(0);
  const [branchCodes, setBranchCodes] = useState([]);
  const [
    currentEditUserPageDeactivated,
    setCurrentEditUserPageDeactivated,
  ] = useState(1);
  const [
    editUserItemCountDeactivated,
    setEditUserItemCountDeactivated,
  ] = useState(0);

  useEffect(() => {
    axios.get("/backend/user/getAll").then((resp) => {
      console.log(resp.data);
      setUserData(resp.data.users);
      setEditUserItemCount(resp.data.numberOfDocs);
    });

    axios.get("/backend/user/getAllPages").then((resp) => {
      setUserSearchData(resp.data.users);
    });
  }, []);

  useEffect(() => {
    axios.get("/backend/user/getAll").then((resp) => {
      setUserData(resp.data.users);
    });
    axios.get("/backend/user/getAllPages").then((resp) => {
      setUserSearchData(resp.data.users);
      setEditUserItemCount(resp.data.numberOfDocs);
    });
  }, [showEditUser, showCreateUserModal]);

  useEffect(() => {
    const reqJson = {
      userSearchText: userSearchText,
    };

    axios
    .post('/backend/user/branchCodeList')
    .then((res) => {
      setBranchCodes(['Select All', ...res.data]);
    })
    .catch();

    console.log("Edit user page");

    if (userSearchText == "") {
      if (showDeactivatedUsers) {
        axios
          .get(
            " /backend/user/getAllDeactivatedUsersPages?page=" +
              currentEditUserPageDeactivated
          )
          .then((resp) => {
            setUserSearchData(resp.data.users);
            setEditUserItemCountDeactivated(resp.data.numberOfDocs);
          });
      } else {
        axios
          .get("/backend/user/getAllPages?page=" + currentEditUserPage)
          .then((resp) => {
            setUserSearchData(resp.data.users);
            setEditUserItemCount(resp.data.numberOfDocs);
            setCurrentEditUserPageDeactivated(1);
          });
      }
    } else {
      axios.post("/backend/user/userSearch", reqJson).then((resp) => {
        setUserSearchData(resp.data.users);
        setEditUserItemCount(0);
        setCurrentEditUserPageDeactivated(1);
      });
    }
  }, [
    userSearchText,
    currentEditUserPage,
    showDeactivatedUsers,
    currentEditUserPageDeactivated,
  ]);

  const onChangePage = (data) => {
    console.log(data);
    setCurrentEditUserPage(data);
  };

  const onChangePageDeactivated = (data) => {
    setCurrentEditUserPageDeactivated(data);
  };

  const onClickEditUser = (user) => {
    setCrmNavPermissions(user.crmNavPermissions);
    setMatrixNavPermissions(user.matrixNavPermissions);
    setMatrixLeadPermissions(user.matrixLeadPermissions);
    setCrmLeadPermissions(user.crmLeadPermissions);
    setBranchPermissions(user.branchPermissions);
    setSitePermissions(user.sitePermissions);
    setActiveEditUSer(user);
    setShowEditUser(true);
  };
  const userLicensedStatesItems = userData.map((user) => {
    return <option value={user.email}>{user.fullName}</option>;
  });

  const userItems = userSearchData.map((user) => {
    return (
      <div className="row">
        <div className="col-3">{user.firstName + " " + user.lastName}</div>
        <div className="col-4">{user.email}</div>
        <div className="col-4">{user.userType}</div>
        <div className="col-1">
          <a className="text-dark" onClick={() => onClickEditUser(user)}>
            <FaRegEdit />
          </a>
        </div>
      </div>
    );
  });
  return (
    <div>
      <EditUserModal
        show={showEditUser}
        setShow={setShowEditUser}
        editUser={activeEditUser}
        store={props.store}
        baseURL={props.baseURL}
        crmNavPermissions={crmNavPermissions}
        setCrmNavPermissions={setCrmNavPermissions}
        matrixNavPermissions={matrixNavPermissions}
        setMatrixNavPermissions={setMatrixNavPermissions}
        matrixLeadPermissions={matrixLeadPermissions}
        setMatrixLeadPermissions={setMatrixLeadPermissions}
        crmLeadPermissions={crmLeadPermissions}
        setCrmLeadPermissions={setCrmLeadPermissions}
        branchPermissions={branchPermissions}
        setBranchPermissions={setBranchPermissions}
        sitePermissions={sitePermissions}
        setSitePermissions={setSitePermissions}
        branchCodes={branchCodes}
      />
      
          <CreateUserModal
            show={showCreateUserModal}
            setShow={setShowCreateUserModal}
            user={props.user}
            baseURL={props.baseURL}
            branchCodes={branchCodes}
          />
       

      <div className="col-12 container">
        <div className="row justify-content-center">
          <div className=" col-8 border mt-1  mr-3">
            <div className=" container form-group mt-2">
              <h4 className="h4 text-center">Edit Users' Profile</h4>
              <div className="mb-2 mt-1 row ">
                <div className="col-6 input-group">
                  <input
                    className="form-control  input-small"
                    placeholder="Search User"
                    value={userSearchText}
                    onChange={(e) => setUSerSearchText(e.target.value)}
                  />
                  <div className="input-group-append">
                    <a
                      className="btn btn-outline-secondary pl-1 pb-1 pr-1 input-small"
                      type="button"
                      id="button-addon2"
                    >
                      <BiSearchAlt />
                    </a>
                  </div>
                </div>

                <div className="col-2"></div>
                <div className="col-4">
                  {" "}
                  <a
                    className="border rounded ml-1 pr-1 pl-1 text-dark"
                    onClick={() => setShowCreateUserModal(true)}
                  >
                    Create User <BiAddToQueue />
                  </a>
                </div>
              </div>
              <div className="row justify-content-start">
                {" "}
                <div className="ml-2">
                  <div class="custom-control custom-switch ml-1">
                    <input
                      checked={showDeactivatedUsers}
                      onClick={() => setDeactivatedUsers(!showDeactivatedUsers)}
                      type="checkbox"
                      class="custom-control-input"
                      id="customSwitch3"
                    />
                    <label class="custom-control-label" for="customSwitch3">
                      Show Deactivated Users
                    </label>
                  </div>
                </div>
              </div>
              <div className="row bg-light">
                <div className="col-3">Name</div>
                <div className="col-4">email</div>
                <div className="col-4">User Type</div>
                <div className="col-1"></div>
              </div>
              {userItems}
              <div>
                <div className="mt-2">
                  <div className="row justify-content-center mt-1">
                    <div>
                      <div className="col">
                        {editUserItemCount == 0 ? (
                          <div></div>
                        ) : (
                          <Pagination
                            innerClass={"pagination"}
                            activeLinkClass={"active"}
                            activePage={
                              showDeactivatedUsers
                                ? currentEditUserPageDeactivated
                                : currentEditUserPage
                            }
                            itemsCountPerPage={12}
                            totalItemsCount={
                              showDeactivatedUsers
                                ? editUserItemCountDeactivated
                                : editUserItemCount
                            }
                            pageRangeDisplayed={5}
                            onChange={
                              showDeactivatedUsers
                                ? onChangePageDeactivated
                                : onChangePage
                            }
                            prevPageText="<"
                            nextPageText=">"
                            firstPageText="<<"
                            lastPageText=">>"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
