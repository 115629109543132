

export default function employmentTest(typeOfEmployment, hasCoBorrower){
    if(typeOfEmployment === "Employed" || typeOfEmployment === "Disabled" || typeOfEmployment === "Retired"){
        return "Pass";
    } else if(typeOfEmployment === "Unemployed" && hasCoBorrower ==="Yes"){
        return "Need More Information";
    } else if(typeOfEmployment === "Unemployed" && hasCoBorrower ==="No"){
        return "Fail";
    }else return "";
}

