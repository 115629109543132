import React, { Component } from "react";
import axios from "axios";
import calcIRRRLRate from "../leadMidSectionComponents/opportunities/calcIRRRLRate";
import calcFhaStreamRate from "../leadMidSectionComponents/opportunities/calcFhaStreamRate";
import calcFhaRtFloor from "../leadMidSectionComponents/opportunities/calcFhaRtFloor";
import calcMiElimFloor from "../leadMidSectionComponents/opportunities/calcMiElimFloor";
import calcMinEquity from "../leadMidSectionComponents/opportunities/calcMinEquity";
import calcLTV from "../leadMidSectionComponents/opportunities/calcLTV";
import calcMaxVaCoLtv from "../leadMidSectionComponents/opportunities/calcMaxVaCoLtv";
import calcMaxConvCoLtv from "../leadMidSectionComponents/opportunities/calcMaxConvCoLtv";
import calcMaxConvMiLtv from "../leadMidSectionComponents/opportunities/calcMaxConvMiLtv";

export default class AdminLeadComparison extends Component {
  constructor(props) {
    super(props);

    this.state = {
      VABaseRate: "",
      FHABaseRate: "",
      ConvBaseRate: "",
      IRRRLRate: "",
      FHAStreamRate: "",
      FHARTFloor: "",
      ConvRTFloor: "",
      MIElimFloor: "",
      JumboThreshold: "",
      Minequity: "",
      MaxVACOLTV: "",
      MaxFHACOLTV: "",
      MaxFHAConvRTLTV: "",
      MaxConvCOLTV: "",
      MaxConvMILTV: "",
      FHAFICO: "",
      IRRRLFICO: "",
    };
  }
  componentDidMount() {
    axios.get("backend/CompField").then((res) => {
      if (res.data && res.data[0]) {
      this.setState(() => ({
        FHAFICO: res.data[0].fhaFICO,
        IRRRLFICO: res.data[0].irrrlFICO,
        VABaseRate: res.data[0].vaBaseRate,
        JumboThreshold: res.data[0].jumboThreshold,
        MaxFHACOLTV: res.data[0].maxFHACOLTV,
        MaxFHAConvRTLTV: res.data[0].maxFHAConvRTLTV,
        FHABaseRate: res.data[0].vaBaseRate + 0.125,
        ConvBaseRate: res.data[0].vaBaseRate + 0.375,
      }));
    }
    });
  }
  calcFHABaseRate(vaBase) {
    return Number(vaBase) + 0.125;
  }
  calcFHABaseRate(vaBase) {
    return Number(vaBase) + 0.375;
  }
  render() {
    return (
      <div className="mt-2">
        <table className="table table-sm table-striped  table-bordered shadow">
          <tbody>
            <tr>
              <td>VA Base Rate</td>
              <td>
                <div className="input-group-sm">{this.state.VABaseRate}</div>
              </td>
              <td>FHA Base Rate</td>
              <td className="admin-comp">
                <div className="input-group-sm">{this.state.FHABaseRate}</div>
              </td>
              <td>Conv Base Rate</td>
              <td className="admin-comp">
                <div className="input-group-sm">{this.state.ConvBaseRate}</div>
              </td>
            </tr>
            <tr>
              <td>IRRRL Rate</td>
              <td className="admin-comp">
                <div className="input-group-sm">
                  {calcIRRRLRate(
                    this.state.VABaseRate,
                    this.state.JumboThreshold,
                    this.props.lead.creditScore,
                    this.props.lead.loanBalance
                  )}
                </div>
              </td>
              <td>FHA Stream Rate</td>
              <td className="admin-comp">
                <div className="input-group-sm">
                  {calcFhaStreamRate(
                    this.state.FHABaseRate,
                    this.props.lead.creditScore,
                    this.props.lead.loanBalance,
                    this.state.JumboThreshold
                  )}
                </div>
              </td>
              <td>FHA RT Floor</td>
              <td className="admin-comp">
                <div className="input-group-sm">
                  {calcFhaRtFloor(
                    this.state.FHABaseRate,
                    this.props.lead.vendorLeadCode,
                    this.props.lead.hasMortgageInsurance,
                    this.props.lead.creditScore,
                    this.props.lead.loanBalance,
                    this.state.JumboThreshold
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>Conv RT Floor</td>
              <td className="admin-comp">
                <div className="input-group-sm">
                  {this.state.ConvBaseRate + 0.75}
                </div>
              </td>
              <td>MI Elim Floor </td>
              <td className="admin-comp">
                <div className="input-group-sm">
                  {calcMiElimFloor(
                    this.state.ConvBaseRate,
                    this.props.lead.creditScore,
                    this.props.lead.loanBalance,
                    this.state.JumboThreshold,
                    this.props.lead.vendorLeadCode
                  )}
                </div>
              </td>
              <td>Jumbo Threshold</td>
              <td className="admin-comp">
                <div className="input-group-sm">
                  {this.state.JumboThreshold}
                </div>
              </td>
            </tr>
            <tr>
              <td>Min Equity</td>
              <td className="admin-comp">
                <div className="input-group-sm">
                  {calcMinEquity(
                    calcLTV(
                      this.props.lead.loanBalance,
                      this.props.lead.estimatedValue
                    )
                  )}
                </div>
              </td>
              <td>Max Va Co LTV </td>
              <td className="admin-comp">
                <div className="input-group-sm">
                  {calcMaxVaCoLtv(this.props.lead.creditScore)}
                </div>
              </td>
              <td>Max FHA Co LTV</td>
              <td className="admin-comp">
                <div className="input-group-sm">{this.state.MaxFHACOLTV}</div>
              </td>
            </tr>
            <tr>
              <td>Max FHA Conv RT LTV</td>
              <td className="admin-comp">
                <div className="input-group-sm">
                  {this.state.MaxFHAConvRTLTV}
                </div>
              </td>
              <td>Max Conv Co LTV </td>
              <td className="admin-comp">
                <div className="input-group-sm">
                  {calcMaxConvCoLtv(this.props.lead.occupancy)}
                </div>
              </td>
              <td>Max Conv MI LTV</td>
              <td className="admin-comp">
                <div className="input-group-sm">
                  {calcMaxConvMiLtv(this.props.lead.state)}
                </div>
              </td>
            </tr>
            <tr>
              <td>FHA FICO</td>
            <td className="admin-comp">
                <div className="input-group-sm">
                  {this.state.FHAFICO}
                </div>
              </td>
              <td>IRRRL FICO</td>
              <td className="admin-comp">
                <div className="input-group-sm">
                  {this.state.IRRRLFICO}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
