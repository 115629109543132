import React, { useState, useEffect } from "react";
import employmentTest from "./employementTest";
import axios from 'axios'
import calcMiRemovalEligibility from "./calcMiRemovalEligibility";

export const MiRemoval = ({lead}) => {
    const [convBaseRate, setConvBaseRate] = useState('');
    const [jumboThreshold, setJumboThreshold] = useState('');
    const [miRemovalStatus, setMiRemovalStatus] = useState({ classStr: '', message: '' });
    
    useEffect(() => {
        axios.get("backend/CompField").then((res) => {
            if (res.data && res.data[0]) {
                setConvBaseRate(res.data[0].vaBaseRate + 0.375);
                setJumboThreshold(res.data[0].jumboThreshold);
            }
        });
    }, [])

    useEffect(() => {
        const status = calcMiRemovalEligibility(lead, convBaseRate, jumboThreshold)
        if (status.classStr && status.message) {
            setMiRemovalStatus(status)
        }
    }, [lead, convBaseRate, jumboThreshold])

    const requiredFields = lead.hasMortgageInsurance !== "" && lead.mortgageDate !== "" && (lead.loanBalance !== "" && lead.loanBalance!== null) &&
    (lead.estimatedValue !== "" && lead.loanBalance!== null) && lead.interestRate !== "" && lead.creditScore !== "" &&
    employmentTest(lead.employment, lead.hasCoBorrower) !== "";

    return (
        <tr>
            <td>MI Removal</td>
            <td>
                {
                    requiredFields ? (
                        <div id="miRemovalResponse" className={miRemovalStatus.classStr}>
                            {miRemovalStatus.message}
                        </div>
                    ) : <div id="miRemovalResponse"></div>
                }
            </td>
        </tr>
    )
}
