import React, { Component } from "react";
import axios from "axios";
import LeadsDashboardTable from "./leads-dashboard-table";
import {ActiveLead} from "../activeLead";
import DatePicker from 'react-datepicker';
export default class LeadDashboard extends Component {
  constructor(props) {
    super(props);
    this.handleOnClickSearch = this.handleOnClickSearch.bind(this);
    this.onChangeSearchTm = this.onChangeSearchTm.bind(this);
    this.onChangeSearchLeadStatus = this.onChangeSearchLeadStatus.bind(this);
    this.onChangeSearchText = this.onChangeSearchText.bind(this);
    this.onChangeSearchMilestone=this.onChangeSearchMilestone.bind(this)
    this.getNewPageOfLeads = this.getNewPageOfLeads.bind(this);
    this.setDateSort = this.setDateSort.bind(this);
    this.setSortOptions = this.setSortOptions.bind(this);
    this.state = {
      TM: [],
      LO: [],
      leads: "",
      prePage: "",
      nextPage: "",
      lastPage: "",
      currentPage: "",
      numberOfLeads: "",
      searchText: "",
      searchLeadStatus: "",
      searchTM: "",
      searchMilestone:"",
      searchLeads: "",
      searchPrePage: "",
      searchNextPage: "",
      searchLastPage: "",
      searchCurrentPage: "",
      searchNumberOfLeads: "",
      dateSort: 1,
      sortBy: "leadCreated",
      sortType: -1,
      isSearchResults: false,
      callbackDate: new Date(),
    };
  }

  componentDidMount() {
    axios
      .get("backend/user/TM")
      .then((res) => {
        this.setState({ TM: res.data.users });
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("backend/user/LO")
      .then((res) => {
        this.setState({ LO: res.data.users });
      })
      .catch((err) => {
        console.log(err);
      });
    if (
      this.props.user.matrixLeadPermissions.includes("Other Leads")
    ) {
      axios.get(`backend/activeleads/tm?page=1&sortBy=${this.state.sortBy}&sortType=${this.state.sortType}`).then((res) => {
        this.setState({
          leads: res.data.leads,
          nextPage: res.data.pages.next.page,
          prePage: res.data.pages.previous.page,
          lastPage: res.data.pages.last.page,
          currentPage: 1,
          numberOfLeads: res.data.numberOfDocs,
          isSearchResults: false,
        });
      });
    } else if (!this.props.user.matrixLeadPermissions.includes("Other Leads")) {
      axios
        .get(`backend/activeleads/tm-leads/${this.props.user._id}?page=1&sortBy=${this.state.sortBy}&sortType=${this.state.sortType}`)
        .then((res) => {
          this.setState(() => ({
            leads: res.data.leads,
            nextPage: res.data.pages.next.page,
            prePage: res.data.pages.previous.page,
            lastPage: res.data.pages.last.page,
            currentPage: 1,
            numberOfLeads: res.data.numberOfDocs,
            isSearchResults: false,
          }));
        });
    }
  }

  handleSetLead(lead) {
    this.props.handleShowLead(lead);
  }
  onClickPrevious() {
    this.getNewPageOfLeads(this.state.currentPage - 1);
  }
  onClickNext() {
    this.getNewPageOfLeads(this.state.currentPage + 1);
  }
  onClickSearchPrevious() {
    this.getNewPageOfSearchLeads(this.state.searchCurrentPage - 1);
  }
  onClickSearchNext() {
    this.getNewPageOfSearchLeads(this.state.searchCurrentPage + 1);
  }
  getNewPageOfLeads(pageNumber) {
    this.getNewPageOfSearchLeads(pageNumber)
  }

  getNewPageOfSearchLeads(pageNumber) {
    const otherLeadsPermission = this.props.user.matrixLeadPermissions.includes("Other Leads");
    let reqURL = '';
    let reqBody;

    if (
        this.state.searchText === "" &&
        this.state.searchLeadStatus === "" &&
        this.state.searchTM === ""
    ) {
        if (otherLeadsPermission) {
            reqURL = `backend/activeleads/tm?page=${pageNumber}&sortBy=${this.state.sortBy}&sortType=${this.state.sortType}`;
        } else if (!otherLeadsPermission) {
            reqURL = `backend/activeleads/tm-leads/${this.props.user._id}?page=${pageNumber}&sortBy=${this.state.sortBy}&sortType=${this.state.sortType}`;
        }
    } else if (this.state.searchTM === "" && this.state.searchLeadStatus === "") {
        if (otherLeadsPermission) {
            reqBody = { text: this.state.searchText };
            reqURL = `backend/activeleads/search/tm/text?page=${pageNumber}&sortBy=${this.state.sortBy}&sortType=${this.state.sortType}`;
        } else if (!otherLeadsPermission) {
            reqBody = {
                text: this.state.searchText,
                tmId: this.props.user._id,
            };
            reqURL = `backend/activeleads/search/tm?page=${pageNumber}&sortBy=${this.state.sortBy}&sortType=${this.state.sortType}`;
        } 
    } else if (this.state.searchLeadStatus === "") {
        reqBody = {
            text: this.state.searchText,
            tmId: this.state.searchTM,
        };
        reqURL = `backend/activeleads/search/tm?page=${pageNumber}&sortBy=${this.state.sortBy}&sortType=${this.state.sortType}`;
    } else if (this.state.searchTM === "") {
        if (otherLeadsPermission) {
            reqBody = {
                text: this.state.searchText,
                searchLeadStatus: this.state.searchLeadStatus,
            };
            reqURL = `backend/activeleads/search/tm/leadStatus/admin?page=${pageNumber}&sortBy=${this.state.sortBy}&sortType=${this.state.sortType}`;
        }  else if (!otherLeadsPermission) {
            reqBody = {
                text: this.state.searchText,
                tmId: this.props.user._id,
                searchLeadStatus: this.state.searchLeadStatus,
            };
            reqURL = `backend/activeleads/search/tm/leadStatus?page=${pageNumber}&sortBy=${this.state.sortBy}&sortType=${this.state.sortType}`;
        }
    } else {
        reqBody = {
            text: this.state.searchText,
            searchLeadStatus: this.state.searchLeadStatus,
            tmId: this.state.searchTM,
        };
        reqURL = `backend/activeleads/search/tm/leadStatus?page=${pageNumber}&sortBy=${this.state.sortBy}&sortType=${this.state.sortType}`;
    }

    if ( this.state.searchLeadStatus == "Call Back Later" && this.state.callbackDate) {
      reqURL += `&start=${new Date(this.state.callbackDate).setHours(0,0,0,0)}&end=${new Date(this.state.callbackDate).setHours(23,59,59,999)}`;
    }

    if (reqBody) {
        axios.post(reqURL, reqBody).then((resp) => {
            this.setState({
                searchLeads: resp.data.leads,
                searchNextPage: resp.data.pages.next.page,
                searchPrePage: resp.data.pages.previous.page,
                searchLastPage: resp.data.pages.last.page,
                searchCurrentPage: pageNumber,
                numberOfLeads: resp.data.numberOfDocs,
                isSearchResults: true,
            });
        });
    } else {
        axios.get(reqURL).then((resp) => {
            this.setState({
                searchLeads: resp.data.leads,
                searchNextPage: resp.data.pages.next.page,
                searchPrePage: resp.data.pages.previous.page,
                searchLastPage: resp.data.pages.last.page,
                searchCurrentPage: pageNumber,
                numberOfLeads: resp.data.numberOfDocs,
                isSearchResults: true,
            });
        });
    }
    
  }

  handleOnClickSearch(e) {
    if(e) {
        e.preventDefault();
    }
    const otherLeadsPermission = this.props.user.matrixLeadPermissions.includes("Other Leads");
    let reqURL = '';
    let reqBody;
   
    if (
        this.state.searchText === "" &&
        this.state.searchLeadStatus === "" &&
        this.state.searchTM === ""
    ) {
        if (otherLeadsPermission) {
            reqURL = `backend/activeleads/tm?page=1&sortBy=${this.state.sortBy}&sortType=${this.state.sortType}`;
        } else if (!otherLeadsPermission) {
            reqURL = `backend/activeleads/tm-leads/${this.props.user._id}?page=1&sortBy=${this.state.sortBy}&sortType=${this.state.sortType}`;
        } 
    } else if (this.state.searchTM === "" && this.state.searchLeadStatus === "") {
        if (otherLeadsPermission) {
            reqBody = {
                text: this.state.searchText,
            };
            reqURL = `backend/activeleads/search/tm/text?page=1&sortBy=${this.state.sortBy}&sortType=${this.state.sortType}`;
        } else if (!otherLeadsPermission) {
            reqBody = {
                text: this.state.searchText,
                tmId: this.props.user._id,
            };
            reqURL = `backend/activeleads/search/tm?page=1&sortBy=${this.state.sortBy}&sortType=${this.state.sortType}`;
        }
    } else if (this.state.searchLeadStatus === "") {
        reqBody = {
            text: this.state.searchText,
            tmId: this.state.searchTM,
        };
        reqURL = `backend/activeleads/search/tm?page=1&sortBy=${this.state.sortBy}&sortType=${this.state.sortType}`;
    } else if (this.state.searchTM === "") {
        if (otherLeadsPermission) {
            reqBody = {
                text: this.state.searchText,
                searchLeadStatus: this.state.searchLeadStatus,
            };
            reqURL = `backend/activeleads/search/tm/leadStatus/admin?page=1&sortBy=${this.state.sortBy}&sortType=${this.state.sortType}`;
        } else if (!otherLeadsPermission) {
            reqBody = {
                text: this.state.searchText,
                tmId: this.props.user._id,
                searchLeadStatus: this.state.searchLeadStatus,
            };
            reqURL = `backend/activeleads/search/tm/leadStatus?page=1&sortBy=${this.state.sortBy}&sortType=${this.state.sortType}`;
        }
    } else {
        reqBody = {
            text: this.state.searchText,
            searchLeadStatus: this.state.searchLeadStatus,
            tmId: this.state.searchTM,
        };
        reqURL = `backend/activeleads/search/tm/leadStatus?page=1&sortBy=${this.state.sortBy}&sortType=${this.state.sortType}`;
    }

    if (this.state.searchLeadStatus == "Call Back Later" && this.state.callbackDate) {
        reqURL += `&start=${new Date(this.state.callbackDate).setHours(0,0,0,0)}&end=${new Date(this.state.callbackDate).setHours(23,59,59,999)}`;
    }
    
    if (reqBody) {
        axios.post(reqURL, reqBody).then((resp) => {
            this.setState({
                leads: resp.data.leads,
                nextPage: resp.data.pages.next.page,
                prePage: resp.data.pages.previous.page,
                lastPage: resp.data.pages.last.page,
                currentPage: 1,
                numberOfLeads: resp.data.numberOfDocs,
                isSearchResults: false,
            });
        });
    } else {
        axios.get(reqURL).then((resp) => {
            this.setState({
                leads: resp.data.leads,
                nextPage: resp.data.pages.next.page,
                prePage: resp.data.pages.previous.page,
                lastPage: resp.data.pages.last.page,
                currentPage: 1,
                numberOfLeads: resp.data.numberOfDocs,
                isSearchResults: false,
            });
        });
    }
  }

  onChangeSearchTm(e) {
    this.setState({ searchTM: e.target.value });
  }
  onChangeSearchLeadStatus(e) {
    this.setState({ searchLeadStatus: e.target.value });
  }
  onChangeSearchText(e) {
    this.setState({ searchText: e.target.value });
  }
  onChangeSearchMilestone(e){
    this.setState({ searchMilestone: e.target.value})
  }
  setDateSort() {
    if(this.state.dateSort === 1) {
      this.setState({ dateSort: -1});
    } else if(this.state.dateSort === -1) {
      this.setState({ dateSort: 1});
    }
  }

  setSortOptions(sortByValue) {
    this.setState((state) => {
      if(state.sortType === 1) {
        return { sortType: -1, sortBy: sortByValue };
      } else if(state.sortType === -1) {
        return { sortType: 1, sortBy: sortByValue} ;
      }
    }, () => {
      this.handleOnClickSearch();
    })
  }

  setCallbackDate(date) {
    this.setState({ callbackDate : date });
  }

  render() {
    const selectTMOptions = this.state.TM.map((tm) => (
      <option key={tm._id} value={tm._id}>
        {tm.firstName}
        {"  "} {tm.lastName}
      </option>
    ));
    if (this.props.showLead !== "") {
      return (
        <ActiveLead
          user={this.props.user}
          lead={this.props.showLead}
          handleSetLead={this.props.handleShowLead}
          onClickLeadDashboard={this.props.onClickLeadDashboard}
          onClickNewTmLead={this.props.onClickNewTmLead}
          setNeedsToBeSaved={this.props.setNeedsToBeSaved}
          needsToBeSaved={this.props.needsToBeSaved}
          getNewPageOfLeads={this.getNewPageOfLeads.bind(this)}
          setOpenConfirmation={this.props.setOpenConfirmation}
        />
      );
    } else {
      if (
        this.props.user.matrixLeadPermissions.includes("Other Leads")
      ) {
        return (
          <div>
            <div className="card mt-4  shadow">
              <div className="text-center">
                <label className="h4 mt-2"> Leads Dashboard</label>
              </div>

              <div className="container mt-2">
                <form className="form-group row ">
                  <select
                    className="form-control input-group col shadow ml-2"
                    value={this.state.searchLeadStatus}
                    onChange={this.onChangeSearchLeadStatus}
                  >
                    <option value="">--Lead Status--</option>
                    <option value="Call Back Later">Call Back Later</option>
                    <option value="Dead">Dead</option>
                    <option value="Transferred">Transferred</option>
                  </select>
                  {/* 
                  <select className="form-control input-group col shadow ml-2"
                  value={this.state.searchMilestone}
                  onChange={this.onChangeSearchMilestone}>
                    <option value="">--Milestone--</option>
                    <option value="Started">Started</option>
                    <option value="Sent to Opening">Sent to Opening</option>
                    <option value="Sent to processing">
                      Sent to processing
                    </option>
                    <option value="Credit Decision">Credit Decision</option>
                    <option value="Submitted">Submitted</option>
                  </select> */}

                  {this.state.searchLeadStatus === 'Call Back Later' ? (
                    <div className="ml-2">
                      <DatePicker 
                        className="form-control input-group col shadow" 
                        placeholderText="Call Back date"
                        selected={this.state.callbackDate} 
                        onChange={(date) => this.setCallbackDate(date)}
                      />
                    </div>
                  ) : <></>}

                  <select
                    className="form-control input-group col shadow ml-2"
                    value={this.state.searchTM}
                    onChange={this.onChangeSearchTm}
                  >
                    <option value="">--Select TM--</option>
                    {selectTMOptions}
                  </select>

                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control input-group col ml-2 shadow"
                    value={this.state.searchText}
                    onChange={this.onChangeSearchText}
                  />

                  <button
                    className="btn btn-primary ml-2"
                    onClick={this.handleOnClickSearch}
                  >
                    Search
                  </button>
                </form>
              </div>

              <LeadsDashboardTable
                LO={this.state.LO}
                user={this.props.user}
                leads={this.state.leads}
                prePage={this.state.prePage}
                nextPage={this.state.nextPage}
                lastPage={this.state.lastPage}
                currentPage={this.state.currentPage}
                numberOfLeads={this.state.numberOfLeads}
                isSearchResults={this.state.isSearchResults}
                searchLeads={this.state.searchLeads}
                searchPrePage={this.state.searchPrePage}
                searchNextPage={this.state.searchNextPage}
                searchCurrentPage={this.state.searchCurrentPage}
                searchNumberOfLeads={this.state.searchNumberOfLeads}
                sortType={this.state.sortType}
                sortBy={this.state.sortBy}
                setSortOptions={this.setSortOptions}
                handleOnClickSearch={this.handleOnClickSearch}
                onClickSearchPrevious={this.onClickSearchPrevious.bind(this)}
                onClickSearchNext={this.onClickSearchNext.bind(this)}
                onClickPrevious={this.onClickPrevious.bind(this)}
                onClickNext={this.onClickNext.bind(this)}
                setLead={this.handleSetLead.bind(this)}
              />
            </div>
          </div>
        );
      } else if (!this.props.user.matrixLeadPermissions.includes("Other Leads")) {
        return (
          <div>
            <div className="card mt-4  shadow">
              <div className="text-center">
                <label className="h4 mt-2"> Leads Dashboard</label>
              </div>

              <div className="container mt-2">
                <form className="form-group row ">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control input-group col ml-2 shadow"
                    value={this.state.searchText}
                    onChange={this.onChangeSearchText}
                  />
                  <select
                    className="form-control input-group col shadow ml-2"
                    value={this.state.searchLeadStatus}
                    onChange={this.onChangeSearchLeadStatus}
                  >
                    <option value="">--Lead Status--</option>
                    <option value="App">App</option>
                    <option value="Call Back Later">Call Back Later</option>
                    <option value="Dead">Dead</option>
                    <option value="New">New</option>
                    <option value="Transferred">Transferred</option>
                  </select>

                  {this.state.searchLeadStatus === 'Call Back Later' ? (
                    <div className="ml-2">
                      <DatePicker 
                        className="form-control input-group col shadow" 
                        placeholderText="Call Back date"
                        selected={this.state.callbackDate} 
                        onChange={(date) => this.setCallbackDate(date)}
                      />
                    </div>
                  ) : <></>}

                  {/* <select className="form-control input-group col shadow ml-2"
                  value={this.state.searchMilestone}
                  onChange={this.onChangeSearchMilestone}
                  >
                    <option value="">--Milestone--</option>
                    <option value="Started">Started</option>
                    <option value="Sent to Opening">Sent to Opening</option>
                    <option value="Sent to processing">
                      Sent to processing
                    </option>
                    <option value="Credit Decision">Credit Decision</option>
                    <option value="Submitted">Submitted</option>
                  </select> */}

                  <button
                    className="btn btn-primary ml-2"
                    onClick={this.handleOnClickSearch}
                  >
                    Search
                  </button>
                </form>
              </div>

              <LeadsDashboardTable
                LO={this.state.LO}
                user={this.props.user}
                leads={this.state.leads}
                prePage={this.state.prePage}
                nextPage={this.state.nextPage}
                lastPage={this.state.lastPage}
                currentPage={this.state.currentPage}
                numberOfLeads={this.state.numberOfLeads}
                isSearchResults={this.state.isSearchResults}
                searchLeads={this.state.searchLeads}
                searchPrePage={this.state.searchPrePage}
                searchNextPage={this.state.searchNextPage}
                searchCurrentPage={this.state.searchCurrentPage}
                searchNumberOfLeads={this.state.searchNumberOfLeads}
                sortType={this.state.sortType}
                sortBy={this.state.sortBy}
                setSortOptions={this.setSortOptions}
                handleOnClickSearch={this.handleOnClickSearch}
                onClickSearchPrevious={this.onClickSearchPrevious.bind(this)}
                onClickSearchNext={this.onClickSearchNext.bind(this)}
                onClickPrevious={this.onClickPrevious.bind(this)}
                onClickNext={this.onClickNext.bind(this)}
                setLead={this.handleSetLead.bind(this)}
              />
            </div>
          </div>
        );
      } else {
        return <div></div>;
      }
    }
  }
}
