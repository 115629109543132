import employmentTest from "./employementTest";
import calcPaymentsMade from "../../generalUseFunctions/calcPaymentsMade"
import calcLTV from "./calcLTV";
import calcMaxVaCoLtv from "./calcMaxVaCoLtv";

export default function calcVaConversionEligibility (lead) {
    if (!lead || !lead.vendorLeadCode) {
        return {
            classStr: '',
            message: ''
        }
    }

    const danger = 'font-weight-bold bg-danger text-white';
    const success = 'bg-success font-weight-bold text-white';

    const ltv = calcLTV(lead.loanBalance, lead.estimatedValue);
    const maxLtv = calcMaxVaCoLtv(lead.creditScore);
    if (employmentTest(lead.employment, lead.hasCoBorrower) === 'Fail') {
        return {
            classStr: danger,
            message: 'Employment Issue',
        }
    }
    if (lead.isVeteran === 'No') {
        return {
            classStr: danger,
            message: 'Not a Veteran',
        }
    }
    if (lead.vendorLeadCode.substring(0, 2) === 'VA') {
        return {
            classStr: danger,
            message: 'Already in a VA loan',
        }
    }
    if (ltv > maxLtv) {
        return {
            classStr: danger,
            message: `LTV too high: ${maxLtv}`,
        }
    }
    if (lead.state !== 'TX') {
        return {
            classStr: success,
            message: 'Eligible',
        }
    }
    if (lead.hasPrevCashOut === 'No') {
        return {
            classStr: success,
            message: 'Eligible'
        }
    }
    if (ltv > 80) {
        return {
            classStr: danger,
            message: 'TX: LTV needs to be less than 80',
        }
    }
    if (calcPaymentsMade(lead.mortgageDate) < 10) {
        return {
            classStr: danger,
            message: 'TX: Min 10 months seasoning',
        }
    }

    return {
        classStr: success,
        message: 'Eligible',
    }
}