import React, { Component } from "react";
import axios from "axios"

export default class UpdateLoanOfficer extends Component {
    constructor(props) {
        super(props);
        this.onClickUpdateLoanOfficer=this.onClickUpdateLoanOfficer.bind(this);
    }

    onClickUpdateLoanOfficer(){
        const json={
            dbIdLookup: this.props.lead.dbIdLookup,
            loanOfficer: this.props.lead.loanOfficer,
            loanOfficerName: this.props.lead.loanOfficerName,
            userAssigningLead: this.props.user._id
        }
        axios.post("/backend/activeleads/update/tm/updateLoanOfficer", json)
            .then(()=> {
                alert("Assigned Loan Officer Updated")
            })
            .catch( console.log("Loan officer not updated"));
    }

    render() {
        const selectLOOptions = this.props.LO.map((lo) => (
            <option
                key={lo._id}
                fullname={lo.firstName + " " + lo.lastName}
                value={lo._id}
            >
                {lo.firstName}
                {"  "} {lo.lastName}
            </option>
        ));
        
        if (this.props.user.matrixLeadPermissions.includes("Reassign LO")) {
            return (
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-4">
                            <div className="d-flex justify-content-end">
                                <label>Assigned Loan Officer</label>
                            </div>
                        </div>
                        <div className="col-4">
                            <select
                                className="form-control input-group ml-2"
                                onChange={this.props.onChangeLoanOfficer}
                                value={this.props.lead.loanOfficer}
                            >
                                <option value="">--Loan Officer--</option>
                                {selectLOOptions}
                            </select>
                        </div>
                        <div className="col-4">
                            <button className="btn btn-primary" onClick={this.onClickUpdateLoanOfficer}>
                                Update Assigned Loan Officer
                            </button>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div></div>;
        }
    }
}
