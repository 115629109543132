var Math = require("mathjs");

export default function calcLTV(loanBalance, estimatedValue) {
  if (estimatedValue == null && loanBalance == null) {
    return "Need est value and loan balance";
  } else if (estimatedValue == "" && loanBalance == "") {
    return "Need est value and loan balance";
  } else if (estimatedValue == "" || estimatedValue == null) {
    return "Need Estimated Value";
  } else if (loanBalance == "" || loanBalance == null) {
    return "Need Loan Balance";
  } else {
    return Math.ceil((loanBalance / estimatedValue) * 100);
  }
}
