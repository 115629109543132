import React from 'react'
import Contact from "./contact";
import AdminLeadComparison from "./admin-lead-comparison"
import PaymentCalculator from "./paymentCalculator";

export const LeadTopSectionManager = (props) => {
    if(props.user.userType=="Admin" || props.user.userType =="IT"){
        if(props.campaignName=="BSEL" || props.campaignName=="BSYL"){
            return (
                <div className=" mt-1 row justify-content-between">
                <div className="col-3 ml-2 mt-2 ">
               
                </div>
                <div className="col-3">
                  <PaymentCalculator
                    lead={props.lead}
                    showPaymentCalculator={props.showPaymentCalculator}
                    close={props.onClickCloseTools}
                  />
                </div>
                <div className="col-4 mr-2 ">
          
                </div>
              </div>
            )
        }else{
            return (
                <div className=" mt-1 row justify-content-between">
          <div className="col-3 ml-2 mt-2 ">
            <Contact
              lead={props.lead}
              onChangeB1FirstName={props.onChangeB1FirstName}
              onChangeB1LastName={props.onChangeB1LastName}
              onChangeB2FirstName={props.onChangeB2FirstName}
              onChangeB2LastName={props.onChangeB2LastName}
              onChangePhoneNumber={props.onChangePhoneNumber}
              onChangeStreetAddress={props.onChangeStreetAddress}
              onChangeCity={props.onChangeCity}
              onChangeState={props.onChangeState}
              onChangeZipCode={props.onChangeZipCode}
              setNeedsToBeSaved={props.setNeedsToBeSaved}
            />
          </div>
          <div className="col-3">
            <PaymentCalculator
              lead={props.lead}
              showPaymentCalculator={props.showPaymentCalculator}
              close={props.onClickCloseTools}
            />
          </div>
          <div className="col-4 mr-2 ">
            <AdminLeadComparison lead={props.lead} />
          </div>
        </div>
            )
        }
    }else{
        if(props.campaignName=="BSEL"|| props.campaignName=="BSYL"){
            return (
                <div className=" mt-1 row justify-content-between">
                <div className="col-3 ml-2 mt-2 ">
            
                </div>
                <div className="col-3">
                  <PaymentCalculator
                    lead={props.lead}
                    showPaymentCalculator={props.showPaymentCalculator}
                    close={props.onClickCloseTools}
                  />
                </div>
                <div className="col-4 mr-2 ">
                 
                </div>
              </div>
            )
        }else{
            return (
                <div className=" mt-1 row justify-content-between">
                <div className="col-3 ml-2 mt-2 ">
                  <Contact
                    lead={props.lead}
                    onChangeB1FirstName={props.onChangeB1FirstName}
                    onChangeB1LastName={props.onChangeB1LastName}
                    onChangeB2FirstName={props.onChangeB2FirstName}
                    onChangeB2LastName={props.onChangeB2LastName}
                    onChangePhoneNumber={props.onChangePhoneNumber}
                    onChangeStreetAddress={props.onChangeStreetAddress}
                    onChangeCity={props.onChangeCity}
                    onChangeState={props.onChangeState}
                    onChangeZipCode={props.onChangeZipCode}
                    setNeedsToBeSaved={props.setNeedsToBeSaved}

                  />
                </div>
                <div className="col-3">
                  <PaymentCalculator
                    lead={props.lead}
                    showPaymentCalculator={props.showPaymentCalculator}
                    close={props.onClickCloseTools}
                  />
                </div>
                <div className="col-4 mr-2 ">
            
                </div>
              </div>
            )
        }

      
    }
    }
    
