import employmentTest from "./employementTest";
import calcPaymentsMade from "../../generalUseFunctions/calcPaymentsMade"

export default function calcArmConversionEligibility (lead) {
    if (!lead) {
        return {
            classStr: '',
            message: '',
        }
    }

    const danger = 'font-weight-bold bg-danger text-white';
    const success = 'bg-success font-weight-bold text-white';

    if (employmentTest(lead.employment, lead.hasCoBorrower) === 'Fail') {
        return {
            classStr: danger,
            message: 'Employment Issue',
        }
    }

    if (lead.isFixed !== 'No') {
        return {
            classStr: danger,
            message: 'Not in an ARM',
        }
    }

    if (calcPaymentsMade(lead.mortgageDate) < 5) {
        return {
            classStr: danger,
            message: 'Seasoning Needed: 5 months',
        }
    }

    if (lead.loanBalance < 75000) {
        return {
            classStr: danger,
            message: 'Loan Balance too low: $75,000',
        }
    }

    return {
        classStr: success,
        message: 'Eligible',
    }
}