import React from 'react';
import { FaSortAmountDownAlt, FaSortAmountUpAlt  } from 'react-icons/fa';

export const SortButton = (props) => {
    return(
        <button className='sortButton'>
            { props.sortType == 1 && <FaSortAmountUpAlt /> }
            { props.sortType == -1 && <FaSortAmountDownAlt /> }
        </button>
    )
}