import calcEquity from "../../generalUseFunctions/calcEquity";
import calcPaymentsMade from "../../generalUseFunctions/calcPaymentsMade";
import calcLTV from "./calcLTV";
import calcMinEquity from "./calcMinEquity";
import employmentTest from "./employementTest";

export default function calcFhaCashoutEligibility (lead, maxLtv, fhaFICO, jumboThreshold) {
    if (!lead) {
        return {
            classStr: '',
            message: '',
        }
    }

    const danger = 'font-weight-bold bg-danger text-white';
    const success = 'bg-success font-weight-bold text-white';

    const equity = calcEquity(lead.estimatedValue, lead.loanBalance);
    const ltv = calcLTV(lead.loanBalance, lead.estimatedValue);
    const minEquity = calcMinEquity(ltv);

    if (employmentTest(lead.employment, lead.hasCoBorrower) === 'Fail') {
        return {
            classStr: danger,
            message: 'Employment Issue',
        }
    }

    if (lead.state === 'TX') {
        return {
            classStr: danger,
            message: 'FHA Cashout not valid in TX',
        }
    }

    if (equity < minEquity) {
        return {
            classStr: danger,
            message: `Not enough equity: ${minEquity}`
        }
    }

    if (ltv > maxLtv) {
        return {
            classStr: danger,
            message: `LTV too high: ${maxLtv}`,
        }
    }

    if (lead.occupancy !== 'Primary') {
        return {
            classStr: danger,
            message: 'Can only do on primary residence',
        }
    }

    if (calcPaymentsMade(lead.mortgageDate) < 5) {
        return {
            classStr: danger,
            message: 'Seasoning Needed: 5 months',
        }
    }

    if ((lead.loanBalance + equity) < 100000) {
        return {
            classStr: danger,
            message: "Doesn't meet minimum loan amount criteria.",
        }
    }

    if (lead.creditScore >= 450) {
        return {
            classStr: success,
            message: 'Eligible',
        }
    }

    if (lead.creditScore < fhaFICO) {
        return {
            classStr: danger,
            message: `FICO too low: ${fhaFICO}`,
        }
    }

    if (lead.loanBalance > (jumboThreshold - 5000)) {
        return {
            classStr: danger,
            message: 'FICO too low: 640',
        }
    }

    return {
        classStr: success,
        message: 'Eligible',
    }
}