import React, { Component,useContext } from "react";
import MatrixLogo from "../img/matrix-logo-2.png";
import axios from "axios";
import Dashboard from "./dashboard";
import DangerAlert from "./dangerAlert";
import SuccessAlert from "./successAlert";
import { ForgotPassword } from "./forgotPassword";
import { Button } from '@jrodriquez/components-library';
import { mainContext } from "../context/mainContext";
export default class SignInPage extends Component {
static contextType = mainContext;
  constructor(props) {
    super(props);

    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeVerificationCode = this.onChangeVerificationCode.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.clearMessage = this.clearMessage.bind(this);
    this.resendCodeHandler = this.resendCodeHandler.bind(this);
    this.verifyCodeHandler = this.verifyCodeHandler.bind(this);
    this.onChangeShowForgotPassword = this.onChangeShowForgotPassword.bind(this);

    this.state = {
      username: "",
      password: "",
      user: "",
      LoggedIn: false,
      error: "",
      successMessage: "",
      verification: false,
      verifyLoading: false,
      verificationCode: "",
      showForgotPassword: false,
    };
  }
  componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    axios
      .post(`${this.context.baseURL}/user/isAuthenticated`, {token: params.authToken})
      .then((res) => {
        if (res.data.Message === "authenticated") {
          this.setState({ user: res.data.user, LoggedIn: true });
        }
      })
      .catch();
  }

  updateUser(updatedUser){
    this.setState({user: updatedUser});
  }

  onChangeUsername(e) {
    const { value } = e.target;
    this.setState({username: value })
  }

  onChangePassword(e) {
    this.setState({ password: e.target.value });
  }
  onChangeVerificationCode(e) {
    this.setState({ verificationCode: e.target.value.toUpperCase() });
  }

  onChangeShowForgotPassword() {
    this.setState({ showForgotPassword: !this.state.showForgotPassword });
  }

  handleSignOutState() {
    this.setState({ LoggedIn: false, user: "" });
  }

  clearMessage() {
    this.setState({error: "", successMessage: ""});
  }

  showLoginForm(e) {
    e.preventDefault();
    this.setState({ verification: false });
  }

  async resendCodeHandler(e) {
    e.preventDefault();

    // check if there are user creds
    if(!this.state.username && !this.state.password) {
      this.setState({verification: false, error: "Please enter login info" });
      return;
    }

    // send request to resend code
    this.setState({successMessage: "Sending code"});
    try {

      const loginJson = {
        email: this.state.username,
        password: this.state.password,
        code: this.state.verificationCode
      };

      const res = await axios.post(`${this.context.baseURL}/user/sms/verify/send`, loginJson);
      this.setState({successMessage: "The code has been resent"});

    } catch(err) {

      console.log(err);

    }

  }

  async verifyCodeHandler(e) {

    e.preventDefault();
    this.setState({ error: "", successMessage: ""});

    // check if there are user creds
    if(!this.state.username && !this.state.password) {
      this.setState({verification: false, error: "Please enter login info" });
      return;
    }

    // validate field
    if(this.state.verificationCode.length <= 0) {
      this.setState({ error: "Please enter the verification code"});
      return
    }

    // prevent new request is one is pending
    if(this.state.verifyLoading) {
      return;
    }

    //verify code
    try {

      const userDataJson = {
        email: this.state.username,
        password: this.state.password,
        code: this.state.verificationCode
      };

      // make request
      this.setState({verifyLoading: true});
      const response = await axios.post(`${this.context.baseURL}/user/sms/verify`, userDataJson);
      this.setState({verifyLoading: false});

      // log user in
      if(response.data.Message == 'User Logged in') {
        this.setState({user: response.data.user, LoggedIn: true, verification: false});
      }

    } catch(error) {
      this.setState({error: error.response.data.message, verifyLoading: false });

    }

  }


  async onSubmit(e) {

    e.preventDefault();

    // please enter login info
    if(!this.state.username || !this.state.password) {
      this.setState({verification: false, error: "Please enter login info" });
      return;
    }


    try {

      const user = {
        email: this.state.username,
        password: this.state.password,
      };

      const res = await axios.post(`${this.context.baseURL}/user/login`, user);
      if(res.data.Message === "User Logged in") {
        this.setState({ user: res.data.user, LoggedIn: true });
      }

    } catch(err) {

      this.setState({ error: err.response.data })
      if(err.response.data === "Unauthorized IP address") {
        this.setState({ verification: true, error: "Please enter the verification code sent to your mobile device " });
      }

    }
  }

  render() {
    if (this.state.LoggedIn) {
      return (
        <Dashboard
          data={this.state}
          handleSignOutState={this.handleSignOutState.bind(this)}
          updateUser={this.updateUser.bind(this)}
        />
      );
    } else if (this.state.verification) {
      return (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-2xl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="border border-light rounded shadow mt-5 mb-2 p-3">
                <div className="text-center">
                  <img className="img-fluid" alt=" matrix Logo" src={MatrixLogo} />
                </div>
                <div className="mt-3 mb-2">
                  <DangerAlert data={this.state.error} />
                  <SuccessAlert data={this.state.successMessage} />
                </div>
                <div className="text-center ">
                  <form className="form-group" onSubmit={this.onClickVerify}>
                    <h4 className="mt-2">
                      Enter 6-digit verification code
                    </h4>
                    <div className="input-group">
                      <input
                        className="form-control  w-50 mt-2"
                        type="text"
                        placeholder="Verification Code"
                        maxLength="8"
                        required
                        value={this.state.verificationCode}
                        onChange={this.onChangeVerificationCode}
                        onFocus={this.clearMessage}
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary green-button w-100 mt-2"
                      onClick={(e) => {this.verifyCodeHandler(e)}}
                    >
                      Verify
                    </button>
                    <button
                      type="submit"
                      className="btn btn-secondary w-100 mt-2 "
                      onClick={(e) => {this.resendCodeHandler(e)}}
                    >
                      Resend code
                    </button>
                  </form>
                </div>
              </div>
              <a onClick={(e) => { this.showLoginForm(e) }} className="font-weight-bold text-secondary">&lt;&lt; Back to login </a>
            </div>
          </div>
        </div>
      );
    } else if (this.state.showForgotPassword) {
        return (
            <ForgotPassword onChangeShowForgotPassword={() => this.onChangeShowForgotPassword()} />
        )
    } else {
      return (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-2xl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="border border-light rounded shadow mt-5 p-3">
                <div className="text-center">
                  <img className="img-fluid" alt=" matrix Logo" src={MatrixLogo} />
                </div>
                <div className="mt-3 mb-2">
                  <DangerAlert data={this.state.error} />

                </div>
                <div className="text-center">
                  <form className="form-group" onSubmit={this.onSubmit}>
                    <div className="input-group">
                      <input
                        className="form-control  w-50 mt-2"
                        type="email"
                        placeholder="Email"
                        value={this.state.username}
                        onChange={(e) => { this.onChangeUsername(e) }}
                        onFocus={this.clearMessage}
                      />
                    </div>
                    <div className="input-group">
                      <input
                        className="form-control w-50 mt-2"
                        type="Password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.onChangePassword}
                        onFocus={this.clearMessage}
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary green-button w-100 mt-2 "
                    >
                      Sign In
                    </button>
                  </form>
                </div>
                <div className="row justify-content-between mr-3 ml-3">
                    <span>NFM Version 2.0.0</span>
                    <button className="btn-no-style" onClick={() => this.onChangeShowForgotPassword()}>
                        Forgot Password?
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
